// .actions {
//     display: flex;
//     column-gap: 1rem;
//     align-items: flex-end;
//     float: right;
//     text-align: right;
//     width: 85%;
//   }
//   .btn:hover {
//     transition: transform 0.5s ease;
//     transform: scale(1.1);
//   }
.handoverbtnaction {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  flex-direction: row-reverse;
  .actionbtn {
    margin: 0 0.5rem;
  }
  .actionbtn:hover {
    transition: transform 0.5s ease;
    transform: scale(1.1);
  }
}
.phoneNum{
  margin-top: 0.5rem;
}