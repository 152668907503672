.header {
  background-color: #f7f7f7;
  padding: 0rem 1rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 2.5rem;
  .pageView {
    line-height: 1;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
}
.archData {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 10px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 10px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 6vw;
    height: 6vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData6 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .imagePostTD {
    width: 5vw;
    object-fit: contain;
    max-height: 5vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}



@media all and (min-width: 1536px) {
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .archData {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 10px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 10px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 6vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData6 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .imagePostTD {
      width: 5vw;
      object-fit: contain;
      max-height: 5vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}

@media all and (max-width: 600px) {
}
