.titleSection {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
  
    .titleSectionLeft {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }
  
    .titleSectionRight {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
      column-gap: 0.5rem;
    }
  }