.container {
    font-size: 1.1rem;
    width: 100%;
}

.options {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    // height: 4rem;
}

.empty {
    // height: calc(100vh - 5.5rem - 1.5rem - 4rem - 8.8rem);
}

.tableWrapper {
    // padding: 0 0.5rem;
    margin: auto 1rem;
    height: 90vh;

   
    .noInputCreated {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media all and (min-width: 1200px) and (max-width:1535px) {
    .tableWrapper {        
        margin: auto 1rem;
        height: 86vh;        
        .noInputCreated {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}