.compImage {
    width: 10vw;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}
.compImage1 {
    width: 50vw;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}
.compImage2 {
    width: 25vw;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}

.compImage3 {
    width: 18vw;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}
.compImage4{
    width: 18rem;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}
.compImage5 {
    width: 11vw;
    height: 8rem;
    object-fit: contain;
    cursor: pointer;
}

@media all and (min-width: 1200px) and (max-width:1535px){

    .compImage {
        width: 10vw;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
    .compImage1 {
        width: 50vw;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
    .compImage2 {
        width: 25vw;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
    
    .compImage3 {
        width: 18vw;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
    .compImage4{
        width: 15rem;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
    .compImage5 {
        width: 11vw;
        height: 8rem;
        object-fit: contain;
        cursor: pointer;
    }
}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}