    .containerDataPage {
      display: flex;
      margin-top: 0.5rem;
    }

    .containerRecyclebin {
      width: 97%;
    }

    .drawerIcon {
      border-radius: 50%;
      background-color: #007fff14;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }

    .expandIconBox {
      width: 4rem;
      padding-top: 0.4rem;
      text-align: center;
      border-right: 1px solid #007fff14;
      height: 89vh;
    }

    .containerMilestonePage {
      width: 100%;
      .containerInner {
        height: 85vh;
      }
    }

    .drawBtn:hover {
      transform: Scale(1.1);
      transition: transform 0.5s ease;
    }