/*
@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translate3d(0, -1rem, 0);
    }
}

.bouncing-loader {
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.bouncing-loader > div {
    width: 1rem;
    height: 1rem;
    margin: 3rem 0.2rem;
    background: var(--bouncing-loader-color);
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}
*/
/*
@animation-timing:2.5s;
@dot-color: ;
@dot-size: 10px;
@dot-offset: -@dot-size / 1.5;
@dot-opacity: 1;
*/

.bouncing-loader {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    z-index: 999;

    .dot {
        width: 1em;
        height: 1em;
        margin: 3rem 0.3rem;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        background: #007fff;

        &:nth-child(1) {
            animation: ease-in-out dot1 3s infinite;
            background: #c76cca;
        }
        &:nth-child(2) {
            animation: ease-in-out dot2 3s infinite;
            // background: #c66bc7;
        }
        &:nth-child(3) {
            animation: ease-in-out dot3 3s infinite;
            // background: #0b3373;
        }
        &:nth-child(4) {
            animation: ease-in-out dot4 3s infinite;
            // background: #032b63;
        }
        &:nth-child(5) {
            animation: ease-in-out dot5 3s infinite;
            background: #c76cca;
        }
    }
}

@keyframes dot1 {
    0% {
        top: 0;
        left: 0;
    }
    10% {
        top: 20px;
        left: 20px;
        background: #007fff;
    }
    20% {
        top: -20px;
        left: 40px;
    }
    30% {
        top: 20px;
        left: 60px;
    }
    40% {
        top: -20px;
        left: 80px;
    }
    50% {
        top: 0px;
        left: 120px;
        background: #c76cca;
    }
    60% {
        top: 20px;
        left: 80px;
    }

    70% {
        top: -20px;
        left: 60px;
    }
    80% {
        top: 20px;
        left: 40px;
    }
    90% {
        top: -20px;
        left: 20px;
        background: #007fff;
    }
    100% {
        top: 0;
        left: 0;
    }
}

@keyframes dot2 {
    0% {
        left: 0px;
    }
    10% {
        left: -20px;
        background: #c76cca;
    }
    20% {
        left: -20px;
    }
    30% {
        left: -20px;
    }
    40% {
        left: -20px;
    }
    50% {
        left: -20px;
    }
    60% {
        left: -20px;
    }
    70% {
        left: -20px;
    }
    80% {
        left: -20px;
    }
    90% {
        left: -20px;
        background: #c76cca;
    }
    100% {
        left: 0px;
    }
}

@keyframes dot3 {
    0% {
        left: 0px;
    }
    10% {
        left: 0px;
    }
    20% {
        left: -20px;
    }
    30% {
        left: -20px;
    }
    40% {
        left: -20px;
    }
    50% {
        left: -20px;
    }
    60% {
        left: -20px;
    }
    70% {
        left: -20px;
    }
    80% {
        left: -20px;
    }
    90% {
        left: 0px;
    }
    100% {
        left: 0px;
    }
}

@keyframes dot4 {
    0% {
        left: 0px;
    }
    10% {
        left: 0px;
    }
    20% {
        left: 0px;
    }
    30% {
        left: -20px;
    }
    40% {
        left: -20px;
    }
    50% {
        left: -20px;
    }
    60% {
        left: -20px;
    }
    70% {
        left: -20px;
    }
    80% {
        left: 0px;
    }
    90% {
        left: 0px;
    }
    100% {
        left: 0px;
    }
}

@keyframes dot5 {
    0% {
        left: 0px;
    }
    10% {
        left: 0px;
    }
    20% {
        left: 0px;
    }
    30% {
        left: 0px;
    }
    40% {
        left: -20px;
        background: #007fff;
    }
    50% {
        left: -20px;
    }
    60% {
        left: -20px;
        background: #007fff;
    }
    70% {
        left: 0px;
    }
    80% {
        left: 0px;
    }
    90% {
        left: 0px;
    }
    100% {
        left: 0px;
    }
}
