.container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  width: 100%;
  height: auto;

  .crumbSection {
    display: inline-flex;
    margin-top: 5px;
    width: 100%;
    align-items: center;
    padding-right: 1rem;
    display: flex;
    justify-content: end;
    .mode {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
  }

  .ganttChartContainer {
    padding: 5px 10px;
  }
}

.toolTip {
  font-size: 1rem;
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: 1px 1px 5px 1px rgb(211, 211, 211);
}

.header {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 60px;
  padding: 0px 10px;
}

.plannedDot {
  height: 10px;
  width: 20px;
  background-color: #007fff;
  display: inline-block;
  padding-top: 2px;
}

.actualDot {
  height: 10px;
  width: 20px;
  display: inline-block;
  padding-top: 2px;
  margin: 0px 5px;
  &:nth-child(1) {
    background-color: yellow;
  }
  &:nth-child(3) {
    background-color: #ff0000;
  }
  &:nth-child(2) {
    background-color: green;
  }
}

.btn {
  background-color: #e8f0fe !important;
  color: #007fff !important;
}

.active {
  background-color: #007fff !important;
  color: #fff !important;
}
