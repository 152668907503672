.accMainDiv {
    height: 83.5vh;
    .groupName {
      display: flex;
      width: 100%;
      column-gap: 5px;
      align-items: center;
  
      .tabColumn {
        width: 16rem;
        padding: 0 1rem;
        background-color: #007fff14;
        padding: 0.3rem 1rem;
        border-radius: 5px;
        // border: 1px solid #007fff;
        // align-items: center;
        cursor: pointer;
      }
      .tabColumn_active {
        width: 16rem;
        background-color: #007fff;
        color: #fff;
        padding: 0.3rem 1rem;
        border-radius: 5px;
        // border: 1px solid #007fff;
        // align-items: center;
        cursor: pointer;
      }
      .tabColumn_active:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
      .tabColumn:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
    .downloadSect {
      width: 8rem;
      display: flex;
    }
    .accordTab {
      padding: 0 0rem;
    }
    .switchIconSec {
      position: absolute;
      right: 1rem;
      align-items: center;
      padding: 0.5rem;
    }
    .main {
      display: grid;
      border: var(--border-style);
      position: sticky;
      border-radius: 0.28571429rem;
      height: 79vh;
      // margin-right: 0.5rem;
      &[data-columns="1"] {
        grid-template-columns: 3rem repeat(1, 1fr) 0.5rem;
      }
      &[data-columns="2"] {
        grid-template-columns: 3rem repeat(2, 1fr) 0.5rem;
      }
      &[data-columns="3"] {
        grid-template-columns: 3rem repeat(3, 1fr) 0.5rem;
      }
      &[data-columns="4"] {
        grid-template-columns: 3rem repeat(4, 1fr) 0.5rem;
      }
      &[data-columns="5"] {
        grid-template-columns: 3rem repeat(5, 1fr) 0.5rem;
      }
    }
    .checkboxContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &[data-columns="1"] {
        height: 32.8rem;
      }
      &[data-columns="2"] {
        height: 24.96rem;
      }
      &[data-columns="3"] {
        height: 18.4rem;
      }
      &[data-columns="4"] {
        height: 17.21rem;
      }
      &[data-columns="5"] {
        height: 15.5rem;
      }
    }
    .noImageAvailable {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 90%;
      justify-content: center;
      align-items: center;
    }
  
    .column {
      border-right: 0.01rem solid #e8f0fe;
      border-bottom: 0.01rem solid #e8f0fe;;
      .header {
        &.readonly {
          height: 8.3rem;
        }
        position: sticky;
        top: 0;
        z-index: 2;
        height: 11.3rem;
        background-color: #c5d2e7;
        border-bottom: var(--border-style);
      }
    }
  }
  
  @media all and (min-width: 1200px) and (max-width: 1535px) {
    .accMainDiv {
      height: 79.5vh;
      .groupName {
        display: flex;
        width: 91%;
        column-gap: 5px;
        align-items: center;
  
        .tabColumn {
          width: 16rem;
          padding: 0 1rem;
          background-color: #007fff14;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          cursor: pointer;
        }
        .tabColumn_active {
          width: 16rem;
          background-color: #007fff;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          cursor: pointer;
        }
        .tabColumn_active:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
        .tabColumn:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
      }
      .downloadSect {
        width: 8rem;
        display: flex;
      }
    }
    .main {
      height: 73vh !important;
    }
    .checkboxContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &[data-columns="2"] {
        height: 24.96rem;
      }
      &[data-columns="3"] {
        height: 18.4rem;
      }
      &[data-columns="4"] {
        height: 16.47rem;
      }
      &[data-columns="5"] {
        height: 14rem;
      }
    }
  }
  
  @media all and (min-width: 900px) and (max-width: 1199px) {
    .accMainDiv {
      height: 84vh;
      .groupName {
        display: flex;
        width: 90%;
        column-gap: 5px;
        align-items: center;
  
        .tabColumn {
          width: 16rem;
          padding: 0 1rem;
          background-color: #007fff14;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          // border: 1px solid #007fff;
          // align-items: center;
          cursor: pointer;
        }
        .tabColumn_active {
          width: 16rem;
          background-color: #007fff;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          // border: 1px solid #007fff;
          // align-items: center;
          cursor: pointer;
        }
        .tabColumn_active:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
        .tabColumn:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
      }
      .downloadSect {
        width: 8rem;
        display: flex;
      }
    }
    .main {
      height: 81vh !important;
    }
    .checkboxContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &[data-columns="2"] {
        height: 21.96rem;
      }
      &[data-columns="3"] {
        height: 15.4rem;
      }
      &[data-columns="4"] {
        height: 16.47rem;
      }
      &[data-columns="5"] {
        height: 15.5rem;
      }
    }
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
    .accMainDiv {
      .groupName {
        display: flex;
        width: 85%;
        column-gap: 5px;
        align-items: center;
  
        .tabColumn {
          width: 16rem;
          padding: 0 1rem;
          background-color: #007fff14;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          cursor: pointer;
        }
        .tabColumn_active {
          width: 16rem;
          background-color: #007fff;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 5px;
          cursor: pointer;
        }
        .tabColumn_active:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
        .tabColumn:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
      }
      .downloadSect {
        width: 5rem;
        display: flex;
      }
    }
    .main {
      display: grid;
      border: var(--border-style);
      position: sticky;
      border-radius: 0.28571429rem;
      height: 81vh !important;
      // margin-right: 0.5rem;
      &[data-columns="2"] {
        grid-template-columns: 4rem repeat(2, 1fr);
      }
      &[data-columns="3"] {
        grid-template-columns: 4rem repeat(3, 1fr);
      }
      &[data-columns="4"] {
        grid-template-columns: 4rem repeat(4, 1fr);
      }
      &[data-columns="5"] {
        grid-template-columns: 4rem repeat(5, 1fr);
      }
    }
    .checkboxContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &[data-columns="2"] {
        height: 21.96rem !important;
      }
      &[data-columns="3"] {
        height: 15.4rem !important;
      }
      &[data-columns="4"] {
        height: 13.47rem !important;
      }
      &[data-columns="5"] {
        height: 15.5rem;
      }
    }
  }
  
  @media all and (max-width: 600px) {
  }
  