// .bubbleCharHolder {
//   * {
//     cursor: pointer !important;
//   }
// }

.bubbleCharHolder {
  margin-top: 0.5rem;
  border-left: 1px solid #007fff14;
  * {
    cursor: pointer !important;
  }
}
.expandIconBox {
  width: 2.5rem;
  padding-top: 0.4rem;
  text-align: center;
  height: 13rem;
  border-radius: 5px;
  background: aliceblue;
  margin-top: 2rem;
  float: left;
  z-index: 1;
  position: relative;
  cursor: pointer;
  border: 1px solid #007fff14;
  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 6rem;
    font-size: 1rem;
    color: #007fff;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .bubbleCharHolder {
    * {
      cursor: pointer !important;
    }
  }
}
@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
}

