#canvas {
    max-width: 24vw;
    height: 90vh !important;
    cursor: pointer;
    object-fit: contain;   
    animation: fadein ease 1s;
}
#canvasexpand{
    width: 92vw !important;
    height: auto !important;
    cursor: pointer;
    object-fit: contain;   
    animation: fadein ease 1s;
}

@media all and (min-width: 1200px) and (max-width:1535px) {
    #canvas {
        max-width: 23vw;
        height: 87vh !important;
        cursor: pointer;
        object-fit: contain;        
        animation: fadein ease 1s;
    }
    #canvasexpand{
        width: 92vw !important;
        height: auto !important;
        cursor: pointer;
        object-fit: contain;   
        animation: fadein ease 1s;
    }
}


@media all and (min-width: 900px) and (max-width:1199px) {
    #canvas {
        max-width: 31vw;
        height: 80vh !important;
        cursor: pointer;
        object-fit: contain;        
        animation: fadein ease 1s;
    }
    #canvasexpand{
        width: 92vw !important;
        height: auto !important;
        cursor: pointer;
        object-fit: contain;   
        animation: fadein ease 1s;
    }
}

@media all and (min-width: 601px) and (max-width: 899px) {
    #canvas {
        max-width: 40vw;
        height: 54vh !important;
        cursor: pointer;
        object-fit: contain;        
        animation: fadein ease 1s;
    }
    #canvasexpand{
        width: 92vw !important;
        height: auto !important;
        cursor: pointer;
        object-fit: contain;   
        animation: fadein ease 1s;
    }
}


@media all and (max-width: 600px) {}

@keyframes fadein {
    0% {
        opacity: 0;
        filter: blur(10px);
    }

    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}