.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 1rem;
    .imageSection {
        display: flex;
        // justify-content: space-evenly;
        height: 63vh;
        flex-direction: column;
        row-gap: 1rem;
        padding: 0 1rem;
        
        img {
            width: 100%;
            height: 14rem;
            object-fit: contain;
        }
    }
    .interface_area {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        height:24vh;
        .text_area {
            width: 95%;
            height:auto;
            font-size: 1.1rem;
            font-family: roboto;
            padding: 10px;
            border: 1px solid #ddefff;
            border-radius: 0.5rem;
            color: #007fff;
            resize: none;
        }
    }
}

.fallback_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: #0000ff 1px solid;
    // border-radius: 0.5rem;
    width: 30%;
    height: 120px;
}

.loder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: #0000ff 1px solid;
    // border-radius: 0.5rem;
    width: 30%;
    height: 130px;
}

.image {
    animation: fadein ease 1s;
    object-fit: cover;
    cursor: pointer;
}

@media all and (min-width: 1200px) and (max-width:1535px){
    .imageSection {
        display: flex;
        // justify-content: space-evenly;
        height: 60vh !important;
        flex-direction: column;
        row-gap: 1rem;
        padding: 0 1rem;
        
        img {
            width: 100%;
            height: 14rem;
            object-fit: contain;
        }
    }
}



@keyframes fadein {
    0% {
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.save_interface {
    margin-left: 10px;
}
