.annotationBody {
  display: flex;
  width: 75%;
  height: 89vh;

  .annotation {
    flex: 0.75;
  }

  .annotationtrue {
    flex: 0.75;
    margin-top: 2%;
  }

  .tagDetails {
    flex: 0.25;
    border-left: 1px solid #0000ff;
    margin-left: 1rem;
    height: 40vh;

    .displayPath_div {
      display: block;
      color: #0000ff;
    }

    .displayPath {
      margin-left: 1rem;
      display: block;
      color: #0000ff;
    }

    .hidePath {
      display: none;
    }

    .taskDiv {
      font-size: 1rem;
      font-weight: 600;
      width: 100%;
    }

    .linkDiv {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      width: 100%;
      column-gap: 0.5rem;
    }

    .taskName,
    .taskValue {
      color: #0000ff;
      margin-bottom: 0.5rem;
      margin-top: -3%;
    }

    .bomLink {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      /* background: rgb(0 0 0 / 5%); */
      width: 100%;
      background: #32436e;
      border-right: 2px solid #fff;
      cursor: pointer;
      border-radius: 0.5rem 0 0 0.5rem;
      border: none;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .costingLink {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      /* background: #e8f0fe; */
      background-color: #c57742;
      width: 100%;
      cursor: pointer;
      border-radius: 0 0.5rem 0.5rem 0;
      margin-left: 0.1rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .navigateButtons {
      padding: 0.5rem 1rem;
      margin: 0 auto;
      vertical-align: middle;
      text-align: center;
      color: white;
      font-size: 1rem;
    }

    .subsystems {
      margin-top: 0px;
      height: 50%;
      color: #0000ff;

      .subsystemTable {
        margin-top: 0px;
        height: 92%;
      }
    }

    .sub_systems {
      margin-top: 0px;
      height: 35rem !important;

      .subsystemTable {
        margin-top: 0px;
        height: 35rem;
      }
    }

    .addedTags {
      margin-top: 0px;
      height: 40%;
      color: #0000ff;

      .addedTagsTable {
        margin-top: 0px;
        height: 40vh;
      }
    }
  }
}

.annotationfalse {
  display: block;
  width: 75%;
  height: 96%;
  max-height: 85vh;

  .annotation {
    flex: 0.75;
  }

  .annotationtrue {
    flex: 0.75;
    margin-top: 2%;
  }

  .tagDetails {
    flex: 0.25;
    border-left: 1px solid #0000ff;
    margin-left: 1rem;
    height: 80rem;

    .displayPath_div {
      display: block;
      color: #0000ff;
    }

    .displayPath {
      margin-left: 1rem;
      display: block;
      color: #0000ff;
    }

    .hidePath {
      display: none;
    }

    .taskDiv {
      font-size: 1rem;
      font-weight: 600;
      width: 100%;
    }

    .linkDiv {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      width: 100%;
      column-gap: 0.5rem;

    }

    .taskName,
    .taskValue {
      color: #0000ff;
      margin-bottom: 0.5rem;
      margin-top: -3%;
    }

    .bomLink {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      /* background: rgb(0 0 0 / 5%); */
      width: 100%;
      background: #32436e;
      border-right: 2px solid #fff;
      cursor: pointer;
      border-radius: 0.5rem 0 0 0.5rem;
      border: none;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .costingLink {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      /* background: #e8f0fe; */
      background-color: #c57742;
      width: 100%;
      cursor: pointer;
      border-radius: 0 0.5rem 0.5rem 0;
      margin-left: 0.1rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .navigateButtons {
      padding: 0.5rem 1rem;
      margin: 0 auto;
      vertical-align: middle;
      text-align: center;
      color: white;
      font-size: 1rem;
    }

    .subsystems {
      margin-top: 0px;
      height: 50%;
      color: #0000ff;

      .subsystemTable {
        margin-top: 0px;
        height: 92%;
      }
    }

    .sub_systems {
      margin-top: 0px;
      height: 35rem !important;

      .subsystemTable {
        margin-top: 0px;
        height: 35rem;
      }
    }

    .addedTags {
      margin-top: 0px;
      height: 40%;
      color: #0000ff;

      .addedTagsTable {
        margin-top: 0px;
        height: 40vh;
      }
    }
  }
}

.elementdata {
  position: absolute;
  top: 1.5%;
  left: 1%;
  width: 70.3%;
}

.linkDiv {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  width: 100%;
  column-gap: 0.5rem;
}

.bomLink {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  /* background: rgb(0 0 0 / 5%); */
  width: 100%;
  background: #32436e;
  border-right: 2px solid #fff;
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: none;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
}

.costingLink {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  /* background: #e8f0fe; */
  background-color: #c57742;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin-left: 0.1rem;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
}

.navigateButtons {
  padding: 0.5rem 1rem;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  color: white;
  font-size: 1rem;
}

.elementdatafalse {
  position: absolute;
  top: 0.5%;
  left: 1%;
  width: 95.5%;
}

.portal {
  animation: pop 0.3s linear 1;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }
}