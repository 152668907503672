.expandIconBox {
	width: 4rem;
	padding-top: 0.4rem;
	text-align: center;
	border-right: 1px solid #007fff14;
	height: 89vh;
	.sideTitle {
		transform: rotate(270deg);
		white-space: nowrap;
		margin-top: 40vh;
	}
}
.parent_container {
	width: 100%;
	height: 100vh;
	// background: linear-gradient(
	// 	290deg,
	// 	rgb(0, 136, 122) 0%,
	// 	rgb(255, 255, 255) 100%
	// );
}

.user_tab_container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 2rem;
	margin: 0 auto;
	width: 98%;
	.all_user_tab {
		width: 100%;
		border-radius: 6px;
		// background: linear-gradient(90deg, #18c9b7 0%, hsl(220, 78%, 29%) 100%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}
	.professional_user_tab {
		width: 100%;
		border-radius: 6px;
		// background: linear-gradient(90deg, hsl(44deg 78.19% 68.19%) 0%, hsl(35.76deg 100% 43.38%) 100%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}

	.starter_user_tab {
		width: 100%;
		border-radius: 6px;
		// background: linear-gradient(90deg,hsl(122.42deg 100% 73.11%) 0%,#009688 100%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}
	.internal_user {
        // background: linear-gradient(90deg, #abaeff 0%, #241c69 50%);         
		width: 100%;
		border-radius: 6px;
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
	}
	.admin_user {
		width: 100%;
		border-radius: 6px;
		// background: linear-gradient(90deg, #f39c81 0%, #960000 50%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
	}
	.admin_user:hover,
	.starter_user_tab:hover,
	.professional_user_tab:hover,
	.all_user_tab:hover,
	.internal_user:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		opacity: 1;
	}
}

.details_table {
	height: 81vh;
	width: 98%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin: 1rem auto;
	padding-top: 1rem;
	border-radius: 1rem;
}

.search_tab {
	border-radius: 6px;
	color: black;
	background-color: #eeeeee;
	// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	display: flex;
	align-items: center;
	grid-column-gap: 1rem;
	column-gap: 1rem;
	padding: 0.2rem 1rem;
	width: 20rem;
	position: fixed;
	right: 1rem;
	z-index: 1;
	margin: 0.5rem;
	.inputSection {
		width: 70%;
		display: flex;
		align-items: center;
		column-gap: 3rem;
	}
}
.pagination_box {
	display: flex;
	position: absolute;
	width: 95%;
	justify-content: flex-end;
	bottom: 1rem;
	align-items: center;
	column-gap: 0.5rem;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
	.details_table {
		height: 74vh;
	}
}