.expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;
  }
.activeCount{
  font-size: 0.8rem !important;
  position: absolute;
  top: 0rem;
  right: 0rem;
  border: 0.5px solid;
  border-radius: 5px;
  min-width: 1rem;
  padding: 0.15rem 0.25rem !important;
  margin: 0.3rem !important;
}