.expandIconBox {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid #007fff14;
  height: 89vh;

  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 40vh;
  }
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.titleSection {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;

  .titleSectionLeft {
    display: flex;
    column-gap: 1rem;
    align-items: center;
  }

  .titleSectionRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;
    column-gap: 0.5rem;
  }
}

.CoststatusTable {
  tr:hover td {
  background-color: rgba(0, 0, 0, 0.04);
  }
}

@media all and (min-width: 1200px) and (max-width:1535px) {
  .titleSection {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;

    .titleSectionLeft {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }

    .titleSectionRight {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
    }
  }
}


@media all and (min-width: 900px) and (max-width:1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {}


@media all and (max-width: 600px) {
  .titleSection {
    width: 90rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    overflow: scroll;

    .titleSectionLeft {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }

    .titleSectionRight {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
    }
  }
}