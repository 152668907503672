.gradientGraph {
  height: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  .title{
    text-decoration: underline;
    display: inline-block;
    font-size: 1rem;
    width: 45%;
    cursor: pointer;
    text-align: right;
    margin-right: 1rem;
    line-height: 1.2;
  }
  .graphBar{
    // margin-left: 1rem;
    display: inline-block;
    width: 93%;
  }
}

@media all and (min-width: 1200px) and (max-width:1535px){
    .gradientGraph {
        height: 2rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        .graphBar{
          // margin-left: 1rem;
          display: inline-block;
          width: 93%;
        }
      }

}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}


@media all and (max-width: 600px) {

}
