.Container {
  width: 100%;
  height: auto;
  .chartContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    margin: 1rem 1rem 0.5rem;
    background: #fff;
  }
  .NumericSection {
    height: 43vh;
    .numericContainer {
      background-color: #ffffff;
      .tablecontainer {
        background-color: #ffffff;
        .tablecontain {
          padding-top: 3rem;
        }
      }
    }
    .bottomGraph {
      background-color: #ffffff;
      // margin-top: -4.4rem;
      margin-bottom: 1rem;
    }
  }
}
.slider {
  line-height: 1;
  color: #0000ff;
  border-radius: 0.5rem;
  z-index: 2;
  font-size: 2rem;
  margin-top: -1.9rem;
  float: right;
}
.parameter {
  // width: 28vw;
  border-right: 1px solid #e8f0fe;
  border-bottom: 1px solid #e8f0fe;
  border-top: none !important;
  padding: 0.3rem 0.5rem;
}
.columnRowData {
  width: 17.6vw;
  border-right: 1px solid #e8f0fe !important;
  border-bottom: 1px solid #e8f0fe;
  border-top: none !important;
  padding: 0.3rem 0.5rem;
}
.featureGraph {
  background: blue;
  color: white;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  height: 0.5rem;
  margin-top: -3rem;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .parameter {
    width: 10rem;
    border-right: 1px solid #e8f0fe;
    border-bottom: 1px solid #e8f0fe;
    border-top: none !important;
    padding: 0.3rem 0.5rem;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .Container {
    width: 100%;
    height: auto;
    .ChartBox {
      width: 64rem;
      overflow: scroll;
      .chartContainer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 2rem;
        margin: 1rem 1rem 0.5rem;
        background: #fff;
      }
    }
    .NumericSection {
      height: 43vh;
      .numericContainer {
        background-color: #ffffff;
        .tablecontainer {
          background-color: #ffffff;
          .tablecontain {
            padding-top: 3rem;
          }
        }
      }
      .bottomGraph {
        background-color: #ffffff;
        // margin-top: -4.4rem;
        margin-bottom: 1rem;
      }
    }
    .parameter {
      width: 10rem;
      border-right: 1px solid #e8f0fe;
      border-bottom: 1px solid #e8f0fe;
      border-top: none !important;
      padding: 0.3rem 0.5rem;
    }
  }
}

@media all and (max-width: 600px) {
}
