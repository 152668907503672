.inward_container {
  display: flex;
}

.left_container {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid rgba(0, 127, 255, 0.0784313725);
  height: 86vh;
}

.drawer_title {
  transform: rotate(270deg);
  white-space: nowrap;
  cursor: pointer;
  font-size: 1rem;
  width: 4rem;
}

.right_main_container {
  width: 96.4vw;
  padding: 0 1rem;
}

.right_container_bottom {
  display: flex;
  height: 100%;
}

.parameterdata_container {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  column-gap: 1rem;
  height: 87.5vh;
}

.parametername {
  width: 19vw;
  background-color: aliceblue;
}

.parameterImage {
  width: 19vw;
  background-color: aliceblue;
}

.parameterHeading {
  display: flex;
  background-color: #007fff;
  color: white;
  height: 2rem;
  // flex-direction: row;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 3px;
}

.parameterHeadingProduct {
  height: 43vh;
}

.productInnerTitle {
  display: flex;
  background-color: #007fff;
  color: white;
  height: 2rem;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 3px;
}

.parameterHeadingProduct2 {
  display: flex;
  background-color: #007fff;
  color: white;
  height: 2rem;
  flex-direction: row;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 3px;
}

.imagescontainer {
  display: block;
  position: relative;
}

.imagescontainer:hover .Buttonfield {
  display: block;
}

.Buttonfield {
  background-color: #ffffffa8;
  display: none;
  position: absolute;
  width: 11rem;
  bottom: 0;
  text-align: center;
}

.documents_container {
  display: flex;
  background-color: #007fff;
  color: white;
  height: 2rem;
  flex-direction: row;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  border: none !important;
  padding: 0 1rem;
  border-radius: 3px;
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}