.expandIconBox {
	width: 4rem;
	padding-top: 0.4rem;
	text-align: center;
	border-right: 1px solid #007fff14;
	height: 89vh;

	.sideTitle {
		transform: rotate(270deg);
		white-space: nowrap;
		margin-top: 40vh;
	}
}

.drawBtn:hover {
	transform: scale(1.1);
	transition: transform 0.5s ease;
}
.BomEntryContainer {
	.titleSection {
		width: 100%;
		display: flex;
		column-gap: 1rem;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.5rem;

		.titleSectionLeft {
			display: flex;
			column-gap: 1rem;
			align-items: center;
			width: 25vw;
		}
		.titleSectionMiddel {
			display: flex;
			column-gap: 1rem;
			align-items: center;
			width: 50vw;
		}
		.titleSectionRight {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			width: 25vw;
			justify-content: flex-end;
			padding-right: 0.5rem;
		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
	.BomEntryContainer {
		.titleSection {
			width: 100%;
			display: flex;
			column-gap: 1rem;
			align-items: center;
			justify-content: space-between;
			padding: 0 0.5rem;

			.titleSectionLeft {
				display: flex;
				column-gap: 1rem;
				align-items: center;
				min-width: 23vw;
			}

			.titleSectionMiddel {
				display: flex;
				column-gap: 1rem;
				align-items: center;
				width: 50vw;
			}
			.titleSectionRight {
				display: flex;
				// align-items: flex-end;
				column-gap: 1rem;
				width: 26vw;
				justify-content: flex-end;
				padding-right: 0.5rem;
			}
		}
	}
}

@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
	.expandIconBox {
		width: 4rem;
		padding-top: 0.4rem;
		text-align: center;
		border-right: 1px solid #007fff14;
		height: 79vh;
	
		.sideTitle {
			transform: rotate(270deg);
			white-space: nowrap;
			margin-top: 35vh;
		}
	}

	.BomEntryContainer {
        overflow-x: scroll;
		.titleSection {
			width: 95rem;
			display: flex;
			column-gap: 1rem;
			align-items: center;
			justify-content: space-between;
			padding: 0 0.5rem;

			.titleSectionLeft {
				display: flex;
				column-gap: 1rem;
				align-items: center;
				width: 25vw;
			}
			.titleSectionMiddel {
				display: flex;
				column-gap: 1rem;
				align-items: center;
				width: 50vw;
			}
			.titleSectionRight {
				display: flex;
				align-items: center;
				column-gap: 1rem;
				width: 25vw;
				justify-content: flex-end;
				padding-right: 0.5rem;
			}
		}
	}
}
