.popu.popupValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hide {
    display: none !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
}

.ui.input > input {
    padding: 0.3rem 0 !important;
}pValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hide {
    display: none !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
}

.ui.input > input {
    padding: 0.3rem 0 !important;
}