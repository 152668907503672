.featureVideoContainer {
 height: 94%;
 width: auto;
 padding:1rem;
    &[data-columns="4"]{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    &[data-columns="3"]{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    &[data-columns="5"]{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }
    &[data-columns="2"]{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }
    &[data-columns="1"]{
        display: grid;
        grid-template-columns: 1fr 1fr;
    /* gap: 12rem; */
    width: 32rem;
         
    }


  
  
    
}