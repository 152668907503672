.bomgridContainer {
  width: 100%;
  height: 10rem;
  max-height: 10rem;
  border-radius: 10px;
  // object-fit: contain;
  .bomDivNoImage{
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #ddefff;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    padding: 0 0.5rem;
  }
  .bomgridImages {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  .imageOptions {
    display: none;
    margin-top: -2rem;
   
    .optionDiv {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
      background-color: rgb(221 239 255 / 69%);
    }
  }
  .bomUploadVideo {
    cursor: pointer;
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border-radius: 10px;
    padding: 0 1rem;
  }
}

.bomgridContainer:hover .imageOptions {
  display: block;
  position: relative;
  .optionDiv {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;
    background-color: rgb(221 239 255 / 69%);
    
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .bomgridContainer {
    width: 100%;
    height: 7rem;
    max-height: 7rem;
    border-radius: 10px;
    // object-fit: contain;
    .bomgridImages {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    .imageOptions {
      display: none;
    }
    .bomUploadVideo {
      cursor: pointer;
      height: 10rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      border-radius: 10px;
      padding: 0 1rem;
    }
  }
  .bomgridContainer:hover .imageOptions {
    display: block;
  }
}
