.userContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
  width: 98vw;
}

.parents_userContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.userContainersubject {
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 0.5rem;
  margin-top: 1rem;
  width: 98vw;
}

.attchmentcontainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 99%;
}

.ProductContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto 1rem;
}

.ProductInnerContainer {
  display: flex;
  align-items: center;
}

.ProductInnerContainerdate {
  display: flex;
  flex-direction: row;
  align-items: center;
}

