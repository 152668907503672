.expandIconBox {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid #007fff14;
  height: 89vh;

  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 40vh;
  }
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.Data_card {
  background: white;
  height: 4rem;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.Data_card {
  background: white;
  display: flex;
  height: 4rem;
  margin: 0.2rem 1rem 1rem;
  border-radius: 0.5rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}



.box_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  // font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;

}

.active_box_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  // font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  text-transform: capitalize;
  background-color: #ddefff;
}