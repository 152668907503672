.featuretabActive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    background-color: #007fff;
    color: white;
    width: 100%;
    cursor: pointer;
}

.featuretabInactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    background-color: #ddefff;
    color: #007fff;
    width: 100%;
    cursor: pointer;
}

.imageDiv {
    height: 12rem;

    .image_card_container {
        width: 100%;
        min-width: 15vw;
        height: 100%;
        max-height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .image_card_container2 {
        width: 100%;
        min-width: 29vw;
        height: 100%;
        max-height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .image_card_container3 {
        width: 100%;
        // max-width: 14vw;
        min-width: 20vw;
        height: 100%;
        max-height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}

.imageDiv:hover {
    .action_container {
        background-color: #ddefff;
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        width: 100%;
        height: 2rem;
        margin-top: -2rem;
        justify-content: center;
    }
}


.action_container {
    display: none;
}

.slideactive {
    width: 25%;
    transition: all 2s ease;
    box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);

    .tabBox {        
        display: flex;        
        align-items: center;
        width: 100%;       
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }

    .featuretreefoot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem 0.5rem;
        background-color: #ddefff;
        border-bottom-left-radius: 10px;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}

.slideinactive {
    width: 0vw;
    transition: all 2s ease;

    .tabBox {        
        display: flex;        
        align-items: center;
        width: 100%;       
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }
    .featuretreefoot {
        display: none;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}

.requiredCss {
    color: red;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5rem;
}



@media all and (min-width: 1200px) and (max-width:1535px) {
    .imageDiv {
        height: 12rem;

        .image_card_container {
            width: 100%;
            min-width: 14vw;
            height: 100%;
            max-height: 12rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
       
        .image_card_container2 {
            width: 100%;
            min-width: 29vw;     
            height: 100%;
            max-height: 12rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        .image_card_container3 {
            width: 100%;
            max-width: 18vw;
            min-width: 18vw;
            height: 100%;
            max-height: 12rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
    }

    .featuretabInactive {
        padding: 0.25rem 0.2rem;
    }

    .featuretabActive {
        padding: 0.25rem 0.2rem;
    }

    .requiredCss {        
        margin-right: 3rem;
    }
}

@media all and (max-width: 600px) {
    .slideactive {
        width: 34rem;
        transition: all 2s ease;
        box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);
        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }
        .Mediatreefoot {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.2rem 0.5rem;
            background-color: #ddefff;
            border-bottom-left-radius: 10px;
            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }
    .slideinactive {
        width: 0vw;
        transition: all 2s ease;
        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }
        .Mediatreefoot {
            display: none;
            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }
}
