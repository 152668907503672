.container{
display: flex;
justify-content: center;
align-items: center;
 .imageContainer{
    background-color: white;
    width: 80vw;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
 }
 .images{
    height: fit-content;
    width: 40vw;
    padding: 1rem;
 }
}