.Container {
  padding: 5px 10px;

  // background-image: url(../../Assets/images/background.png);
  // background-image: url(../../Assets/images/background2.png);
  // background-position: 74.5% 25%;
  .header_container {
    background-color: transparent;

    .logo {
      height: 2.3rem;
      cursor: pointer;
      margin-top: 0.3rem;
    }

    .searchInput {
      color: #007fff;
      background-color: transparent;
      width: 34rem;
      height: 2rem;
      padding-left: 3rem;
      padding-bottom: 0.5rem;
      border-bottom: none;
      border-top: none;
      border-right: none;
      border-left: none;
    }

    .search {
      text-align: center;
      // width: 55%;
      margin: 0 auto;

      .searchForm {
        display: inline-block;
        margin-top: 0.5rem;
        border-bottom: 2px solid;

        .searchIcon {
          color: #007fff;
          width: 3rem;
          height: 1.5rem;
          margin-right: -2.5rem;
          cursor: pointer;
          z-index: 1;
          position: relative;
          margin-bottom: -1.5rem;
        }

        .AutoSearchBox {
          height: auto;
          max-height: 27rem;
          overflow: auto;
          width: 37rem;
          margin-top: 0.1rem;
          background-color: aliceblue;
          position: absolute;
          z-index: 11;
          border-radius: 2px;
          border-bottom: 2px solid #007fff14;
          border-right: 2px solid #007fff14;
          border-left: 2px solid #007fff14;

          .searchSubText {
            color: #007fff;
            cursor: pointer;
            text-align: left;
          }
        }

        .textarea:focus,
        input:focus {
          outline: none;
        }
      }
    }

    .menu {
      display: flex;
      float: right;
      column-gap: 5px;
      align-items: center;

      .switchUI:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }

      .menu_item {
        display: flex;
        transition: transform 0.5s ease;
        cursor: pointer;

        .Avatar {
          height: 50px;
          width: 50px;

          .menuIcon {
            font-size: 32px;
          }

          .menuIconLog {
            font-size: 32px;
            color: red;
          }
        }
      }

      .menu_item:hover {
        transform: scale(1.2);
      }
    }
  }

  .GuestUser {
    padding: 1rem;
    padding-top: 0;
    margin: 0 auto;

    .formContainer {
      display: flex;
      align-items: center;
      color: #007fff;
    }

    input.form-control {
      width: auto !important;
    }

    .guestbtnaction {
      display: flex;
      float: right;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .actionbtn {
        margin: 0 0.5rem;
      }

      .actionbtn:hover {
        transition: transform 0.5s ease;
        transform: scale(1.1);
      }
    }
  }
}

.Notification_Container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.7rem;
  padding: 0.5rem;
  align-items: center;
  font-size: 0.9rem;
  border-left: 3px solid blue;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);

  .iconsCol {
    // height: 3.5rem;
    text-align: center;
  }
}

.notifyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.3rem 0;

  .filterMenu {
    border: 1px solid #007fff;
    border-radius: 50%;
    z-index: 2;
    width: 2rem;
    text-align: center;
    height: 2rem;
    padding-top: 0.15rem;
    cursor: pointer;
  }
}

.inner_notification {
  max-height: 85vh;
  border-radius: 3px;
  padding-bottom: 0.5rem;
}

.Notification_Container:hover,
.Notification_Container_active:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.19), 1px 0px 0px rgba(0, 0, 0, 0.23);
}

.notificIcon:hover {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}

.innserSearchSubText {
  display: flex;
  // justify-content: space-between;
  border-bottom: 1px solid #007fff14;
  padding: 0 0.6rem;
  background-color: white;
  transition: transform 0.5s ease;
  align-items: center;
  font-size: 0.9rem;
  justify-content: space-between;
}

.innserSearchSubText:hover {
  text-decoration: none;
  background-color: #007fff14;
  overflow: hidden;
}

.Notification_Container_active {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  // margin-top: 0.7rem;
  padding: 0.5rem;
  font-weight: 500;
  align-items: center;
  font-size: 0.9rem;
  border-left: 3px solid blue;
  background-color: aliceblue;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);
}

.NotificationInfo {
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  padding-left: 0.5rem;

  .desc {
    // color: #007fff;
    font-weight: 500;
  }

  .linkdesc {
    // color: #007fff;
    font-weight: 500;
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }
}

.spanNotification {
  font-size: 0.7rem;
  font-weight: 500;
  // color: #007fff;
}

.noNotification {
  width: 100%;
  height: 50%;
  align-items: center;
  position: relative;
  top: 45%;
  left: 0%;
  text-align: left;
  padding-bottom: 0.5rem;
  display: flex;
  margin-bottom: -1rem;
}

.arrow_notification {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.notificationTitle {
  text-align: center;
  padding: 0.3rem 0 0 0;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  border-bottom: 1px solid aliceblue;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .Container {
    // padding: 5px 10px;
    height: 3.8rem;

    // margin-bottom: 10px;
    .logo {
      height: 2.3rem;
      cursor: pointer;
      vertical-align: top;
      margin-top: 0rem !important;
    }

    .searchInput {
      color: #007fff;
      width: 35rem;
      height: 2rem;
      padding-left: 3rem;
      padding-bottom: 0.5rem;
      border-color: #007fff;
      border-top: none;
      border-right: none;
      border-left: none;
    }

    .search {
      text-align: center;
      // width: 55%;
      margin: 0 auto;

      .searchForm {
        display: flex;
        margin-top: 0.5rem;

        .searchIcon {
          // color: #007fff;
          // width: 2rem;
          // height: 2rem;
          // margin-left: -2.5rem;
          // margin-top: 0.3rem;
          // cursor: pointer;
          color: #007fff;
          width: 3rem;
          height: 1.5rem;
          margin-right: -2.5rem;
          cursor: pointer;
          z-index: 1;
          position: relative;
          margin-bottom: -1.5rem;
        }

        .textarea:focus,
        input:focus {
          outline: none;
        }
      }
    }

    .menu {
      display: flex;
      float: right;
      column-gap: 10px;
      align-items: center;

      .switchUI:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }

      .menu_item {
        display: flex;
        transition: transform 0.5s ease;
        cursor: pointer;

        .Avatar {
          height: 45px;
          width: 45px;

          .menuIcon {
            font-size: 25px;
          }

          .menuIconLog {
            font-size: 25px;
            color: red;
          }
        }
      }

      .menu_item:hover {
        transform: scale(1.1);
      }
    }

    // .mobileMenu {
    //   display: none;
    // }
  }

  .inner_notification {
    max-height: 80vh;
    border-radius: 3px;
    padding-bottom: 0.5rem;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .Container {
    // height: 3.3rem;
    background: #007fff;

    .logo {
      height: 2.3rem;
      cursor: pointer;
      margin-top: 0.3rem;
    }

    .search {
      text-align: center;
      // width: 55%;
      margin: 0 auto;

      .searchForm {
        display: inline-block;
        margin-top: 0.5rem;
        border-bottom: 2px solid;
        background: #fff;

        .searchIcon {
          color: #007fff;
          width: 3rem;
          height: 1.5rem;
          margin-top: 0.1rem;
          margin-right: -2.5rem;
          cursor: pointer;
          z-index: 1;
          position: relative;
          margin-bottom: -1.5rem;
        }

        .searchInput {
          color: #007fff;
          width: 24rem;
          height: 2rem;
          padding-left: 3rem;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
          border-color: #007fff;
          border-top: none;
          border-right: none;
          border-left: none;
        }

        .AutoSearchBox {
          height: auto;
          max-height: 27rem;
          overflow: auto;
          width: 26.8rem !important;
          background-color: aliceblue;
          margin-top: 0 !important;
          position: absolute;
          z-index: 11;
          border-radius: 2px;
          border-bottom: 2px solid #007fff14;
          border-right: 2px solid #007fff14;
          border-left: 2px solid #007fff14;

          .searchSubText {
            color: #007fff;
            // margin-top: 1rem;
            cursor: pointer;
            text-align: left;

            .innserSearchSubText {
              display: flex;
              // justify-content: space-between;
              border-bottom: 1px solid #007fff14;
              padding: 0 0.6rem;
              background-color: white;
              transition: transform 0.5s ease;
              align-items: center;
              font-size: 0.75rem !important;
              justify-content: space-between;
            }
          }
        }

        .textarea:focus,
        input:focus {
          outline: none;
        }
      }
    }

    .menu {
      display: flex;
      float: right;
      column-gap: 10px;
      align-items: center;

      .switchUI:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }

      .menu_item {
        display: flex;
        transition: transform 0.5s ease;
        cursor: pointer;

        .Avatar {
          height: 45px;
          width: 45px;

          .menuIcon {
            font-size: 25px;
          }

          .menuIconLog {
            font-size: 25px;
            color: red;
          }
        }
      }

      .menu_item:hover {
        transform: scale(1.1);
      }
    }
  }

  .inner_notification {
    max-height: 80vh;
    border-radius: 3px;
    padding-bottom: 0.5rem;
  }

  .headerMobIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 28px;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .Container {    
    width: 100vw;
    background: #007fff;

    .logo {
      height: 2.3rem;
      cursor: pointer;
      margin-top: 0.3rem;
    }

    .search {
      text-align: center;
      // width: 55%;
      margin: 0 auto;

      .searchForm {
        display: inline-block;
        margin-top: 0.5rem;
        border-bottom: 2px solid;
        background: #fff;

        .searchIcon {
          color: #007fff;
          width: 3rem;
          height: 1.5rem;
          margin-top: 0.1rem;
          margin-right: -2.5rem;
          cursor: pointer;
          z-index: 1;
          position: relative;
          margin-bottom: -1.5rem;
        }

        .searchInput {
          color: #007fff;
          width: 18rem;
          height: 2rem;
          padding-left: 3rem;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
          border-color: #007fff;
          border-top: none;
          border-right: none;
          border-left: none;
        }

        .AutoSearchBox {
          height: auto;
          max-height: 27rem;
          overflow: auto;
          width: 20.7rem !important;
          background-color: aliceblue;
          margin-top: 0 !important;
          position: absolute;
          z-index: 11;
          border-radius: 2px;
          border-bottom: 2px solid #007fff14;
          border-right: 2px solid #007fff14;
          border-left: 2px solid #007fff14;

          .searchSubText {
            color: #007fff;
            // margin-top: 1rem;
            cursor: pointer;
            text-align: left;

            .innserSearchSubText {
              display: flex;
              // justify-content: space-between;
              border-bottom: 1px solid #007fff14;
              padding: 0 0.6rem;
              background-color: white;
              transition: transform 0.5s ease;
              align-items: center;
              font-size: 0.75rem !important;
              justify-content: space-between;
            }
          }
        }

        .textarea:focus,
        input:focus {
          outline: none;
        }
      }
    }

    .menu {
      display: flex;
      float: right;
      column-gap: 10px;
      align-items: center;

      .switchUI:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }

      .menu_item {
        display: flex;
        transition: transform 0.5s ease;
        cursor: pointer;

        .Avatar {
          height: 45px;
          width: 45px;

          .menuIcon {
            font-size: 25px;
          }

          .menuIconLog {
            font-size: 25px;
            color: red;
          }
        }
      }

      .menu_item:hover {
        transform: scale(1.1);
      }
    }
  }

  .headerMobIcon {
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 28px;
  }
}

@media all and (max-width: 600px) {
  .Container {
    padding: 5px 10px;
    background-color: #007fff;

    .header_container {
      background-color: transparent;
      width: 100%;
      .logo {
        height: 2.3rem;
        cursor: pointer;
        margin-top: 0.3rem;
      }

      .search {
        display: none;
        // text-align: center;
        // // width: 55%;
        // margin: 0 auto;

        .searchForm {
          display: flex;
          margin-top: 0.5rem;

          .searchIcon {
            // color: #007fff;
            // width: 2rem;
            // height: 2rem;
            // margin-left: -2.5rem;
            // margin-top: 0.3rem;
            // cursor: pointer;
            color: #007fff;
            width: 3rem;
            height: 1.5rem;
            margin-right: -2.5rem;
            cursor: pointer;
            z-index: 1;
            position: relative;
            margin-bottom: -1.5rem;
          }

          .AutoSearchBox {
            height: auto;
            max-height: 27rem;
            overflow: auto;
            width: 5rem;
            background-color: aliceblue;
            position: absolute;
            z-index: 11;
            border-radius: 2px;
            border-bottom: 2px solid #007fff14;
            border-right: 2px solid #007fff14;
            border-left: 2px solid #007fff14;

            .searchSubText {
              color: #007fff;
              // margin-top: 1rem;
              cursor: pointer;
              text-align: left;
            }
          }

          .textarea:focus,
          input:focus {
            outline: none;
          }
        }
      }

      .menu {
        display: flex;
        float: right;
        column-gap: 10px;
        align-items: center;

        .switchUI:hover {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }

        .menu_item {
          display: flex;
          transition: transform 0.5s ease;
          cursor: pointer;

          .Avatar {
            height: 45px;
            width: 45px;

            .menuIcon {
              font-size: 25px;
            }

            .menuIconLog {
              font-size: 25px;
              color: red;
            }
          }
        }

        .menu_item:hover {
          transform: scale(1.1);
        }
      }

      .headerMobIcon {
        height: 1.5rem;
        margin-right: 0.5rem;
        width: 28px;
      }

      .videoIcon {
        // animation: blinker 1s linear infinite !important;
        background-color: "white";
        width: "2rem";
      }

      .blinkText {
        animation: videoIcon 1s linear infinite !important;
      }

      @keyframes videoIcon {
        0% {
          opacity: 0 !important;
        }

        50% {
          opacity: .5 !important;
        }

        100% {
          opacity: 1 !important;
        }
      }
    }
  }
}