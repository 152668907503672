.popupValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hide {
    display: none !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
}

.tableStyle {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    line-height: 1.2 !important;
}