.container {
    width: 100%;
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    column-gap: 1rem;
    padding:0 1rem;

    .topContainer {
        display: flex;
        flex-direction: column;
        // margin-bottom: 2rem;
        // margin-top: 1rem;
        // padding: 0 1rem;
        // grid-column-gap: 2rem;        
    }
}

.userconatiner {
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 0.5rem;
    width: 100%;
}

.userlists {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 0.5rem 1rem;
    column-gap: 1rem;
    // justify-content: space-around;
}