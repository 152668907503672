.container {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  padding: 0.5rem;
  // background-color: 007fff;

  // &:not(:last-of-type) {
  //     border-right: var(--border-style);
  // }
}

.productInfo {
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 2;
  border-radius: 5px;
  text-align: center;
  // height: 4.4rem;
  background-color: #007fff;
  color: white;
  font-size: 1.1rem;
  .gridColumn {
    padding: 0rem !important;
    width: 100%;
    // background: #007fff;
  }
  .gridColumn1 {
    // width: 80% !important;
  }
  .gridColumn2 {
    width: 20% !important;
    border-right: 2px solid #ffffff;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding-top: 0.5rem;
}

.hide {
  display: none !important;
}
.i.upload.large.link.icon.Imicon {
  color: 007fff !important;
}
