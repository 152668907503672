.BOMImageModal{
    height: 100%;
    .BomImage {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 100%;
        min-height: 20rem;
        padding: 1rem;
        row-gap: 1rem;
        column-gap: 1rem;
        .bomImageCard {
          height: 115px;
          max-height: 25rem;
        }
        .bomImageCardImage {
          height: 16.9rem;
        }
        .bomImageCardDoc {
          height: 115px;
        }
        .bomImageCard:hover {
          transform: scale(1.05);
          transition: transform 0.5s ease-in-out;
        }
      }
      .BomImageNoImage {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        min-height: 20rem;
        padding: 1rem;
        row-gap: 1rem;
        column-gap: 1rem;
        .bomImageCard {
          height: 115px;
          max-height: 25rem;
        }
        .bomImageCardImage {
          height: 100%;
          margin: 0 auto;
          max-width: 200px;
        }
        .bomImageCardDoc {
          height: 115px;
        }
        .bomImageCard:hover {
          transform: scale(1.05);
          transition: transform 0.5s ease-in-out;
        }
      }   
}