.tableStyle {
    width: 100% !important;
    td,
    th {
        padding: 5px !important;
    }
    input[type="number"] {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
    }
}