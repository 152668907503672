.container {
  width: 97%;
  margin: 0 auto;
  .tableTitleRow {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    position:sticky;
    top:0;
    .tableTitleCell {
      padding: 0.2rem 1rem;
    }
  }
  .title {
    color: #646464;
  }
  .tableBodyCell {
    font-size: 1rem !important;
    padding: 1rem 1rem !important;
    border-bottom-color: #007fff14 !important;
  }
}
