.image_card_container {
    width: 100%;
    height: 11rem;
    /* border-radius: 1rem; */

    object-fit: contain;
    margin: 0.5rem;
    position: relative;
    text-align: center;
    overflow: hidden;
}

// .image_box {
//     width: 18rem;
//     height: 10.5rem;
//     object-fit: cover;

// }

.image_card_container:hover {
    .action_sequence {
        display: block;
        position: absolute;
        z-index: 1;
    }

    .action_container {

        background-color: #ddeffff2;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        flex-wrap: wrap;
    }
}

.image:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
}

.action_container {
    display: none;
}

.action_sequence {
    display: none;
}



@media all and (min-width: 1200px) and (max-width:1535px) {
    .image_card_container {
        width: 100%;
        height: 11rem;
        /* border-radius: 1rem; */

        object-fit: contain;
        margin: 0.5rem;
        position: relative;
        text-align: center;
        overflow: hidden;
    }

    .image_card_container:hover {
        .action_sequence {
            display: block;
            position: absolute;
            z-index: 1;
        }

        .action_container {

            background-color: #ddeffff2;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.3rem;
            flex-wrap: wrap;
        }
    }

}


@media all and (min-width: 900px) and (max-width:1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {}


@media all and (max-width: 600px) {}