  .containReject {
    position: relative;
    height: 5rem;
    display: flex;
    align-items: flex-start;
    // margin-top: -3rem;      
  }
  .containSubmit{
    position: relative;
    height: 5rem;
  }
  .clientReject{
    display: flex;
    align-items: center;
  }
  .clientAccept{      
    display: flex;
    align-items: flex-start;
    height: 6rem;
    margin-left: -0.2rem;
    margin-top: -0.7rem;
  }
  .workflow_name { 
    white-space: nowrap;
    width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.87rem !important;
  }



.Ideadetails{
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 3rem;
  .title{ 
    font-size: 1rem;
    font-weight: 500;
    color: #007fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // text-align: right;
    white-space: nowrap;
    width:8rem;
    padding-right: 0.5rem;
  }
  .ideavalue{
    margin-left: 0.5rem;
    font-size: 0.87rem;      
    color: #161616;
  }
}
.IdeadetailsSmallscreen{
  display: flex;
  align-items: center;
  column-gap: 1rem;
  height: 2.4rem;
  .title{ 
    // font-size: 1rem;
    font-weight: 500;
    color: #007fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // text-align: right;
    white-space: nowrap;
    width:6rem;
    // padding-right: 0.5rem;
  }
  .titleright{
    font-weight: 500;
    color: #007fff;
    display: flex;
    justify-content: space-between;
    align-items: center;      
    white-space: nowrap;
    width:9rem;
    // padding-right: 0.5rem;
  }
  .ideavalue{
    // margin-left: 0.5rem;
    font-size: 0.87rem;      
    color: #161616;
  }
}


  @media all and (min-width: 1200px) and (max-width:1535px){
    .containReject {
      position: relative;
      height: 5rem;
      display: flex;
      align-items: flex-start;
      // margin-top: -3rem;      
    }
    .containSubmit{
      position: relative;
      height: 5rem;
    }
    .clientReject{
      display: flex;
      align-items: center;
    }
    .clientAccept{      
      display: flex;
      align-items: flex-start;
      height: 6rem;
      margin-left: -0.2rem;
      margin-top: -0.7rem;
    }
    .workflow_name { 
      white-space: nowrap;
      width: 9rem;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.87rem !important;
    }
    
    .Ideadetails{
      display: flex;
      align-items: center;
      column-gap: 0.5;
      height: 3rem;
      .title{
        font-size: 0.87rem;
        font-weight: 500;
        color: #007fff;
        text-align: right;
        width:7rem;
        margin-right: 0.5rem;
      }
      .ideavalue{
        font-size: 0.87rem;      
        color: #161616;
      }
    }

  }
  
  
  @media all and (min-width: 900px) and (max-width:1199px) {
  
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
  
  }
  
  
  @media all and (max-width: 600px) {
  
  }
