.container {
    display: flex;
    justify-content: center;
    align-items: center;

    .containerInside {
        background-color: white;
        width: 80vw;
        height: fit-content;
        padding: 0.25rem 1rem;
        padding-bottom: 0rem;
        border-radius: 10px;
    }

    .materalcontainerInside {
        background-color: white;
        width: 50vw;
        height: auto;
        padding: 0.25rem 1rem;
        padding-bottom: 0rem;
        border-radius: 10px;
    }
}