.imageMeasurementContainer {
	padding: 0 1rem;
	.imageMeasurementWrapper {
		height: 93vh;
		width: 100%;
		margin: 0.2rem;
		display: grid;
		grid-template-areas: 'table canvas';
		grid-template-columns: 28rem 1fr;
		border: 1px solid #ddefff;
		border-radius: 10px;

		.imageMeasurementCont {
			grid-area: canvas;
			height: 100%;
			width: auto;
			max-height: 93vh;
			width: 100%;
			height: 100%;
			margin: 0 auto;			
		}
		.canvasWrapper {
            height: 100%;
			width: 100%;
			margin: 1rem;
		}

		.measurements {
			grid-area: table;
			// padding: 0 1rem;
            overflow: hidden;
			border-right: 1px solid #ddefff;
		}

		.total {
			display: flex;
			justify-content: center;
		}
	}
}


@media all and (min-width: 1200px) and (max-width: 1535px) {
	.imageMeasurementContainer {
		padding: 0 1rem;
		.imageMeasurementWrapper {
			height: 91vh;
			width: 100%;
			margin: 0.2rem;
			display: grid;
			grid-template-areas: 'table canvas';
			grid-template-columns: 27rem 1fr;			

			.imageMeasurementCont {
				grid-area: canvas;
				max-height: 91vh;
				max-width: 73vw;
				width: 100%;
				height: 100%;
				margin: 0 auto;
			}
			.canvasWrapper {
				width: 100%;
				height: 100%;
				margin: 1rem;
			}

			.measurements {
                grid-area: table;
                // padding: 0 1rem;
                overflow: hidden;
                border-right: 1px solid #ddefff;
			}

			.total {
				display: flex;
				justify-content: center;
			}
		}
	}
}
