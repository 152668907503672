.BomImagesSec {
  display: block;
  // height: 42vh;
  &[data-columns="1"] {
    .BomImage {
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
  &[data-columns="2"] {
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
  &[data-columns="3"] {
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
  &[data-columns="4"] {
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
  &[data-columns="5"] {
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
  &[data-columns="6"] {
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(5vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          max-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 80px;
          }
        }
      }
    }
  }
}
.BomImageflex {
  // height: 42vh;
  .BomImage {
    // height: 42vh;
    padding: 0.2rem 0.5rem;
    height: 100%;
    .imagegrid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
      gap: 10px;
      .bomImagesCard {
        width: 100%;
        height: 100%;
        min-height: 80px;
        .bomImagesIn {
          width: 100%;
          object-fit: contain;
          height: 80px;
        }
      }
    }
  }
}

.bom_parameter {
  padding: 0 0.5rem;
  height: 41vh;
  margin-top: 0.5rem;
  overflow: auto;
  .bomParamet {
    color: #007fff;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .BomImagesSec {
    display: block;
    // height: 42vh;
    &[data-columns="1"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 80px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 80px;
            }
          }
        }
      }
    }
    &[data-columns="2"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 80px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 80px;
            }
          }
        }
      }
    }
    &[data-columns="3"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 80px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 80px;
            }
          }
        }
      }
    }
    &[data-columns="4"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 80px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 80px;
            }
          }
        }
      }
    }
    &[data-columns="5"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(6vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 80px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 80px;
            }
          }
        }
      }
    }
    &[data-columns="6"] {
      .BomImage {
        // height: 42vh;
        padding: 0.2rem 0.5rem;
        height: 100%;
        .imagegrid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(5vw, 1fr) );
          gap: 10px;
          .bomImagesCard {
            width: 100%;
            height: 100%;
            min-height: 60px;
            max-height: 60px;
            .bomImagesIn {
              width: 100%;
              object-fit: contain;
              height: 60px;
            }
          }
        }
      }
    }
  }
  .BomImageflex {
    // height: 42vh;
    .BomImage {
      // height: 42vh;
      padding: 0.2rem 0.5rem;
      height: 100%;
      .imagegrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7vw, 1fr) );
        gap: 10px;
        .bomImagesCard {
          width: 100%;
          height: 100%;
          min-height: 80px;
          .bomImagesIn {
            width: 100%;
            object-fit: contain;
            height: 110px;
          }
        }
      }
    }
  }
  .bom_parameter {
    padding: 0 0.5rem;
    height: 35vh !important;
    margin-top: 0.5rem;
    .bomParamet {
      column-gap: 0.5rem;
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .bom_parameter {
    padding: 0 0.5rem;
    height: 34vh !important;
    margin-top: 0.5rem;
    .bomParamet {
      color: #007fff;
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
}
