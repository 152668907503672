.repotsLandingPage{
    display: flex;
    flex-direction: row;
  
    width: 100%;
}
.reportactiveTab
{
    color: white;
    cursor: pointer;
    background-color: #007fff;
    width: 50%;
    padding: 0.4rem 0rem 0.4rem 0rem;
    border: none;
    font-size: 1rem;
    text-align: center;
    // margin-left: 0.2rem;
    border-radius: 2px;
}
.InactivereportTab{
    color:  #007fff;
    cursor: pointer;
    background-color: white;
    width: 50%;
    padding: 0.4rem 0rem 0.4rem 0rem;
    border: none;
    font-size: 1rem;
    text-align: center;
    // margin-left: 0.2rem;
    border-radius: 2px;
    border: 1px solid;
}