.tui-image-editor-header-logo {
    display: none !important;
}

// .tui-image-editor-download-btn {
//     display: none !important;
// }

.tie-btn-zoomOut,
.tie-btn-zoomIn,
.tie-btn-hand {
    display: none !important;
}

/* .tui-image-editor-header {
    display: none;
} */

.tui-image-editor-header-buttons {
    display: none;
}