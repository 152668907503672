.expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;

    .sideTitle {
        transform: rotate(270deg);
        white-space: nowrap;
        margin-top: 40vh;
    }
}

.drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
}

.expandall {
    font-size: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 1rem;
    padding-right: 1rem;
    border: 1px solid;
    border-radius: 20px;
    width: 38vw;
    transition: width 2s;
    overflow: hidden;
    padding: 0.2rem 0.5rem;
    margin-left: -2rem;
    justify-content: flex-end;

    .dblibrarychip:hover {
        background-color: #007fff !important;
        color: white;
    }

}

.collapse {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 1rem;
    width: 0vw;
    overflow: hidden;
    border: 1px solid;
    border-radius: 20px;
    transition: width 2s;
    margin-left: -2rem !important;
    padding: 0.2rem 0.5rem;
    justify-content: flex-end;

    .dblibrarychip:hover {
        display: none;
    }
}





@media all and (min-width: 1200px) and (max-width:1535px) {
    .expandall {
        font-size: 1rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 1rem;
        padding-right: 1rem;
        border: 1px solid;
        border-radius: 20px;
        width: 49vw;
        transition: width 2s;
        overflow: hidden;
        padding: 0.1rem 0.5rem;
        margin-left: -2rem;
        justify-content: flex-end;

        .dblibrarychip:hover {
            background-color: #007fff !important;
            color: white;
        }

    }

    .collapse {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 1rem;
        width: 0vw;
        overflow: hidden;
        border: 1px solid;
        border-radius: 20px;
        transition: width 2s;
        margin-left: -2rem !important;
        padding: 0.1rem 0.5rem;
        justify-content: flex-end;

        .dblibrarychip:hover {
            display: none;
        }
    }
}