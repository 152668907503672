.errorMsg {
    color: red;
    font-size: 1rem;
    display: flex;
    align-items: center;

}

.align-start {
    text-align: start;
}

align-center {
    text-align: center;
}

.align-end {
    text-align: end;
}
