.ck.ck-editor__editable_inline {
    min-height: 500px;
    flex: 1 1 auto;
}

.OtherJoditEditor {
    height: 62vh !important;
    /* Adjust to your desired height */
  }
  
  @media all and (min-width: 1200px) and (max-width:1535px) {
    .OtherJoditEditor {
        height: 50vh !important;
        /* Adjust to your desired height */
    }
  }