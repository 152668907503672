.lengthCount5 {
    width: 13.5vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.lengthCount4 {
    width: 16vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.lengthCount3 {
    width: 20vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.lengthCount2 {
    width: 25vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.lengthCount1 {
    width: 25vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.lengthCount {
    width: 30vw;
    padding: 0.2rem 0.5rem !important;
    border-bottom: none !important;
}

.headerLength5 {
    width: 13vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}

.headerLength4 {
    width: 15vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}

.headerLength3 {
    width: 20vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}

.headerLength2 {
    width: 25vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}

.headerLength1 {
    width: 25vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}

.headerLength {
    width: 30vw;
    padding: 0.2rem 0.5rem !important;
    // padding-left: 2rem !important;
    border-bottom: none !important;
}