.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    padding: 0 1rem;
    margin-top: -0.5rem;

    span {
        color: #007fff;
        margin-top: 0.2rem;
    }
}

.pdfname {
    text-align: center;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 18rem;

}

.pdfnameexpanded {
    text-align: center;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

}

@media all and (min-width: 1200px) and (max-width:1535px) {
    .pdfname {
        text-align: center;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 11rem;
    }

    .pdfnameexpanded {
        text-align: center;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100% !important;

    }
}