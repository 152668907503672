.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: #fff;
  padding: 0.5rem 0;
  border-radius: 10px;
  max-height: 96%;
}
.imagess {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: #fff;
  padding: 1rem;
}
.exportBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.excelpopBox {
  //   display: flex;
  //   justify-content: center;
  //   max-height: 60rem;
  display: flex;
  width: 100%;
  max-height: 81vh;
  justify-content: flex-start;
}