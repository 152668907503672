.fileupload::before {
    border-bottom-color: aliceblue !important;
}

.MuiInputBase-root.MuiInput-root:before {
    border-bottom-color: #007fff !important;
}
input[type="file"]{
    border: none;
    width:100%;
    outline: none;
    border-bottom: 1px solid #007fff; /* You can adjust the color and thickness of the outline */
  }
  input[type="file"]::before{
    border:none;
  }
  .MuiInputBase-root.MuiInput-root:before{
    border:none !important;
  }