.DispatchEmailEditor {
    height: 60vh !important;
    /* Adjust to your desired height */
  }
  
  @media all and (min-width: 1200px) and (max-width:1535px) {
    .DispatchEmailEditor {
      height: 44vh !important;
      /* Adjust to your desired height */
    }
  }