.drawerIcon {
    border-radius: 50%;
    background-color: #007fff14;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
  }
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 100%;
    .sideTitle {
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 45vh;
      font-size: 1rem;
    }
  }
  .drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  .gotoIcon:hover{
    transform: scale(1.15);
    transition: transform 0.5s ease;
  }