.legend {
    // display: flex;
    // justify-content: space-evenly;
    .active_user {
        display: flex;
        // justify-content: space-between;
        font-size: 1rem;
        &:nth-child(1) {
            color: #0000ff;
        }
        &:nth-child(2) {
            color: rgb(125, 253, 125);
        }
        &:nth-child(3) {
            color: #d8b02a;
        }
        .dot {
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            display: inline-block;
            margin-top: 2px;
            margin-right: 4px;
        }
    }
}

.graphcanvas {
    display: flex;
    justify-content: center;
    align-items: center;
}
