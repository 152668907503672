.other {
    display: flex;
    align-items: center;
    // justify-content: end;
    // position: sticky;
    // top:3rem;
    // z-index: 2;
    background: white;
    // border-bottom: 1px solid #808080a3;
    padding: 0.5rem 0.5rem;
    width: 25rem;
}

.tableHead {
    padding: 0.2rem 0.2rem !important;
    background-color: #ddefff !important;
    border-bottom-color: #ffffff !important;
    // color: white !important;
}

.tableStyle {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    color: #007fff;    
}

.tableStyleFooter {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #007fff !important;
}
.tableStyleValue{
    padding: 0.2rem 0 !important;
    border-bottom-color: #ddefff !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #007fff !important;
}
.tableStyleFooterValue{
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    background-color: white;
    border-radius: 5px;    
    color: #007fff !important;
    width: 100%;
    display: flex;
}





@media all and (min-width: 1200px) and (max-width:1535px){
    .other {
        display: flex;
        align-items: center;
        background: white;        
        padding: 0.5rem 0.5rem;
        width: 19rem;
    }
}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}


@media all and (max-width: 600px) {

}

