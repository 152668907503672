.container {
  display: grid;
  grid-template-columns: 28rem 4fr 0.1fr;
  .ideaName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .ideaNameSpan {
      width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1rem;
    }
  }
  .ideaActionButton {
    width: 100%;
  }
  .ideaActionSubmittedButton {
    width: 40%;
  }
  .ideaActionSubmittedButton1 {
    width: 40%;
  }
  .ideaRedirectionButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    .rdtBtn {
      padding: 0.5rem;
    }
    .rdtBtn:hover {
      padding: 0.5rem;
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  }
  .clientProposalHeader {    
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .tabCell1 {
    color: #646464;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border-bottom-color: aliceblue;
  }
  .tabCell2 {
    color: #007fff;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border-bottom-color: aliceblue;
  }
  .tabHCell1 {
    color: #646464;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    width: 50%;
  }
  .tabHCell2 {
    color: #646464;
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    text-align: left;
    border-bottom-color: aliceblue;
    width: 50%;
  }
  .tabBCell2 {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border-bottom-color: aliceblue;
  }
  .clientProposalTextarea {
    margin-bottom: 1rem;
    max-width: 25rem;
    min-width: 25rem;
    max-height: 15rem;
  }
  .middlePortion {
    height: 89vh;
    overflow-y: scroll;
  }
  .rightContainer {
    height: 90vh;
    width: 25vw;
    display: flex;
    transition-property: width;
    transition-duration: 0.6s;
    transition-timing-function: ease-in;
    border-left: 1px solid #007fff14;
    .expandIconBox {
      position: absolute;
      width: 2.5rem;
      border: 1px solid #007fff14;
      height: 16rem;
      background: aliceblue;
      margin-top: 5rem;
      margin-left: -1.2rem;
      border-radius: 3px;
      .iconBtn {
        position: absolute;
        margin-left: -0.2rem;
        z-index: 1000;
      }
      .iconBtnTag {
        position: absolute;
        margin-top: 2.5rem;
        // margin-left: -1.2rem;
        z-index: 1000;
      }
    }
    .clientProposalTextareaWithError {
      margin-left: 3.4rem;
      margin-bottom: 0.2rem;
    }
    .proposalError {
      font-size: 1rem;
      color: rgb(211, 47, 47);
      max-width: 290px;
      margin-left: 3.4rem;
      margin-bottom: 1rem;
    }
    .middlePortion {
      height: 83vh;
      overflow-y: scroll;
    }
  }
  .clientProposalTextareaWithError {
    margin-bottom: 0.2rem;
  }
  .proposalError {
    font-size: 1rem;
    color: rgb(211, 47, 47);
    max-width: 290px;
    margin-bottom: 1rem;
  }
  .rightContainer_close {
    height: 90vh;
    width: 2.5rem !important;
    transition-property: width;
    transition-duration: 0.6s;
    transition-timing-function: ease-out;
    display: flex;
    overflow: hidden;
    border-left: 1px solid #007fff14;
    .expandIconBox {
      position: absolute;
      width: 2.5rem;
      border: 1px solid #007fff14;
      height: 16rem;
      background: aliceblue;
      margin-top: 5rem;
      margin-left: -1.2rem;
      border-radius: 3px;
      .iconBtn {
        position: absolute;
        margin-left: -0.2rem;
        z-index: 1000;
      }
      .iconBtnTag {
        position: absolute;
        margin-top: 2.5rem;
        margin-left: -1.2rem;
        z-index: 1000;
      }
    }
  }
  .ideaImagesContainer {
    margin-left: 2.5rem;
    width: 25vw;
    .ideaImagesContainerInside {
      max-height: 66vh;
      min-height: 65vh;
    }
    .ideaImagesContainerInsideNoDoc{
      height: 89vh;
    }
    .docTable {
      height: auto;
      .docmentHeader {
        position: sticky;
        top: 0;
        background-color: aliceblue;
        z-index: 1001;
      }
    }
  }

  // Idea workflow
  .containReject {
    position: relative;
    height: 5rem;
    display: flex;
    align-items: flex-start;
    // margin-top: -3rem;      
  }
  .containSubmit{
    position: relative;
    height: 5rem;
  }
  .clientReject{
    display: flex;
    align-items: center;
  }
  .clientAccept{      
    display: flex;
    align-items: flex-start;
    height: 6rem;
    // margin-left: -0.2rem;
    margin-top: -0.7rem;
  }
  .workflow_name { 
    white-space: nowrap;
    width: 16rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.87rem !important;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .container {
    display: grid;
    grid-template-columns: 25rem 4fr 0.1fr;
    .ideaName {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .ideaNameSpan {
        width: 18rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .clientProposalTextarea {
      margin-bottom: 1rem;
      max-width: 22rem;
      min-width: 22rem;
      max-height: 14rem;
    }
    .middlePortion {
      height: 86vh;
      overflow-y: scroll;
    }
    .rightContainer {
      height: 90vh;
      width: 25vw;
      display: flex;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-in;
      border-left: 1px solid #007fff14;
      .expandIconBox {
        position: absolute;
        width: 2.5rem;
        border: 1px solid #007fff14;
        height: 16rem;
        background: aliceblue;
        margin-top: 5rem;
        margin-left: -1.2rem;
        border-radius: 3px;
        .iconBtn {
          position: absolute;
          margin-left: -0.2rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: absolute;
          margin-top: 2.5rem;
          // margin-left: -1.2rem;
          z-index: 1000;
        }
      }
      .clientProposalTextareaWithError {
        margin-left: 3.4rem;
        margin-bottom: 0.2rem;
      }
      .proposalError {
        font-size: 1rem;
        color: rgb(211, 47, 47);
        max-width: 290px;
        margin-left: 3.4rem;
        margin-bottom: 1rem;
      }
      .middlePortion {
        height: 83vh;
        overflow-y: scroll;
      }
    }
    .rightContainer_close {
      height: 83vh;
      width: 2.5rem !important;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
      display: flex;
      overflow: hidden;
      border-left: 1px solid #007fff14;
      .expandIconBox {
        position: absolute;
        width: 2.5rem;
        border: 1px solid #007fff14;
        height: 16rem;
        background: aliceblue;
        margin-top: 5rem;
        margin-left: -1.2rem;
        border-radius: 3px;
        .iconBtn {
          position: absolute;
          margin-left: -0.2rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: absolute;
          margin-top: 2.5rem;
          margin-left: -1.2rem;
          z-index: 1000;
        }
      }
    }
    .ideaImagesContainer {
      margin-left: 2.5rem;
      width: 23vw;
      .ideaImagesContainerInside {
        max-height: 60vh;
        min-height: 55vh;
      }
      .ideaImagesContainerInsideNoDoc{
        height: 86vh;
      }
      .docTable {
        height: auto;
        .docmentHeader {
          position: sticky;
          top: 0;
          background-color: aliceblue;
          z-index: 1001;
        }
      }
    }

    // Idea workflow
    .containReject {
      position: relative;
      height: 5rem;
      display: flex;
      align-items: flex-start;
      // margin-top: -3rem;      
    }
    .containSubmit{
      position: relative;
      height: 5rem;
    }
    .clientReject{
      display: flex;
      align-items: center;
    }
    .clientAccept{      
      display: flex;
      align-items: flex-start;
      height: 6rem;
      // margin-left: -0.2rem;
      margin-top: -0.7rem;
    }
    .workflow_name { 
      white-space: nowrap;
      width: 13rem;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.87rem !important;
    }
  }

}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .container {
    display: grid;
    grid-template-columns: 24rem 4fr 0.1fr;
    .ideaName {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .ideaNameSpan {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .container {
    display: grid;
    grid-template-columns: 25rem 4fr 0.1fr;
    .ideaName {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .ideaNameSpan {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media all and (max-width: 600px) {
}
