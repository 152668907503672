.workstation{
    // background-color: #ddefff !important;
    height: 26px !important;
    border: 2px solid !important;
    width: 100%;
}

.workstation_active{
    background-color: #007fff !important;
    color: white !important;
    height: 26px !important;
    border: 2px solid !important;
    width: 100%;
}

.workstation:hover{
    background-color: #c5e0ff !important;    
}
.titleSectionMiddel{
    display: flex;    
    column-gap: 1rem;
    align-items: center;
    width: 50vw;
    padding: 0 1rem 0 0;
    margin-top: -3rem;
    height: 3rem;
}



@media all and (min-width: 1200px) and (max-width:1535px){

}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}

@media all and (max-width: 600px) {
    .titleSectionMiddel{
        display: flex;    
        column-gap: 1rem;
        align-items: center;
        width: 50rem;
        padding: 0 1rem 0 0;
        margin-top: -3rem;
        height: 3rem;
    }

}

.customCheckbox {
    cursor: pointer !important;
  }

  .joditContainer {
    background-color: #ece85a2b !important;
  }
  
  .jodit-wysiwyg {
    background-color: #ece85a2b !important;
  }