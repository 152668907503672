// .Container_wrapper {
//   display: flex;
//   flex-direction: Column;
//   gap: 1rem;
//   width: 100%;
//   margin: 0 auto;
//   height: 86vh;
// }

.trendingpartsresponsive,
.supplierpartcard,
.contactFlexresponsive,
.financialbasicDataResponsive {
  display: none !important;
}

.supplierpartcardButtons {
  display: none !important;
}

// .supplierImg{

// }

// .info_container {
//   width: 100%;
// background-color: #f5f5f5;

// padding: 0rem 8rem;
.info_main_container {
  display: flex;
  flex-direction: column;
  // column-gap: 1rem;
  // align-items: flex-start;
  width: 100%;
  padding: 0 10%;
  margin: 0 auto;
  background-color: white;
  // border-left: 0.1px solid #f5f5f5;
  // border-right: 0.1px solid #f5f5f5;

  .partTreeData {
    width: 20%;
    padding-right: 0.2rem;
    border-right: 1px solid;
  }

  .infoBox {
    width: 100%;
    padding: 0rem 1rem;
    margin-bottom: 2rem;

    .titleSectionHeader {
      width: 14rem;
      border-top-left-radius: 1rem;
      padding: 0.2rem 1rem;
    }

    .valueSectionHeader {
      width: 14rem;
      padding: 0.2rem 1rem;
    }

    .unitSectionHeader {
      width: 5rem;
      border-top-right-radius: 1rem;
      padding: 0.2rem 1rem;
    }

    .titleSection {
      width: 14rem;
    }

    .valueSection {
      width: 14rem;
    }

    .unitSection {
      width: 5rem;
    }
  }
}

.pinandDownloadsec {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-end;
  padding-right: 4rem;
}

// }

.image_container {
  width: 100%;
  // height: 79vh;
  padding: 0 1rem;
}

.costing_container {
  width: 100%;
  padding: 0 0 0 1rem;
  margin: 0 auto;
}

.PartInfo_container {
  width: 100%;
  margin: 0 auto;
}

.costing_container {
  width: 100%;
  margin: 0 auto;
}

// --------------contact-----------------------
.contactCard {
  width: 49%;
}

// -----------------------------------------


@media all and (min-width: 1200px) and (max-width: 1535px) {
  // .Container_wrapper {
  //   display: flex;
  //   flex-direction: Column;
  //   gap: 1rem;
  //   width: 100%;
  //   padding: 0 1rem;
  //   margin: 0 auto;
  //   height: 90vh;
  // }

  // .info_container {
  //   width: 100%;
  // background-color: #f5f5f5;

  // padding: 0rem 8rem;
  .info_main_container {
    // display: flex;
    // column-gap: 1rem;
    // align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    // 	border-left: 0.1px solid #f5f5f5;
    // border-right: 0.1px solid #f5f5f5;

    .infoBox {
      padding: 0rem 1rem;
      margin-bottom: 2rem;

      .titleSection {
        width: 15rem;
      }

      .valueSection {
        width: 100%;
      }
    }
  }

  .partTreeData {
    width: 20%;
    // height: 25rem;
    padding-right: 0.2rem;
    // border-bottom: 1px solid;
  }

  .pinandDownloadsec {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-end;
    padding-right: 4rem;
  }
}

.image_container {
  min-width: 13rem;
  // height: 72vh;
  // padding: 0 1rem;
  padding-bottom: 1rem;
  margin-right: 0.2rem;
}

.costing_container {
  width: 100%;
  padding: 0 0rem;
  margin: 0 auto;
}

.PartInfo_container {
  width: 100%;
  margin: 0 auto;
}

.costing_container {
  width: 100%;
  margin: 0 auto;
}

// }

@media (min-width: 1280px) {
  // .imageContainer {
  //   left: 10rem;
  //   /* For large screens and up */
  // }
}

// @media all and (min-width: 601px) and (max-width: 940px) {
//   .contactCard {
//     width: 100%;
//   }
// }

@media only screen and (max-width: 1180px) {
  // .info_container {
  //   width: 100%;

  // padding: 0rem 8rem;
  .info_main_container {
    // display: flex;
    // column-gap: 1rem;
    // align-items: flex-start;
    width: 100%;
  }

  // }
}

@media (max-width: 960px) {
  // .imageContainer {
  //   left: 10rem;
  //   /* For medium screens and up */
  // }

  // .info_container {
  //   width: 100%;

  // padding: 0rem 8rem;
  .info_main_container {
    // display: flex;
    // column-gap: 1rem;
    // align-items: flex-start;
    width: 100%;
  }

  // }

  .contactFlex {
    flex-direction: column !important;
    gap: 0.5rem !important;
  }

  .contactFlex {
    display: none !important;
  }

  .contactFlexresponsive {
    display: flex !important;
    flex-wrap: wrap;
    gap: 0.5rem !important;
  }
}


@media (max-width: 840px) {
  .aboutSellerContainer {
    flex-direction: column !important;
  }

  .aboutSeller {
    width: "100%" !important;
  }

  // .imageContainer {
  //   left: 6rem;
  //   /* For medium screens and up */
  //   width: 11rem !important;
  //   height: 11rem !important;
  // }

  .tabsContainer {
    display: none !important;
  }

  .trendingPartsContainer {
    margin-top: 2rem !important;
  }

  .docItem {
    flex-direction: column !important;
  }

  .docItemCard {
    width: 100% !important;
  }
  .businessDataResponsive {
    flex-direction: column !important;
    row-gap: 0 !important;
  }
  .contactCard {
    width: 100% !important;
  }
}

@media all and (max-width: 760px) {
  // .ClaimBusinesscontainer {
  //   display: none;
  // }

  .contactscontainer {
    flex-direction: column !important;
  }

  .contactCard {
    width: 100% !important;
  }

  .contactFlex {
    gap: 0.5rem !important;
  }
}

//create media query for less then 600px


@media all and (max-width: 640px) {
  // .imageContainer {
  //   left: 5rem;
  //   top: 5rem;
  //   width: 9rem !important;
  //   height: 9rem !important;
  // }

  // .bgContainer {
  //   height: 10rem !important;
  //   margin-bottom: 4rem !important;
  // }

  .shareIcon {
    font-size: 1.35rem !important;
  }

  .business_name {
    font-size: 1.15rem !important;
  }

  .listedPartsContainer {
    margin-top: 1rem !important;
  }

  .listedParts {
    display: none !important;
  }

  .websiteflex {
    justify-content: space-between !important;
  }
}


@media all and (max-width: 600px) {
  .info_main_container{
    padding: 0 1rem;
  }
  .PartInfo_container {
    width: 100%;
    margin: 0 auto;
  }

  // .financialbasicData {
  //   // display: none !important;
  // }

  .supplierpartcardd {
    display: none !important;
  }

  .supplierpartcard,
  .supplierpartcardButtons {
    display: flex !important;
  }

  .downloadtext {
    display: none !important;
  }

  .docDownload {
    width: 6rem !important;
  }

  .contactCard {
    width: 100% !important;
  }

  .contactAvatar {
    width: 4rem !important;
    height: 4rem !important;
  }

  .supplierdocheading {
    margin-top: 1rem !important;
  }

  .doc_name {
    font-size: 1rem !important;
  }

  .partsContainersection {
    margin-top: 2rem !important;
  }

  .mainContainer {
    display: none !important
  }

  .contactscontainer {
    display: none !important
  }
}



@media all and (max-width: 540px) {
  // .imageContainer {
  //   left: 4rem;
  //   top: 4rem;
  //   width: 7.5rem !important;
  //   height: 7.5rem !important;
  // }

  // .bgContainer {
  //   height: 8rem !important;
  //   margin-bottom: 4.5rem !important;
  // }


  .websiteflex {
    width: 100% !important;
  }

  .trendingpartscontiner {
    flex-direction: column !important;
  }

  .trendingparts,
  .trendingpartsgraphcontainer {
    width: 100% !important;
  }

  .trendingpartscontiner {
    height: auto !important;

  }

  .trendingparts {
    display: none !important;
  }

  .trendingpartsresponsive {
    padding: 0.5rem !important;
    margin: 1rem 0 !important;
    display: flex !important;
    gap: 1rem !important;
    flex-wrap: wrap !important;
  }

  .trendingpartsresponsive {
    width: auto !important;
  }

  .docCardContainer,
  .docItemCard {

    gap: 1rem !important;
  }

  .viewpdf {
    width: 30% !important;
    // height: 3rem !important;
  }

  .businessDataResponsive {
    flex-direction: column !important;
    row-gap: 0 !important;
  }

  // .supplierpartcardButton{
  // 	width: 100 !important;

  // }
  // .supplierpartcardButtonInner{
  // 	width: 100% !important;
  // }
}

@media all and (max-width: 480px) {
  .supplierpartcomparebutton {
    display: none !important;
  }

  .supplierpartcarddetails {
    flex-wrap: wrap !important;
    gap: 0.2rem !important;
  }

  .supplierpartcard {
    height: 10rem !important;
  }

  .supplierpartflex {
    gap: "0.5rem" !important;
  }
}

@media all and (max-width: 460px) {
  .websiteflex {
    display: none !important;
  }
}


@media(min-width: 600px) {
  .mainContainerMobile {
    display: none !important
  }
}