  .qrScannerdiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 84vh;
    flex-direction: row;
    .qrScannerleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrScannerimg {
        width: 20rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .qrScannerright {
      text-align: center;
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .videoStyle{
    width: 133%;   
    margin-top: -6rem;
    margin-bottom: -5rem;   
  }
  
  @media all and (min-width: 1200px) and (max-width: 1535px) {
    .qrScannerdiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 84vh;
      flex-direction: row;
      .qrScannerleft {
        display: flex;
        flex-direction: column;
        align-items: center;
        .qrScannerimg {
          width: 20rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }
        .titletext {
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
        }
      }
      .qrScannerright {
        text-align: center;
        .titletext {
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    .videoStyle {
      width: 133%;
      margin-top: -5rem;
      margin-bottom: -4rem;
    }
  }
  
  @media all and (min-width: 900px) and (max-width: 1199px) {
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
  }
  
  @media all and (max-width: 600px) {
    .qrScannerdiv {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 75vh;
      flex-direction: column;
      .qrScannerleft {
        display: flex;
        flex-direction: column;
        align-items: center;
        .qrScannerimg {
          width: 15rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }
        .titletext {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
        }
      }
      .qrScannerright {
        text-align: center;
        .titletext {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    .videoStyle{
      width: 100% !important; 
      margin-top: 0rem;
      margin-bottom: 0rem;     
    }
  }
  