.searchVehicle {
  max-height: 155px;

  .searchFilter {
    display: flex;
    padding: 0 0.5rem;
    flex-wrap: wrap;
    column-gap: 30px;
    padding-bottom: 0.5rem;
    align-items: center;

    // box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    .searchVehicle {
      font-weight: 600;
      color: #007fff;
      padding-left: 0.5rem;
      padding-top: 0.2rem;
      transition-duration: transform 0.5s ease;
    }
  }
}

.searchCont {
  height: 66vh;
  overflow-y: scroll;
}

.searchContfull {
  height: 85vh;
  overflow-y: scroll;
}

.searchresultHeader {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: right;

  .history_pagination {
    display: flex;
    justify-content: right;
  }
}

.searchHeader {
  padding: 0.5rem 0rem;
  font-weight: 500;
  font-size: initial;
}

.tableHcell {
  border-bottom: none !important;
  padding: 0 0.5rem !important;
  min-width: 20rem !important;
}

.tableBCell {
  border-bottom: none !important;
  padding: 0 0.5rem !important;
  min-width: 10rem;
  &:first-child {
    min-width: 10rem;
    white-space: nowrap;
  }
}

.accordContainer {
  // background-color: aliceblue !important;
  border-color: #bbdefb !important;
}

.changeVehicle {
  background-color: #007fff !important;
  color: #ffffff !important;
  border-radius: 1rem;
}

.changeVehicle:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.searchHistoryBtn {
  background-color: #007fff !important;
  color: #ffffff !important;
}

.searchHistoryBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
  background-color: #0069d3 !important;
}

.btn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
  opacity: 1;
}

.accContainer {
  padding: 0.5rem 1rem;
}

// .accordionDetails {
//   // color: #007fff;

// }
.accordianInnerDetails {
  // color: #007fff;
  padding: 0.5rem 1rem;
  width: auto;
  min-width: 48rem;
}

.searchModTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 8px;

  .searchmodulelink {
    display: flex;
    color: #ffffff;
  }
}

.searchpara {
  color: #007fff;
  padding: 0.1rem;
  // display: flex;
}

.searchparavalue {
  color: #007fff;
  // display: flex;
  // flex-wrap: wrap;
  margin-top: 1%;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 0.5rem;
  // grid-row-gap: 5px;
  // grid-column-gap: 10px;
}

.searchTab {
  display: flex;
  color: #007fff;
  margin-bottom: 1rem;

  // .searchList {
  //   margin: 0 0.5rem;
  // }
  // .imageSec {
  //   display: flex;
  //   padding-top: 0.5rem;
  //   .modImage {
  //     height: 5rem;
  //     margin: 0 0.1rem;
  //     .naImage {
  //       width: 100%;
  //       height: 100%;
  //       background-color: #0000ff26;
  //     }
  //   }
  // }
  .MuiAccordionSummary-content {
    padding: 6px !important;
  }

  .modulelinkItem {
    padding: 0.3rem 0.5rem;
    background-color: #007fff;
    color: #ffffff;
  }

  .modulelinkItem:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
    background-color: #0069d3;
  }
}

.serachlogs {
  margin: 0 auto;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #c9dae1;
}

.searchName {
  font-size: 1.1rem;
  font-weight: 600;
  color: #007fff;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // font-size: 16px;
  // padding-left: 1.3rem;
}

.searchlogdata {
  display: grid;
  color: #007fff;
}

.clearbtn {
  position: sticky !important;
  bottom: 0px;
  width: 100%;
  color: white !important;
  background-color: #007fff !important;

  &:hover {
    transform: scale(1.1);
    transition: 0.5s ease;
    background-color: #0069d3;
  }
}

.recentSearch {
  /* background-color: #f0f7ff; */
  border-color: #007fff;
  color: rgb(62, 80, 96);
  /* border-radius: 5px; */
  /* border-bottom: 1px solid grey; */
  margin-bottom: 0.3rem;

  //    padding: 1rem;
  line-height: 1.6;
}

.searchTitle {
  display: flex;
  justify-content: space-between;
}

.pinIcon:hover {
  transition: ease 0.5s;
  transform: scale(1.2);
}

.insideData {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.2rem;
  line-height: 1;
  transition: ease 0.5s;
  margin: 0 0.5rem;
  color: #007fff;

  .recentSearchlog {
    padding-right: 1rem;
  }
}

.insideData:hover {
  background-color: aliceblue;
  border-color: #007fff;
  border-radius: 5px;
  color: #007fff;
  transform: scale(1.1);
}

.searchData {
  margin: 0 1rem;
  font-weight: 500;
  font-size: initial;
  display: flex;
  justify-content: space-between;
}

.Noserach {
  display: flex;
  justify-content: center;
  margin-top: 15rem;
  flex-direction: column;
  /* width: 7rem; */
  align-items: center;
}

.noresult {
  color: #007fff;
}

.searchtext {
  width: 7rem;
}

.clearBtnsearchHistory {
  cursor: pointer;
}

.hideSearchVehicle {
  display: none;
  transition: transform 0.5s ease;
}

.chipContainer {
  border: 1px solid #007fff;
  // padding: 0.3rem 0rem 0.3rem 0rem;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.1rem 0.5rem;
  border-radius: 2rem;

  // flex-direction: column;
  .searchGroupClear {
    align-items: center;
    display: flex;

    .clearIcon {
      cursor: pointer;
      font-size: 1.8rem;

      // opacity: 0;
      &:hover {
        // opacity: 1;
        transform: scale(1.1);
        transform: transform 0.5s ease;

      }
    }
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .searchVehicle {
    height: 129px;

    .searchFilter {
      display: flex;
      padding: 0 0.5rem;
      flex-wrap: wrap;
    }
  }

  .searchCont {
    height: 64vh;
    overflow-y: scroll;
  }

  .searchContfull {
    height: 83vh;
    overflow-y: scroll;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .searchresultHeader {
    margin-top: 1rem;
  }

  .searchCont {
    height: 66vh;
    overflow-y: scroll;
  }

  .searchContfull {
    height: 85vh;
    overflow-y: scroll;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .searchresultHeader {
    margin-top: 1rem;
  }

  .searchCont {
    height: 66vh;
    overflow-y: scroll;
  }

  .searchContfull {
    height: 85vh;
    overflow-y: scroll;
  }
}

@media all and (max-width: 600px) {
  .searchresultHeader {
    margin-top: 1rem;
  }
}