.header {
  background-color: #f7f7f7;
  padding: 0rem 1rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 2.5rem;
  .pageView {
    line-height: 1;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
}
.accordDetails {
  max-height: 58.6rem;
  // margin-bottom: 0.5rem;
}
.tableCell{
  padding: 0.1rem 0.5rem !important;
  border-bottom: none !important;
  font-size: 12px !important;
}
.tableCell:hover{
  padding: 0.1rem 0.5rem !important;
  border-bottom: none !important;
  font-size: 12px !important;
  text-decoration: underline;
}
// .tableRowlink:hover{
//   transform: scale(1.05);
//   transition: transform 0.5s ease;
// }

// .featureName:hover{

//    transform: scale(1.1);

// }

@media all and (min-width: 1536px) {
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .accordDetails {
    max-height: 27rem;
    // margin-bottom: 0.5rem;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .accordDetails {
    max-height: 33rem;
    // margin-bottom: 0.5rem;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .accordDetails {
    max-height: 55rem;
    // margin-bottom: 0.5rem;
  }
}

@media all and (max-width: 600px) {
}
