.selectMaterial {
    width: 100%;
    // display: flex;
    // align-items: flex-start;
    // column-gap: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(228, 110, 110, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 10px;
    overflow: hidden;
    max-height: 80vh;
    background-color: white;
}

.selectMaterialCost {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 10px;
    padding: 0.5rem 0;

    .selectedMaterialCalc {
        display: flex;
        align-items: flex-start;
        column-gap: 0.5rem;
    }
}


@media all and (min-width: 1200px) and (max-width:1535px){
    .selectMaterial {
        width: 100%;
        // display: flex;
        // align-items: flex-start;
        // column-gap: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(228, 110, 110, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        border-radius: 10px;
        overflow: hidden;
        max-height: 75vh;
    }
}

