.Toastify__toast--default {
    background: #017fff  !important; 
    min-height: 5rem !important;
    color: #fff !important;
    font-size: 1rem;
    font-weight: bold !important;
    border-radius: 5px !important;
    /* width: 34vw;
    padding: 30px 20px; */
}

.Toastify__close-button--default {
    color: #fff !important;
    opacity: 0.7;
}