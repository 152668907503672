.expandIconBox {
	width: 4rem;
	padding-top: 0.4rem;
	text-align: center;
	border-right: 1px solid #007fff14;
	height: 95vh;
	.sideTitle {
		transform: rotate(270deg);
		white-space: nowrap;
		margin-top: 40vh;
	}
}

.parent_container {
	width: 100%;
	height: 95vh;
	background: linear-gradient(
		290deg,
		rgb(0, 136, 122) 0%,
		rgb(255, 255, 255) 100%
	);
}

@media all and (min-width: 1200px) and (max-width:1535px){
	.expandIconBox {
		width: 4rem;
		padding-top: 0.4rem;
		text-align: center;
		border-right: 1px solid #007fff14;
		height: 93vh;
		.sideTitle {
			transform: rotate(270deg);
			white-space: nowrap;
			margin-top: 40vh;
		}
	}
	
	.parent_container {
		width: 100%;
		height: 93vh;
		background: linear-gradient(
			290deg,
			rgb(0, 136, 122) 0%,
			rgb(255, 255, 255) 100%
		);
	}
}