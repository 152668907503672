.container {
    width: 100%;
    height: 95%;

    .topheader {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        align-items: center;
    }

    .goback {
        cursor: pointer;
        padding-top: 0.5rem;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        // font-size: 1.5rem;
    }

    .mailname {
        font-size: 1.5rem;
        justify-content: space-between;

        .title {
            font-size: 1.5rem;
            display: inline-block;
            color: #007fff;
        }
    }

    .mail_body {

        .userContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            row-gap: 0.5rem;
            margin-top: 0.5rem;
            width: 98vw;
        }

        .parents_userContainer {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }

        .userContainersubject {
            display: flex;
            flex-direction: row;
            align-items: center;
            row-gap: 0.5rem;
            margin-top: 1rem;
            width: 98vw;
        }


        .details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0.5rem;

            .tabletitle {
                color: #007fff;
                font-size: 1rem;
                // margin-left: 5rem;
            }

            // .mailstatus {
            //     // float: right;
            //     // margin-right: 5rem;
            // }

            // .mailstatustable {
            //     // margin: 0 auto;
            //     // margin: 0 5rem;
            // }
        }

        // .mailstatustable {
        //     margin: 0 auto;
        //     margin: 0 5rem;
        // }

        // .user {
        //     margin-top: 1rem;
        // }
        .emailInput {
            color: #007fff;
            display: flex;
            font-size: 1rem;
            vertical-align: middle;
            padding: 0 1rem;

            .multiEmails {
                margin-left: 1rem;
                margin-bottom: 1rem;

                .selectEmailContainer {
                    border: gray 1px solid;
                    max-width: fit-content;
                    border-radius: 0.5rem;
                    padding: 0.5rem;
                    margin-bottom: 0.5rem;
                    display: flex;
                    font-size: 11px !important;

                    .spanStyle {
                        margin-left: 0.5rem;
                        cursor: pointer;
                    }
                }
            }
        }

        .richEditor {
            // margin-top: 3rem;
            overflow-y: auto;
            max-height: 65vh;
            margin: 2rem 3rem;

            .editorInput {

                // border: 1px solid red;
                // width: 80%;
                // margin: 3rem;
                .details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    // margin-top: 3rem;
                    color: #007fff;
                    margin-bottom: 0.5rem;
                }

                .meetingTable {
                    height: 30rem;
                    // margin-top: -5rem;
                }
            }
        }

        .sendIcon {
            float: right;
            font-size: 2rem;
            cursor: pointer;
            font-size: 1rem;
        }
    }

}