.selectMaterial {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(228, 110, 110, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 10px;
    overflow: hidden;
    max-height: 75vh;
    background-color: white;
}

.selectMaterialCost {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 10px;
    padding: 0 0 0.5rem 0;
    height: 61vh;
    overflow: hidden;
    .selectedMaterialCalc {
        display: flex;
        align-items: flex-start;
        column-gap: 0.5rem;
    }
}

.tableCell {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.chipSection {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 0.5rem;
    padding-bottom: 0.1rem;
    width: 100%;

    .calulatorChip_active {
        background-color: #007fff !important;
        color: white !important;
        height: 27px;

        .chipDeleteIcon {
            color: white !important;
            opacity: 0.7;

        }

        .chipDeleteIcon:hover {
            opacity: 1;
        }
    }

    .calulatorChip {
        background-color: #ddefff !important;
        color: #007fff !important;
        height: 27px;
    }

}

@media all and (min-width: 1200px) and (max-width:1535px){
    .selectMaterial {
        width: 100%;
        // display: flex;
        // align-items: flex-start;
        // column-gap: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(228, 110, 110, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        border-radius: 10px;
        overflow: hidden;
        max-height: 75vh;
    }
}