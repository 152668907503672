.bomImageSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  width: 100%;
  gap: 0.5rem;

  .bomgridContainer {
    width: 100%;
    height: 10rem;
    max-height: 10rem;
    border-radius: 10px;

    // object-fit: contain;
    .bomgridImages {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }

    .imageOptions {
      display: none;
      margin-top: -4.5rem;

      .optionDiv {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        align-items: center;
        column-gap: 1rem;
        justify-content: center;
        background-color: rgb(221 239 255 / 69%);
      }
    }
  }

  .bomgridContainer:hover .imageOptions {
    display: block;
    position: relative;

    .optionDiv {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
      background-color: rgb(221 239 255 / 69%);

    }
  }
}
.bomThreeSixtySection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 1rem;

  .bomThreeSixtyContainer {
    width: 100%;
    height: 7rem;
    max-height: 7rem;
    border-radius: 10px;

    // object-fit: contain;
    .bomthreeSixtyVideo {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
      padding: 0 1rem;
    }

    .imageOptions {
      display: none;
      margin-top: -2.5rem;

      .optionDiv {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        align-items: center;
        column-gap: 1rem;
        justify-content: center;
        background-color: rgb(221 239 255 / 69%);
      }
    }
  }

  .bomThreeSixtyContainer:hover .imageOptions {
    display: block;
    position: relative;

    .optionDiv {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
      background-color: rgb(221 239 255 / 69%);

    }
  }
}
.bomdeleteImages{
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 7rem;
}
.withoutimages{
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 1rem;
  border: 1px solid #ddefff;
  border-radius: 10px;
  margin-bottom: 1rem;
  .bomThreeSixtyContainer {
    width: 100%;
    height: 10rem;
    max-height: 10rem;
    border-radius: 10px;

    // object-fit: contain;
    .bomUploadVideo {
      cursor: pointer;
      height: 10rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      border-radius: 10px;
      padding: 0 1rem;
    }

    .imageOptions {
      display: none;
      margin-top: -2.5rem;

      .optionDiv {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        align-items: center;
        column-gap: 1rem;
        justify-content: center;
        background-color: rgb(221 239 255 / 69%);
      }
    }
  }

  .bomThreeSixtyContainer:hover .imageOptions {
    display: block;
    position: relative;

    .optionDiv {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
      background-color: rgb(221 239 255 / 69%);

    }
  }

}
.loaderStyles{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:1rem;
  width: 100%;
}
.documentSection {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 23vw;
  padding: 0.6rem;
  cursor: pointer;

  .AddDocuments {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1rem;
  }

  .listDocumentsec {
    align-items: center;
    width: 100%;
    display: flex;

    // column-gap: 1rem;
    .listDocuments {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 1rem;
    }
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .bomImageSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    width: 100%;
    gap: 0.5rem;

    .bomgridContainer {
      width: 100%;
      height: 7rem;
      max-height: 7rem;
      border-radius: 10px;

      // object-fit: contain;
      .bomgridImages {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
      }

      .imageOptions {
        display: none;
      }
    }

    .bomgridContainer:hover .imageOptions {
      display: block;
    }
  }

  .documentSection {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 20vw;

    .AddDocuments {
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 1rem;
    }

    .listDocumentsec {
      align-items: center;
      width: 100%;
      display: flex;

      .listDocuments {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 1rem;
      }
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {}

@media all and (max-width: 600px) {
  .bomImageSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    width: 100%;
    gap: 0.5rem;

    .bomgridContainer {
      width: 100%;
      height: 7rem;
      max-height: 7rem;
      border-radius: 10px;

      // object-fit: contain;
      .bomgridImages {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
      }

      .imageOptions {
        display: none;
      }
    }

    .bomgridContainer:hover .imageOptions {
      display: block;
    }
  }

  .documentSection {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 20vw;

    .AddDocuments {
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 1rem;
    }

    .listDocumentsec {
      align-items: center;
      width: 100%;
      display: flex;

      .listDocuments {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 1rem;
      }
    }
  }
}