$track-color: #e1e1f3;
// $thumb-color: #7e8a96;
// $thumb-hover-color: #555;
$thumb-color: #09C6F9;
$thumb-hover-color: #045DE9;

[adl-scrollbar="true"] {
    // for firefox
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: lighten($color: $thumb-color, $amount: 10) $track-color;
    scroll-behavior: smooth;
    // for webkit
    overflow: overlay;
}

[adl-scrollbar="true"][adl-scrollbar-width="0.2"]::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
}

[adl-scrollbar="true"][adl-scrollbar-width="0.3"]::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}

[adl-scrollbar="true"][adl-scrollbar-width="0.4"]::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

[adl-scrollbar="true"][adl-scrollbar-width="0.5"]::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

[adl-scrollbar="true"][adl-scrollbar-width="0.6"]::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
}

[adl-scrollbar="true"]::-webkit-scrollbar {
    display: block;
}

[adl-scrollbar="true"]:hover::-webkit-scrollbar {
    display: block;
}

[adl-scrollbar="true"]::-webkit-scrollbar-track {
    background: $track-color;
}

[adl-scrollbar="true"]::-webkit-scrollbar-thumb {
    // background: $thumb-color;   
    background-image: linear-gradient(#09C6F9, #045DE9);
    border-radius: 10px;
}

[adl-scrollbar="true"]::-webkit-scrollbar-thumb:hover {
    // width: 0.4rem;
    background: $thumb-hover-color;
}