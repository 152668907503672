.CostingModuleSection {
  height: 85.5vh;
  width: 100%;
  .costTable {
    padding-bottom: 0.5rem;
    height: 66vh;
    .CostSecTable {
      height: 58vh;
    }
    .tbodycell {
      padding: 0rem 0rem;
      font-size: 12px;
      width: auto;
    }
    .tbodycellp {
      padding: 0.2rem 0.5rem;
      // padding-left: 5%;
      font-size: 12px;
      width: auto;
    }
    .tbodycellRight {
      padding: 0.2rem 0.5rem;
      font-size: 12px;
      text-align: right;
    }
  }
  .chartShort {
    display: block;
    padding: 1rem 0;
    margin: 0 auto;
    position: sticky;
    top: 0;
    background: white;
  }
  .chartWND {
    padding: 1rem;
    position: sticky;
    top: 0;
    background: white;
  }
  .chartLong {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1rem;
    align-items: center;
    position: sticky;
    top: 0;
    background: white;
  }
}
.cellData{
  font-size: 12px;
}
.imagecosting {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0rem 3rem 0rem;
}
.toolTip {
  background-color: white;
  padding: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  .text {
    font-size: 0.7rem;
  }
}

// .costingTable {
//   display: flex !important;
//   flex-direction: row;
//   font-size: 12px;
//   // width: 50rem !important;
//   justify-content: space-around;
//   outline: 1px solid #ddefff !important;
// }
.costingAccrodian {
  //  padding-left: 7.4rem;
  // margin-left: 9rem;
  // width: 12.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .firstspan {
    min-width: 5rem;
    text-align: right;
  }
  .secondspan {
    min-width: 5rem;
    text-align: right;
  }
  // column-gap: 2rem;
}
.accTitle {
  align-items: center;
  display: flex;
  line-height: 1.2;
}
.accIcon {
  transform: rotate(0deg);
}
.accIconExpand {
  transform: rotate(90deg);
}

.chartDiv {
  display: flex;
  .chartLeftDiv {
    display: block;
  }
  .chartRightDiv {
    display: block;
    margin-left: -1rem;
  }
}

@media all and (min-width: 1536px) {
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .CostingModuleSection {
    height: 82vh;
    .costTable {
      padding-bottom: 0.5rem;
      height: 62vh;
      .CostSecTable {
        height: 47vh;
      }
      .tbodycell {
        padding: 0rem 0rem;
        font-size: 12px;
        width: auto;
      }
      .tbodycellnp {
        padding: 0rem 0rem;
        font-size: 12px;
        width: auto;
      }
      .tbodycellp {
        padding: 0.2rem 0.5rem;
        font-size: 12px;
        width: auto;
      }
      .tbodycellRight {
        padding: 0.2rem 0.5rem;
        font-size: 12px;
        text-align: right;
      }
      .tableCellLeft{
        border-bottom: none;
        font-size:12px;
        width: 50%;
        padding: 0 0.5rem !important;
      }
      .tableCellMiddleW{
        border-bottom: none;
        width: 20%;
        text-align: right;
        font-size: 12px;
        padding: 0 0.5rem !important;
      }
      .tableCellMiddle{
        border-bottom: none;
        width: 15%;
        text-align: right;
        font-size: 12px;
        padding: 0 0.5rem !important;
      }
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .CostingModuleSection {
    height: 79.5vh;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
}
