.InwardMailEditor {
    height: 60vh !important;
    /* Adjust to your desired height */
  }
  
  @media all and (min-width: 1200px) and (max-width:1535px) {
    .InwardMailEditor {
      height: 43vh !important;
      /* Adjust to your desired height */
    }
  }