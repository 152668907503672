.quickCompareSticky {
    position: absolute;
    top: 0vh;
    right: 0;
    height: 15rem;
    z-index: 1000;
    transform: translateX(300px);
    transition: all 1s linear 0s;

    .mainCompare {
        display: flex;
        

        .titleText {
            width: 3rem;
            display: flex;
            align-items: center;
            background: #007fff;
            color: #ffffff;
            line-height: 1.2;
            writing-mode: vertical-rl;
            height: fit-content;
            padding: 1rem 0;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            justify-content: flex-end;
            transform: rotate(180deg);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            // margin-top: 8rem;
        }

        .contSection {
            background: aliceblue;
            padding: 1rem;
            // border-top-left-radius: 10px;
            // border-bottom-left-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            z-index: 1000;
            // border-top: 2px solid;
            border-left: 2px solid;

            // border-bottom: 2px solid;
            .partSec {
                transform: rotate(270deg);
                white-space: nowrap;
            }

        }
    }

}

.quickCompareEnable {
    position: absolute;
    // top: 0vh;
    // right: 0;
    bottom: 0;
    left: 0;
    height: 14rem;
    z-index: 1000;
    transform: translateY(165px);
    transition: all 500ms linear 0s;
    width: 100%;

    .mainCompare {
        display: block;        

        .titleText {
            height: 2rem;
            display: flex;
            column-gap: 10px;
            align-items: center;
            background-color: #007fff;
            color: "#ffffff";
            line-height: 1.2;
            /* writing-mode: vertical-rl; */
            width: -moz-fit-content;
            width: fit-content;
            padding: 0 3rem;
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
            justify-content: flex-end;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            margin-left: 20vw;
            clip-path: polygon(15% 0, 85% 1%, 100% 100%, 0% 100%);
        }

        .contSection {
            background: aliceblue;
            padding: 1rem;
            // border-top-left-radius: 10px;
            // border-bottom-left-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            z-index: 1000;
            // border-top: 2px solid;
            border-top: 2px solid;

            // border-bottom: 2px solid;
            .partSec {
                transform: rotate(270deg);
                white-space: nowrap;
            }
        }
    }

}

.quickCompareOpen {
    position: absolute;
    // top: 0vh;
    // right: 0;
    bottom: 0;
    left: 0;
    height: 14rem;
    z-index: 1000;
    transform: translateY(0);
    transition: all 500ms linear 0s;
    width: 100%;

    .mainCompare {
        display: block;
        .titleText {
            height: 2rem;
            display: flex;
            column-gap: 10px;
            align-items: center;
            background-color: #007fff;
            color: "#ffffff";
            line-height: 1.2;
            /* writing-mode: vertical-rl; */
            width: -moz-fit-content;
            width: fit-content;
            padding: 0 3rem;
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
            justify-content: flex-end;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            margin-left: 20vw;
            clip-path: polygon(15% 0, 85% 1%, 100% 100%, 0% 100%);
        }

        .contSection {
            background: aliceblue;
            padding: 1rem;
            // border-top-left-radius: 10px;
            // border-bottom-left-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            z-index: 1000;
            // border-top: 2px solid;
            border-top: 2px solid;

            // border-bottom: 2px solid;
            .partSec {
                transform: rotate(270deg);
                white-space: nowrap;
            }


        }
    }

}

@media all and (min-width: 1200px) and (max-width:1535px) {
    .quickCompareEnable {
        position: absolute;
        // top: 0vh;
        // right: 0;
        bottom: 0;
        left: 0;
        height: 14rem;
        z-index: 1000;
        transform: translateY(142px);
        transition: all 500ms linear 0s;
        width: 100%;

        .mainCompare {
            display: block;            

            .titleText {
                height: 2rem;
                display: flex;
                column-gap: 10px;
                align-items: center;
                background-color: #007fff;
                color: "#ffffff";
                line-height: 1.2;
                /* writing-mode: vertical-rl; */
                width: -moz-fit-content;
                width: fit-content;
                padding: 0 3rem;
                box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
                justify-content: flex-end;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                margin-left: 20vw;
                clip-path: polygon(15% 0, 85% 1%, 100% 100%, 0% 100%);
            }

            .contSection {
                background: aliceblue;
                padding: 1rem;
                // border-top-left-radius: 10px;
                // border-bottom-left-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
                z-index: 1000;
                // border-top: 2px solid;
                border-top: 2px solid;

                // border-bottom: 2px solid;
                .partSec {
                    transform: rotate(270deg);
                    white-space: nowrap;
                }
            }
        }

    }

    .quickCompareOpen {
        position: absolute;
        // top: 0vh;
        // right: 0;
        bottom: 0;
        left: 0;
        height: 14rem;
        z-index: 1000;
        transform: translateY(0);
        transition: all 500ms linear 0s;
        width: 100%;

        .mainCompare {
            display: block;            

            .titleText {
                height: 2rem;
                display: flex;
                column-gap: 10px;
                align-items: center;
                background-color: #007fff;
                color: "#ffffff";
                line-height: 1.2;
                /* writing-mode: vertical-rl; */
                width: -moz-fit-content;
                width: fit-content;
                padding: 0 3rem;
                box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
                justify-content: flex-end;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                margin-left: 20vw;
                clip-path: polygon(15% 0, 85% 1%, 100% 100%, 0% 100%);
            }

            .contSection {
                background: aliceblue;
                padding: 1rem;
                // border-top-left-radius: 10px;
                // border-bottom-left-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
                z-index: 1000;
                // border-top: 2px solid;
                border-top: 2px solid;

                // border-bottom: 2px solid;
                .partSec {
                    transform: rotate(270deg);
                    white-space: nowrap;
                }


            }
        }

    }
}


@media all and (min-width: 900px) and (max-width:1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {}

