.container {
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  height: 100vh;
  .expandIconBox {
    float: left;
    width: 3.5rem;
    padding-top: 0.8rem;
    text-align: center;
  }
  .projectSelectSection {
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    column-gap: 1rem;

    .iconSec {
      margin-top: 0.5rem;
      .drawerIcon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: aliceblue;
      }
      .drawerIcon:hover {
        background-color: none;
      }
      .MuiButtonBase-root.MuiButton-root:hover {
        background-color: none;
      }
    }

    .SelectedProject {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      .copareNameSec {
        display: flex;
        column-gap: 10px;
        width: 86vw;
        align-items: center;
        .chipBox {
          border-radius: 2rem;
          align-items: center;
          column-gap: 5px;
          display: flex;
          padding: 0.2rem 0.5rem;
          white-space: nowrap;
          height: 2rem;
          .editIcon {
            opacity: 0.8;
          }
          .editIcon:hover {
            opacity: 1;
            transform: scale(1.2);
            transition: transform 0.5s ease;
          }
          .deleteIcon {
            opacity: 0.8;
          }
          .deleteIcon:hover {
            opacity: 1;
            transform: scale(1.1);
            transition: transform 0.5s ease;
          }
        }
      }
    }
  }

  .productImageSec {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    padding: 1rem;
    .compareProductImage {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      .prodImage:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
  }

  .tabHeader:active {
    background-color: #007fff;
    color: white;
    width: 100%;
  }

  .tabHeader {
    background-color: white;
    color: #007fff;
    width: 100%;
  }

  .drawerContainer {
    padding: 0 1rem;
    margin: 0 auto;

    // background-color: aliceblue !important;
    .selectProduct {
      margin-top: 1rem;
      width: 100% !important;
    }

    .createBtn {
      padding: 0 1rem;

      .crBtn:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
  }
  .compareProductBtn {
    .moduleBtn:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
      color: #007fff;
    }
  }
  .panelData {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: aliceblue;
    padding: 1rem 2rem;
    column-gap: 1rem;
  }

  .textParameter {
    padding: 1rem 1rem;
    background-color: aliceblue;
  }

  .headerRowD {
    background-color: #007fff !important;
    color: white !important;
    padding: 0.3rem 0.5rem;
    text-align: center;
    &:first-child {
      width: 10rem;
      text-align: left;
    }
  }

  .dataCell {
    padding: 0.3rem 0.5rem;
    text-align: center;
    &:first-child {
      width: 10rem;
      text-align: left;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1536px) {
  .projectSelectSection {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    column-gap: 1rem;

    .iconSec {
      margin-top: 0.5rem;
      .drawerIcon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: aliceblue;
      }
      .drawerIcon:hover {
        background-color: none;
      }
      .MuiButtonBase-root.MuiButton-root:hover {
        background-color: none;
      }
    }

    .SelectedProject {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      .copareNameSec {
        // display: flex;
        // column-gap: 10px;
        width: 83vw !important;
        .chipBox {
          border-radius: 2rem;
          align-items: center;
          column-gap: 5px;
          display: flex;
          padding: 0.2rem 0.5rem;
          white-space: nowrap;
          height: 2.5rem;
        }
      }
    }
  }
  .productImageSec {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    padding: 1rem;
    .compareProductImage {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      .prodImage {
        height: 175px;
      }
      .prodImage:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
  }
  .panelData {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: aliceblue;
    padding: 1rem 2rem;
    column-gap: 1rem;
    overflow-x: scroll;
  }
  .textParameter {
    padding: 1rem 2rem;
    background-color: aliceblue;
  }

  .headerRowD {
    background-color: #007fff !important;
    color: white !important;
    padding: 0.3rem 0.5rem;
  }

  .dataCell {
    padding: 0.3rem 0.5rem;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .projectSelectSection {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    column-gap: 1rem;

    .iconSec {
      margin-top: 0.5rem;
      .drawerIcon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: aliceblue;
      }
      .drawerIcon:hover {
        background-color: none;
      }
      .MuiButtonBase-root.MuiButton-root:hover {
        background-color: none;
      }
    }

    .SelectedProject {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      .copareNameSec {
        width: 80vw !important;
        .chipBox {
          border-radius: 2rem;
          align-items: center;
          column-gap: 5px;
          display: flex;
          padding: 0.2rem 0.5rem;
          white-space: nowrap;
          height: 2.5rem;
        }
      }
    }
  }

  .productImageSec {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    padding: 1rem;
    .compareProductImage {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      max-width: 100%;
      overflow-x: scroll;
      .prodImage {
        height: 175px;
      }
      .prodImage:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
  }
  .panelData {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: aliceblue;
    padding: 1rem 2rem;
    column-gap: 1rem;
    overflow-x: scroll;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .projectSelectSection {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    column-gap: 1rem;

    .iconSec {
      margin-top: 0.5rem;
      .drawerIcon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: aliceblue;
      }
      .drawerIcon:hover {
        background-color: none;
      }
      .MuiButtonBase-root.MuiButton-root:hover {
        background-color: none;
      }
    }

    .SelectedProject {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      .copareNameSec {
        // display: flex;
        // column-gap: 10px;
        width: 72vw !important;
        .chipBox {
          border-radius: 2rem;
          align-items: center;
          column-gap: 5px;
          display: flex;
          padding: 0.2rem 0.5rem;
          white-space: nowrap;
          height: 2.5rem;
        }
      }
    }
  }

  .productImageSec {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    width: 100%;
    background-color: aliceblue;
    padding: 1rem;
    overflow: scroll;
    .compareProductImage {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      // max-width: 60rem;
      overflow-x: scroll;
      .prodImage {
        height: 175px;
      }
      .prodImage:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
  }
  .tabpanelsec {
    .parametSect {
      width: 100%;
      .tabPanel {
        width: 100%;
        overflow: scroll;
      }
    }
  }
  .panelData {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: aliceblue;
    padding: 1rem 2rem;
    column-gap: 1rem;
    overflow-x: scroll;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
}

@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .parametSect {
    width: 100%;
    .tabPanel {
      // width: 64rem;
      overflow: scroll;
    }
  }
}
