
.CostingConfigMailEditor {
    height: 68vh !important;
    /* Adjust to your desired height */
  }
  
  @media all and (min-width: 1200px) and (max-width:1535px) {
    .CostingConfigMailEditor {
      height: 55vh !important;
      /* Adjust to your desired height */
    }
  }