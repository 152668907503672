.container {
    width: 100%;
    padding: 0 1rem;
    .header {
        text-align: center;
        margin-bottom: 0.1em;
        font-size: 1.1rem;
        font-weight: 600;
        p {
            background-color: #e8f0fe;
            color: #0000ff;
            line-height: 3rem;
            span {
                color: green;
            }
        }
    }
    .table_wrapper {
        margin-bottom: 1rem;
    }
}
.table_header{
    border-color: #e8f0fe;
    background: #e8f0fe;
    font-size: 1.1rem;
}
