.columnHeaderValue{    
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #007fff !important;
    line-height: 1.2 !important;
    // border-right: 1px solid #ddefff !important;
    top: 2.26rem !important;
}
.columnHeaderCount{        
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #007fff !important;
    line-height: 1.2 !important;
    // border-right: 1px solid #ddefff !important;
    top: 2.26rem !important;
}
.columnSHeaderValue{    
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    line-height: 1.2 !important;
    border-right: 1px solid #ddefff !important;
    top: 2.23rem !important;
}
.columnSHeaderCount{        
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    line-height: 1.2 !important;
    border-right: 1px solid #ddefff !important;
    top: 2.23rem !important;
}
.columnValue{    
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    line-height: 1.2 !important;
    // border-right: 1px solid #ddefff !important;
}
.columnCount{        
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    line-height: 1.2 !important;
    // border-right: 1px solid #ddefff !important;
}
.columnPart{        
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    border-right: 1px solid #ddefff !important;
}
.columnPart1{        
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    border-right: 1px solid #007fff !important;
}
.columnSubsytem{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.columnDash{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;
    color:white;
    cursor: pointer;
}
.columnSummary{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    // justify-content: center;
    color:white;
    cursor: pointer;
}
.stickyHeader{
    position: sticky;
    top: 0;
    z-index: 10;
    // background-color: 'white',
  }

//   .columnCount .columnValue .columnPart .columnPart1 .columnSubsytem .columnDash .columnSummary

.landTable {
    tr:hover td {
    background-color: rgba(0, 0, 0, 0.04);
    }
}