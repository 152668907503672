.MediatabActive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    background-color: #007fff;
    color: white;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
}

.MediatabInactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.5rem;
    background-color: #ddefff;
    color: #007fff;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
}

.slideactive {
    width: 25%;
    transition: all 2s ease;
    box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);

    .tabBox {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }

    .Mediatreefoot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem 0.5rem;
        background-color: #ddefff;
        border-bottom-left-radius: 10px;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}

.slideinactive {
    width: 0;
    transition: all 2s ease;

    .tabBox {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }

    .Mediatreefoot {
        display: none;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}

@media all and (min-width: 1200px) and (max-width:1535px) {}


@media all and (min-width: 900px) and (max-width:1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {
    .slideactive {
        width: 50%;
        transition: all 2s ease;
        transition-delay: 0.5s;
        box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);

        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }

        .Mediatreefoot {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.2rem 0.5rem;
            background-color: #ddefff;
            border-bottom-left-radius: 10px;

            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }

    .slideinactive {
        width: 0;
        transition: all 2s ease;

        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }

        .Mediatreefoot {
            display: none;

            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }
}


@media all and (max-width: 600px) {
    .slideactive {
        width: 34rem;
        transition: width 0.5s ease-in;
        transition-delay: 0.5s;
        box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);

        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }

        .Mediatreefoot {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.2rem 0.5rem;
            background-color: #ddefff;
            border-bottom-left-radius: 10px;

            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }

    .slideinactive {
        width: 0;
         transition: all 2s ease;

        .tabBox {
            display: flex;
            align-items: center;
            width: 100%;
            column-gap: 2px;
            z-index: 0;
            overflow: hidden;
            border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
        }

        .Mediatreefoot {
            display: none;

            .slideIcon {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }
    }
}