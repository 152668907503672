.main_FeatureLandingPage {
  display: flex;
  width: 100%;
  .goBack {
    width: 3rem;
    padding: 0 0.5rem;
  }
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 100%;
    .sideTitle {
      cursor: pointer;
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 28rem;
      font-size: 1rem;
    }
  }
  .featureContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 97%;
    padding-right: 0.5rem;
    .chipSection {
      // margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      column-gap: 10px;
      .downlBtn {
        text-align: right;
        width: fit-content;
        margin-right: 1rem;
      }
      // .downloadreportFeature {
      //   justify-content: flex-end;
      // }
    }
    .feature_left {
      width: 20%;
      max-width: 30%;
      min-width: 20%;
      border-right: 1px solid #007fff1f;
      height: 90vh;
      overflow-y: scroll;
      .headerSect {
        padding: 0.3rem 1rem;
        font-weight: 700;
        position: sticky;
        top: 0;
        align-items: center;
      }
      .active_feature {
        background: #007fff;
        color: white;        
        margin: 0.1rem 0;
        cursor: pointer;
        padding: 0.1rem 0.5rem;
        font-size: 1rem;        
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 1rem;
      }
      .inactive_Feature {
        margin: 0.1rem 0;
        cursor: pointer;
        padding: 0.1rem 0.5rem;
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 1rem;

      }
    }
    .feature_left::-webkit-scrollbar-track {
      // border: 1px solid black;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    .feature_left::-webkit-scrollbar {
      width: 5px;
      // background-color: #18aedb;
      border-radius: 2px;
    }
    .feature_left::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#09c6f9, #045de9);
      border-radius: 2px;
    }   
    .feature_right {
      width: 80%;
      max-width: 80%;
      min-width: 70%;
      // padding: 0 0.5rem 0 1rem;
    }
  }
  .featureMainDiv{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    border-radius: 0.5rem;
    overflow: hidden;
    column-gap: 0.5rem;
  }
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.featureNoData {
  display: flex;
  justify-content: center;
  margin-top: 16rem;
  font-size: 1rem;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .expandIconBox {
    .sideTitle {
      margin-top: 23rem !important;
      font-size: 1rem;
    }
  }
  .chipSection {
    margin-bottom: 0.3rem;
    display: flex;
    column-gap: 10px;
    .downlBtn {
      text-align: right;
      width: fit-content;
      margin-right: 1rem;
    }
    // .downloadreportFeature {
    //   justify-content: flex-end;
    // }
  }
  .feature_left {
    width: 22%;
    max-width: 30%;
    min-width: 22%;
    border-right: 1px solid #007fff1f;
    height: 87vh !important;
    overflow-y: scroll;
    .headerSect {
      padding: 0.2rem 1rem;
      font-weight: 700;
      position: sticky;
      top: 0;
      align-items: center;
    }
    .active_feature {
      background: #007fff;
      color: white;
      padding: 0.1rem 0.5rem;
      margin: 0.2rem 0;
      cursor: pointer;
    }
    .inactive_Feature {
      cursor: pointer;
      padding: 0.1rem 0.5rem;
    }
  }
  .feature_right {
    width: 78%;
    max-width: 78%;
    min-width: 70%;
    // padding: 0 1rem;
  }
  .feature_left::-webkit-scrollbar-track {
    // border: 1px solid black;
    background-color: #f5f5f5;
    border-radius: 2px;
  }
  .feature_left::-webkit-scrollbar {
    width: 5px;
    // background-color: #18aedb;
    border-radius: 2px;
  }
  .feature_left::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#09c6f9, #045de9);
    border-radius: 2px;
  }
  .feature_left_close {
    width: 22rem !important;
    min-width: 22rem;
    border-right: 1px solid #007fff1f;
    height: 87vh;
    overflow-y: scroll;
    .headerSect {
      padding: 0.2rem 1rem;
      font-weight: 700;
      position: sticky;
      top: 0;
      align-items: center;
    }
    .active_feature {
      background: #007fff;
      color: white;
      padding: 0.1rem 0.5rem;
      margin: 0.2rem 0;
      cursor: pointer;
    }
    .inactive_Feature {
      cursor: pointer;
      padding: 0.1rem 0.5rem;
    }
  }
  .feature_left_close::-webkit-scrollbar-track {
    // border: 1px solid black;
    background-color: #f5f5f5;
    border-radius: 2px;
  }
  .feature_left_close::-webkit-scrollbar {
    width: 5px;
    // background-color: #18aedb;
    border-radius: 2px;
  }
  .feature_left_close::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#09c6f9, #045de9);
    border-radius: 2px;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .expandIconBox {
    .sideTitle {
      margin-top: 26rem !important;
    }
  }
  .featureContainer {
    width: 95% !important;
    .chipSection {
      margin-top: 0.5rem;
      .downlBtn {
        text-align: right;
        width: 14rem;
      }
    }
    .feature_left {
      width: 28rem !important;
      min-width: 28rem !important;
      border-right: 1px solid #007fff1f;
      height: 87vh !important;
      overflow-y: scroll;
      .headerSect {
        padding: 0.2rem 1rem;
        font-weight: 700;
        position: sticky;
        top: 0;
      }
      .active_feature {
        background: #007fff;
        color: white;
        padding: 0.1rem 0.5rem;
        margin: 0.2rem 0;
        cursor: pointer;
      }
      .inactive_Feature {
        cursor: pointer;
        padding: 0.1rem 0.5rem;
      }
    }
    .feature_left::-webkit-scrollbar-track {
      // border: 1px solid black;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    .feature_left::-webkit-scrollbar {
      width: 5px;
      // background-color: #18aedb;
      border-radius: 2px;
    }

    .feature_left::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#09c6f9, #045de9);
      border-radius: 2px;
    }
    .feature_left_close {
      width: 22rem !important;
      min-width: 22rem;
      border-right: 1px solid #007fff1f;
      height: 87vh;
      overflow-y: scroll;
      .headerSect {
        padding: 0rem 1rem;
        font-weight: 700;
        position: sticky;
        top: 0;
        align-items: center;
      }
      .active_feature {
        background: #007fff;
        color: white;
        padding: 0.1rem 0.5rem;
        margin: 0.2rem 0;
        cursor: pointer;
      }
      .inactive_Feature {
        cursor: pointer;
        padding: 0.1rem 0.5rem;
      }
    }
    .feature_left_close::-webkit-scrollbar-track {
      // border: 1px solid black;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    .feature_left_close::-webkit-scrollbar {
      width: 5px;
      // background-color: #18aedb;
      border-radius: 2px;
    }

    .feature_left_close::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#09c6f9, #045de9);
      border-radius: 2px;
    }
    .feature_right {
      overflow-x: scroll;
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .goBack {
    width: 3rem;
    padding: 0 0.5rem;
    margin-top: 0.5rem;
  }
  .featureContainer {
    width: 94% !important;
    .chipSection {
      margin-bottom: 0rem !important;
      margin-top: 0.5rem;
      overflow-y: scroll;
      height: 3.5rem;
      padding-right: 0.5rem;
    }
    .feature_left {
      width: 32rem !important;
      min-width: 32rem !important;
      border-right: 1px solid #007fff1f;
      height: 87vh !important;
      overflow-y: scroll;
      .headerSect {
        padding: 0.5rem 1rem;
        font-weight: 700;
        position: sticky;
        top: 0;
      }
      .active_feature {
        background: #007fff;
        color: white;
        padding: 0.1rem 0.5rem;
        margin: 0.2rem 0;
        cursor: pointer;
      }
      .inactive_Feature {
        cursor: pointer;
        padding: 0.1rem 0.5rem;
      }
    }
    .feature_left::-webkit-scrollbar-track {
      // border: 1px solid black;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    .feature_left::-webkit-scrollbar {
      width: 5px;
      // background-color: #18aedb;
      border-radius: 2px;
    }

    .feature_left::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#09c6f9, #045de9);
      border-radius: 2px;
    }
    .feature_left_close {
      width: 22rem !important;
      min-width: 22rem;
      border-right: 1px solid #007fff1f;
      height: 87vh;
      overflow-y: scroll;
      .headerSect {
        padding: 0rem 1rem;
        font-weight: 700;
        position: sticky;
        top: 0;
        align-items: center;
      }
      .active_feature {
        background: #007fff;
        color: white;
        padding: 0.1rem 0.5rem;
        margin: 0.2rem 0;
        cursor: pointer;
      }
      .inactive_Feature {
        cursor: pointer;
        padding: 0.1rem 0.5rem;
      }
    }
    .feature_left_close::-webkit-scrollbar-track {
      // border: 1px solid black;
      background-color: #f5f5f5;
      border-radius: 2px;
    }
    .feature_left_close::-webkit-scrollbar {
      width: 5px;
      // background-color: #18aedb;
      border-radius: 2px;
    }

    .feature_left_close::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#09c6f9, #045de9);
      border-radius: 2px;
    }
    .feature_right {
      overflow-x: scroll;
    }
  }
}
