.expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;
    .sideTitle {
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 40vh;
    }
  }
  .drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }