.container {
    width: 100%;
    height: 81vh;
    font-size: 1.1rem;
    padding: 0 !important;
    display: flex;
    margin-top: 2rem !important;

}

// .menuWrapper {
//     background-color: #fff;
//     border-bottom: var(--border-style);

//      position: sticky;
//     z-index: 10;
//     top: 0;
// }

.main {
    // min-height: calc(100vh - 71vh);
    position: relative;
    margin-top: -3rem;
}

.menuWrapper,
.main,
.footer {
    padding: 0.1rem;
}

.footer {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // background-color: #fff;
    // border-top: 1px solid #ddefff;
    width: 100%;
    // position: sticky;
    height: 2.5rem;
    // z-index: 1;
    // bottom: 0;
    margin-top: -3.5rem;
}

.input {
    width: 20rem;
}

.categories {
    margin: 1rem 1rem 0 0;

    .categoriesMenu {
        width: max-content !important;
    }
}

.other {
    position: relative;
    width: 100%;
}

.hide {
    display: none;
}

.chipSection {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 0.5rem;
    padding-bottom: 0.1rem;
    width: 47vw;

    .calulatorChip_active {
        background-color: #007fff !important;
        color: white !important;
        height: 27px;

        .chipDeleteIcon {
            color: white !important;
            opacity: 0.7;

        }

        .chipDeleteIcon:hover {
            opacity: 1;
        }
    }

    .calulatorChip {
        background-color: #ddefff !important;
        color: #007fff !important;
        height: 27px;
    }

}


@media all and (min-width: 1200px) and (max-width:1535px) {
    .container {
        // width: 100%;
        height: 73vh;
        font-size: 1.1rem;
        padding: 0 !important;
        display: flex;
        margin-top: 2rem !important;
    
    }
    .chipSection {
        display: flex;
        align-items: start;
        column-gap: 0.5rem;
        padding: 0 0.5rem;
        padding-bottom: 0.1rem;
        
        // width: 37vw;

        .calulatorChip_active {
            background-color: #007fff !important;
            color: white !important;
            height: 27px;
            width: 90%;

            .chipDeleteIcon {
                color: white !important;
                opacity: 0.7;

            }

            .chipDeleteIcon:hover {
                opacity: 1;
            }
        }

        .calulatorChip {
            background-color: #ddefff !important;
            color: #007fff !important;
            height: 27px;
            width: 90%;
        }

    }
    .footer {
        display: flex;
        align-items: center;       
        border-top: 1px solid #ddefff;
        width: 100%;       
        height: 2.5rem;        
        margin-top: -3rem;
    }
}