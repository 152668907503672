.container {
    font-size: 1.1rem;
    width: 100%;
    height: 100%;
}

.options {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.empty {
    height: calc(100vh - 5.5rem - 1.5rem - 4rem - 8.8rem);
}

.tableWrapper {
    padding: 0 0.5rem;
    // height: 83.5vh;
    border-radius: 10px;

    td div,
    td input,
    th div,
    th input {
        width: 100% !important;
    }
}


@media all and (min-width: 1200px) and (max-width:1535px){
    .tableWrapper {
        padding: 0 0.5rem;        
        border-radius: 10px;
        height: 80%;
        td div,
        td input,
        th div,
        th input {
            width: 100% !important;
        }
    }
}

