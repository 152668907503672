.imageContainer {
  overflow: hidden;
  width: 100%;
  padding: 1rem;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: var(--border-style);

  &[data-columns="1"] {
    height: 32.8rem;

    .imageTitle {
      // right: 18%;
      display: none;
      margin-top: -1rem;
      // top: -5%;
      position: absolute;
      background-color: #ddefffbd;
      width: fit-content;
    }

    &:hover {
      .imageTitle {
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
    }

    .image {
      height: 30rem;
      width: fit-content;
      margin: 0 auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
    }

    .image:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &[data-columns="2"] {
    height: 24.96rem;

    .imageTitle {
      // right: 18%;
      display: none;
      margin-top: -1rem;
      // top: -5%;
      position: absolute;
      background-color: #ddefffbd;
      width: fit-content;
    }

    &:hover {
      .imageTitle {
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
    }

    .image {
      max-height: 23.8rem;
      width: fit-content;
      margin: 0 auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
    }

    .image:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &[data-columns="3"] {
    height: 18.4rem;

    .imageTitle {
      // right: 18%;
      display: none;
      margin-top: -1rem;
      // top: -5%;
      position: absolute;
      background-color: #ddefffbd;
      width: fit-content;
    }

    &:hover {
      .imageTitle {
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
    }

    .image {
      max-height: 17rem;
      width: fit-content;
      margin: 0 auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
    }

    .image:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &[data-columns="4"] {
    height: 17.26rem;

    .imageTitle {
      // right: 18%;
      display: none;
      margin-top: -1rem;
      // top: -5%;
      position: absolute;
      background-color: #ddefffbd;
      width: fit-content;
    }

    &:hover {
      .imageTitle {
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
    }

    .image {
      max-height: 16rem;
      width: fit-content;
      margin: 0 auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
    }

    .image:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &[data-columns="5"] {
    height: 15.7rem;

    .imageTitle {
      // right: 18%;
      display: none;
      margin-top: -1rem;
      // top: -5%;
      position: absolute;
      background-color: #ddefffbd;
      width: fit-content;
    }

    &:hover {
      .imageTitle {
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: 0;
      }
    }

    .image {
      max-height: 14.2rem;
      width: fit-content;
      margin: 0 auto;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
    }

    .image:hover {
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .dimmer {
    z-index: 2 !important;
  }
}

.sequence_name {
  color: #007fff;
}

.sequence_no {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  min-width: 3rem;
  color: #009100;
  font-size: 1.1rem;
}

.mimage_name {
  color: rgb(0, 0, 255);
  font-size: 1.1rem;
  margin: 0.5rem;
}

.saveicon {
  color: #ff0000 !important;
}

.image_name_edit {
  // width: 40%;
  color: #007fff;
  border-bottom: 1px solid #007fff !important;
}

.seq_num {
  width: 30%;
  color: #007fff;
  border-bottom: 1px solid #007fff !important;
}

.AnnotationContainer {
  display: flex;
  flex-direction: row;
  min-height: 60rem;
  object-fit: contain;
}

.partNames {
  height: 248px !important;
  overflow-y: scroll !important;
}

.sub_system {
  margin-left: 1rem;
  margin-top: 0px;
  margin-bottom: 0px;
}

.tagDetails {
  border-left: 1px solid blue;
  padding-left: 1rem;
}

.partsPath {
  height: 22rem !important;
}

.partNames {
  height: 16.5rem !important;
}

.image {
  max-width: 100%;
  cursor: pointer;
  animation: fadein ease 1s;
}

@media screen and (min-width: 1400px) {
  .partsPath {
    height: 30rem !important;
  }

  .partNames {
    height: 19rem !important;
  }

  .partsPath {
    height: 21rem !important;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    filter: blur(10px);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.noImage {
  width: 100%;
  color: #007fff !important;
}

.image,
.noImage {
  max-width: 100%;
  // max-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  position: absolute;
  bottom: 0;
  // background-color: rgba(255, 255, 255, 0.356);
  width: 100%;
  color: #007fff !important;
  // background-color:  rgba(255, 255, 255, 0.6);
  background-color: #ddefffbd;

  .icon {
    display: none;
    cursor: pointer;
  }

  .disabled {
    cursor: not-allowed;
  }
}

.hide {
  display: none;
}

.imageContainer:hover {
  .buttonContainer {
    .icon {
      display: block;
      // font-size: 1.5rem;
    }
  }
}

@media only screen and (max-height: 768px) and (max-width: 1390px) {
  .partNames {
    height: 215px !important;
    overflow-y: scroll !important;
  }

  .partsPath {
    height: 270px !important;
    overflow-y: scroll !important;
  }

  .buttonContainer {
    column-gap: 0.2rem !important;
  }
}