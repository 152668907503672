.customacoordain_container{
    display: flex;
    }
    
    .customacoordain_child_show{
        display: flex;
        height: 56rem;
        overflow:auto;
        transition: 1s ease height;
       
        }
        .customacoordain_child_hide{
            display: none;
            height: 0rem;
        
            }