.portalContainer{
    background-color: white;
    width: fit-content;
    height: fit-content;
    max-width: 30rem;
    position: absolute;
    border-radius: 0.3rem;
    top: 3rem;
    padding: 0.5rem 1rem;
    word-wrap: break-word;

}