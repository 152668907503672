.dataView{
    z-index: 1001;
    /* height: 100vh; */
    width: 101%;
    height: 100%;
    top: -2.5rem;
    left: -2.5rem;
    /* width: 100vh; */
    position: absolute;
    background-color: #00000080;
    /* background-color: rgba(255, 255, 255, 0.4); */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 20px;
    margin: 30px;
    font-weight: bold;
}

.threesixstyInnerContainer{
    background-color: white;
    height: 36rem;
    width: 54rem;
    margin:auto;
    margin-top: 9rem !important;
}

.closedImgs{
    position: absolute;
    right: 1rem;
    top: 0rem;
    z-index: 2222222;
    font-size: 2rem;
    background-color: #ffffff85;
    cursor: pointer;
    border-bottom-left-radius: 90%;
    width: 3rem;
}


