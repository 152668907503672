.container {
    height: 100%;

    .close {
        color: black;

        i {
            margin: 0 !important;
        }
    }

    .main {
        width: 100%;

        .tableContainer {
            grid-area: table;
            // height: calc(100vh - 36.7rem);
            position: relative;
        }

        .calculator {
            grid-area: calculator;
            margin-left: 1rem;
            width: 100%;
            height: calc(100vh - 36.7rem);
            font-size: 1.1rem;
            position: relative;
            padding: 0 !important;
        }
    }

    .options {
        display: flex;
        align-items: center;
        min-height: 2.5rem;

        button {
            margin-right: 1rem;
        }
    }
}

.hide {
    display: none !important;
}

// .costTable {
//     td {
//         padding: 0.2em 0.7em !important;
//     }
// }