.expandIconBox {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid #007fff14;
  height: 89vh;

  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 40vh;
  }
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.titleSection {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;

  .titleSectionLeft {
    display: flex;
    column-gap: 1rem;
    align-items: center;
  }

  .titleSectionRight {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    justify-content: space-between;
    padding-right: 0.5rem;
    column-gap: 0.5rem;
  }
}
.qrScannerdiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 84vh;
  flex-direction: row;
  .qrScannerleft {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrScannerimg {
      width: 20rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
    .titletext {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }
  .qrScannerright {
    text-align: center;
    .titletext {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }
}
.videoStyle {
  margin-top: -6rem;
  margin-bottom: -5rem;
}

.bomstatustable{
  tr:hover td {
  background-color: rgba(0, 0, 0, 0.04);
  }
}
@media all and (min-width: 1200px) and (max-width: 1535px) {
  .titleSection {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;

    .titleSectionLeft {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }

    .titleSectionRight {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
    }
  }
  .qrScannerdiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 84vh;
    flex-direction: row;
    .qrScannerleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrScannerimg {
        width: 20rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .qrScannerright {
      text-align: center;
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .videoStyle {
    margin-top: -5rem;
    margin-bottom: -4rem;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 85vh;
  
    .sideTitle {
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 35vh;
    }
  }

  .titleSection {
    width: 90rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    overflow: scroll;

    .titleSectionLeft {
      display: flex;
      column-gap: 1rem;
      align-items: center;
    }

    .titleSectionRight {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: space-between;
      padding-right: 0.5rem;
      .bomStatus {
        display: none;
      }
      .expandColapIcon {
        display: none;
      }
      .chartIcon {
        display: none;
      }
    }
  }
  .qrScannerdiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75vh;
    flex-direction: column;
    .qrScannerleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrScannerimg {
        width: 15rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      .titletext {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .qrScannerright {
      text-align: center;
      .titletext {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .videoStyle {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
