.child {
    margin: 0.5rem auto;
    width: 50%;
    position: absolute;
    height: 75%;
    top: 10%;
    left: 25%;
    background-color: #fff;
    border-radius: 5px;

    .scannerClose {
        position: relative;
        float: right;
        cursor: pointer;
        margin: 0.5rem;
    }

    .scannerSection {
        width: 70%;
        margin: 0 auto;
    }

    &:focus-visible {
        outline: none;
    }
}

@media all and (max-width: 600px) {
    .child {
        margin: 0.5rem auto;
        width: 80%;
        position: absolute;
        height: 35%;
        top: 20%;
        left: 10%;
        background-color: #fff;
        border-radius: 5px;

        .scannerClose {
            position: relative;
            float: right;
            cursor: pointer;
            margin: 0.5rem;
        }

        .scannerSection {
            width: 100%;
            margin: -2rem auto;
            padding: 0.5rem;
        }

        &:focus-visible {
            outline: none;
        }
    }
}