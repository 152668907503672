.searchInput {
    color: #007fff;
    background-color: transparent;
    width: 14vw;
    height: 2.5rem;
    // padding-left: 1rem;
    // padding-bottom: 0.5rem;
    border-bottom: none;
    border-top: none;
    border-right: none;
    border-left: none;
}

.search {
    text-align: center;
    // width: 55%;
    margin: 0 auto;
}

.searchForm {
    display: inline-block;
    margin-top: 0.5rem;
    border-bottom: 2px solid;
}

.searchIcon {
    color: #007fff;
    width: 3rem;
    height: 1.5rem;
    margin-right: -2.5rem;
    cursor: pointer;
    z-index: 1;
    position: relative;
    margin-bottom: -1.5rem;
}

.AutoSearchBox {
    height: auto;
    max-height: 24rem;
    overflow: auto;
    width: 24rem;
    margin-top: 0.1rem;
    background-color: aliceblue;
    position: absolute;
    z-index: 11;
    border-radius: 2px;
    border-bottom: 2px solid #007fff14;
    border-right: 2px solid #007fff14;
    border-left: 2px solid #007fff14;

    .searchSubText {
        color: #007fff;
        cursor: pointer;
        text-align: left;
    }
}

.textarea:focus,
input:focus {
    outline: none;
}
.innserSearchSubText {
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #007fff14;
    padding: 0 0.6rem;
    background-color: white;
    transition: transform 0.5s ease;
    align-items: center;
    font-size: 0.9rem;
    justify-content: space-between;
}


  @media all and (min-width: 1200px) and (max-width:1535px){
    .searchInput {
        color: #007fff;
        background-color: transparent;
        width: 15vw;
        height: 2.5rem;
        // padding-left: 1rem;
        // padding-bottom: 0.5rem;
        border-bottom: none;
        border-top: none;
        border-right: none;
        border-left: none;
    }
    .AutoSearchBox {
        height: auto;
        max-height: 21rem;
        overflow: auto;
        width: 21rem;
        margin-top: 0.1rem;
        background-color: aliceblue;
        position: absolute;
        z-index: 11;
        border-radius: 2px;
        border-bottom: 2px solid #007fff14;
        border-right: 2px solid #007fff14;
        border-left: 2px solid #007fff14;
    
        .searchSubText {
            color: #007fff;
            cursor: pointer;
            text-align: left;
        }
    }
  }
  