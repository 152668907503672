.slideactive {
    width: 25%;
    transition: all 2s ease;
    
    transition-delay: 0.5s;
    box-shadow: 5px 0 5px -5px rgba(128, 128, 128, 0.7), 0px 0 5px -5px rgba(128, 128, 128, 0.7);

    .tabBox {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }

    .featuretreefoot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem 0.5rem;
        background-color: #ddefff;
        border-bottom-left-radius: 10px;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}

.slideinactive {
    width: 0;
    transition: all 2s ease;
    // transition-delay: 0.5s;

    .tabBox {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 2px;
        z-index: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
    }

    .featuretreefoot {
        display: none;

        .slideIcon {
            display: flex;
            align-items: center;
            column-gap: 1rem;
        }
    }
}