.archModalCont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #fff;
    height: 96%;
    padding: 20px;
    box-shadow: 0px 11px 15px - 7px rgba(0, 0, 0, 20%),
      0px 24px 38px 3px rgba(0, 0, 0, 14%), 0px 9px 46px 8px rgba(0, 0, 0, 12%);
  }
  