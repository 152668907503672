.headerColum {
  padding: 0.2rem 0.5rem !important;
  white-space: nowrap;
  width: 13.7vw;
  // text-align: center !important;
  border-bottom: none !important;
  &:first-child{
    text-align: left !important;
  }
}

.headerColumempty {
  padding: 0.2rem 0.5rem !important;
  white-space: nowrap;
  width: 13.7vw;
  text-align: center !important;
  border-bottom: none !important;
}

.dataColumn {
  padding: 0.5rem !important;
  border-bottom-color: #ddefff !important;
}
.dataColumnIn{
  padding: 0.2rem 0.5rem !important;
  border-bottom-color: #ddefff !important;
}
.dataNoborder {
  padding: 0.5rem !important;
  border-bottom: none !important;
}
.tablesec {
  width: 100%;
 height: 90vh;
 display: flex;
 align-items: flex-start;
}
.Inferensec {
  width: 100%;
  // margin: 1rem 0;
  border: none !important;
 
}
.hideBorder.jodit-container:not(.jodit_inline)  {
  border: 0px solid #dadada !important;
  
}
.jodit-container:not(.jodit_inline){
  border:none !important;
}
.rviewjscontainer{
  display: contents !important;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .tablesec {
    width: 100%;
    height: 87vh !important;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .tablesec {
    width: 100%;
    height: 82vh !important;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .tablesec {
    width: 100%;
    height: 81vh;
  }
}

@media all and (max-width: 600px) {
}
