// .tableContainer {
//     display: grid;
//     grid-template-columns: 1fr 0.8fr;
//     .bomViewTableSec {
//       height: 78vh;
//       .tableHCell {
//         padding: 0.3rem 0.5rem;
//         // color: white;
//         text-align: center;
//       }
//       .tableSNCell {
//         width: 5rem;
//         padding: 0.3rem 0.5rem;
//       }
//       .tableCell {
//         padding: 0.3rem 0.5rem;
//       }
//     }
//     .bomViewImageSec {
//       height: 78vh;
//       .BomImage {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         height: 20rem;
//         padding: 1rem;
//         row-gap: 1rem;
//         column-gap: 1rem;
//         .bomImageCard:hover {
//           transform: scale(1.05);
//           transition: transform 0.5s ease;
//         }
//       }
//     }
//   }

.Dataheader {
  width: 200px;
  height: 300px;
}
.boxCont {
  width: 95%;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  min-height: 50vh;
  transform: translate(-50%, -50%);
  position: absolute;
  .popHeader {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    width: 100%;
    position: sticky;
    top: 0;
    // background-color: aliceblue;
  }
  .tableContainerS {
    // overflow-y: scroll;
    padding: 1rem;
    // margin: 1rem;
    max-height: 65vh;
    .tableSec {
      max-height: 70vh;
      .tablemultiCell {
        white-space: nowrap;
        padding: 0.3rem 0.5rem;
        border-left: 1px solid rgba(224, 224, 224, 1);
      }
      .tablemultiCellData {
        padding: 0.3rem 0.5rem;
        border-left: 1px solid rgba(224, 224, 224, 1);
      }
      .tablemultiCell:first-child,
      .tablemultiCellData:first-child {
        border-left: none;
      }
      .tableCell {
        padding: 0.3rem 0.5rem;
      }
      .tableDetails {
        overflow-y: scroll;
      }
    }
  }
  tableContainerS::-webkit-scrollbar-track {
    // border: 1px solid black;
    background-color: #f5f5f5;
    border-radius: 2px;
  }
  .tableContainerS::-webkit-scrollbar {
    width: 5px;
    // height: 5px;
    // background-color: #18aedb;
    border-radius: 2px;
  }
  .tableContainerS::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#09c6f9, #045de9);
    border-radius: 2px;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  // .tableContainer {
  //   display: grid;
  //   grid-template-columns: 1fr 0.8fr;
  //   .bomViewTableSec {
  //     height: 71vh;
  //     .tableHCell {
  //       padding: 0.3rem 0.5rem;
  //       // color: white;
  //       text-align: center;
  //     }
  //     .tableCell {
  //       padding: 0.3rem 0.5rem;
  //     }
  //   }
  //   .bomViewImageSec {
  //     height: 71vh;
  //     .BomImage {
  //       display: grid;
  //       grid-template-columns: 1fr 1fr;
  //       height: 20rem;
  //       padding: 1rem;
  //       row-gap: 1rem;
  //       column-gap: 1rem;
  //     }
  //   }
  // }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
  // .tableContainer {
  //   display: grid;
  //   grid-template-columns: 1fr 0.8fr;
  //   .bomViewTableSec {
  //     height: 71vh;
  //     .tableHCell {
  //       padding: 0.3rem 0.5rem;
  //       // color: white;
  //       text-align: center;
  //     }
  //     .tableCell {
  //       padding: 0.3rem 0.5rem;
  //     }
  //   }
  //   .bomViewImageSec {
  //     height: 71vh;
  //     .BomImage {
  //       display: grid;
  //       grid-template-columns: 1fr 1fr;
  //       height: 20rem;
  //       padding: 1rem;
  //       row-gap: 1rem;
  //       column-gap: 1rem;
  //     }
  //   }
  // }
}

@media all and (max-width: 600px) {
}
