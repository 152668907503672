.containerWrapper {
    display: flex;
    align-items: flex-start;
    width:35vw;
    column-gap: 0.5rem;

    .collapser {
        width:35vw;
        background-color: #007fff;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 500; 
        line-height: 1; 
        height: 1.2rem;               
        border-radius: 4px;
        justify-content: center;
        flex-direction: column;
        border: 1px solid;  
        cursor: pointer;    

        .arrowup {
            margin-top: -17px;
            width: 8px;
            height: 8px;
            border-top: 1px solid;
            border-left: 1px solid;
            transform: rotate(45deg);
            background-color: #007fff;
            z-index: 1;
            position: absolute;
        }

        .arrowdown {
            margin-bottom: -16px;
            width: 8px;
            height: 8px;
            border-bottom: 1px solid;
            border-right: 1px solid;
            transform: rotate(45deg);
            background-color: #007fff;
            z-index: 1;
            position: absolute;
        }
    }

    .CostPartDetails {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 35vw;
        height: 22.18vh;
        overflow: hidden;
        transition: all 1s ease-in-out;

        .prodImage {
            width: 100%;
            height: 100%;
            display: flex;
            // align-items: center;
            justify-content: center;
        }
    }

    .CostPartDetailsCollapse {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 35vw;
        height: 0vh;
        overflow: hidden;
        transition: all 1s ease-in-out;

        .prodImage {
            width: 100%;
            height: 100%;
            display: flex;
            // align-items: center;
            justify-content: center;
        }
    }

    .partInfoTitle {
        text-align: right !important;
        border-bottom: none !important;
        padding: 0.2rem 0.5rem !important;
        background-color: #007fff;
        color: white !important;
        width: 3rem;
    }

    .partInfo {
        border-bottom: none !important;
        padding: 0.2rem 0.5rem !important;
        min-width: 2rem;
    }

    .partlabel {
        // text-align: right !important;
        border-bottom: 1px solid #ddefff !important;
        padding: 0.2rem 0.5rem !important;
        // width: 10rem;
    }

    .partdetails {
        text-align: right !important;
        border-bottom: 1px solid #ddefff !important;
        padding: 0.2rem 0.5rem !important;
    }

    .BomDrawer {
        // display: flex;
        // align-items: flex-start;
        height: 89vh;
        margin-left: -1.5rem;
        z-index: 3;
        // position: absolute;        
        right: 0.6rem;

        .drabtnSec {
            width: fit-content;
            height: 100%;

            .bombutton {
                background-color: #007fff;
                white-space: nowrap;
                position: relative;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                top: 75%;
                cursor: pointer;
                width: 1.52rem;
                height: 6rem;
                text-align: center;
                display: flex;

                .text {
                    writing-mode: vertical-rl;
                    transform: rotate(180deg);
                    white-space: nowrap;
                }
            }
        }

        .bomTableActive {
            width: 45rem;
            transition: all 1.5s ease-in-out;
            position: absolute;
            left: 4px;
            top: 10vh;

            .bomBodySection {
                width: 100%;
                height: 100%;
                background-color: white;

                .bomactiveTab {
                    background-color: #007fff;
                    padding: 0.3rem 1rem;
                    color: white;
                    cursor: pointer;
                    width: 8rem;
                    text-align: center;
                }

                .bomTab {
                    background-color: white;
                    padding: 0.3rem 1rem;
                    color: #007fff;
                    border: 1px solid #ddefff;
                    width: 8rem;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }

        .bomTableInactive {
            width: 0rem;
            overflow: hidden;
            transition: all 1.5s ease-in-out;
            position: absolute;
            left: 4px;
            top: 10vh;

            .bomBodySection {
                width: 100%;
                height: 100%;
                background-color: white;

                .bomactiveTab {
                    background-color: #007fff;
                    padding: 0.3rem 1rem;
                    color: white;
                    cursor: pointer;
                    width: 8rem;
                    text-align: center;
                }

                .bomTab {
                    background-color: white;
                    padding: 0.3rem 1rem;
                    color: #007fff;
                    cursor: pointer;
                    border: 1px solid #ddefff;
                    width: 8rem;
                    text-align: center;
                }
            }
        }
    }
}

@media all and (min-width: 1200px) and (max-width:1535px) {
    .containerWrapper {
        display: flex;
        align-items: flex-start;
        width: 100%;
        column-gap: 0.5rem;
        // justify-content: space-between;
        .collapser {
            width:40vw;
            background-color: #007fff;
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            font-weight: 500; 
            line-height: 1; 
            height: 1.2rem;               
            border-radius: 4px;
            justify-content: center;
            flex-direction: column;
            border: 1px solid;  
            cursor: pointer;    
    
            .arrowup {
                margin-top: -17px;
                width: 8px;
                height: 8px;
                border-top: 1px solid;
                border-left: 1px solid;
                transform: rotate(45deg);
                background-color: #007fff;
                z-index: 1;
                position: absolute;
            }
    
            .arrowdown {
                margin-bottom: -16px;
                width: 8px;
                height: 8px;
                border-bottom: 1px solid;
                border-right: 1px solid;
                transform: rotate(45deg);
                background-color: #007fff;
                z-index: 1;
                position: absolute;
            }
        }
        .CostPartDetails {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 40vw;
            height: 30vh;
            overflow: hidden;
            transition: all 1s ease-in-out;

            .prodImage {
                width: 100%;
                height: 100%;
                display: flex;
                // align-items: center;
                justify-content: center;
            }
        }

        .CostPartDetailsCollapse {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 40vw;
            height: 0vh;
            overflow: hidden;
            transition: all 1s ease-in-out;

            .prodImage {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .BomDrawer {
            // display: flex;
            // align-items: flex-start;
            height: 89vh;
            margin-left: -1.5rem;
            z-index: 3;
            // position: absolute;        
            right: 0.6rem;
    
            .drabtnSec {
                width: fit-content;
                height: 100%;
    
                .bombutton {
                    background-color: #007fff;
                    white-space: nowrap;
                    position: relative;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    top: 75%;
                    cursor: pointer;
                    width: 1.52rem;
                    height: 6rem;
                    text-align: center;
                    display: flex;
    
                    .text {
                        writing-mode: vertical-rl;
                        transform: rotate(180deg);
                        white-space: nowrap;
                    }
                }
            }
    
            .bomTableActive {
                width: 45rem;
                transition: all 1.5s ease-in-out;
                position: absolute;
                left: 4px;
                top: 13vh;
    
                .bomBodySection {
                    width: 100%;
                    height: 100%;
                    background-color: white;
    
                    .bomactiveTab {
                        background-color: #007fff;
                        padding: 0.3rem 1rem;
                        color: white;
                        cursor: pointer;
                        width: 8rem;
                        text-align: center;
                    }
    
                    .bomTab {
                        background-color: white;
                        padding: 0.3rem 1rem;
                        color: #007fff;
                        border: 1px solid #ddefff;
                        width: 8rem;
                        cursor: pointer;
                        text-align: center;
                    }
                }
            }
    
            .bomTableInactive {
                width: 0rem;
                overflow: hidden;
                transition: all 1.5s ease-in-out;
                position: absolute;
                left: 4px;
                top: 13vh;
    
                .bomBodySection {
                    width: 100%;
                    height: 100%;
                    background-color: white;
    
                    .bomactiveTab {
                        background-color: #007fff;
                        padding: 0.3rem 1rem;
                        color: white;
                        cursor: pointer;
                        width: 8rem;
                        text-align: center;
                    }
    
                    .bomTab {
                        background-color: white;
                        padding: 0.3rem 1rem;
                        color: #007fff;
                        cursor: pointer;
                        border: 1px solid #ddefff;
                        width: 8rem;
                        text-align: center;
                    }
                }
            }
        }
    }
    
}