.tableHcell {
  border-bottom: none;
  padding: 0 0.5rem;
}
.tableBcell {
  // border-bottom: 1px solid;
  padding: 0 0.5rem;
}

.view_container {
  width: 100%;
  // background-color: aliceblue;
  height: auto;
  .tab_mainContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    .icon_project {
      display: flex;
      width: 80%;
    }
    .tabBox_container {
      display: flex;align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0.5rem; // remove on enable back button
      align-items: center;
      height: 3rem;
    }
  }
  .viewbtnSection {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .downloadBtn:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  }
  .recursive_container {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    column-gap: 0.5rem;
    margin: 0 1rem;
    height: 86vh;
    border: 1px solid #ddefff;
    border-radius: 0.5rem;
    overflow: hidden;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

    .left_container {
      width: fit-content;
      min-width: 16%;
      max-width: 30%;
      .drawerTreeIcon {
        text-align: right;
        padding: 0.5rem;
        position: relative;
        margin-bottom: -3.5rem;
      }
      .treeViewData {
        padding: 0.2rem;
        .treeViewText {
          line-height: 1.2 !important;
          font-size: 12px;
        }
      }
    }
    .righ_container {
      width: 88%;
      .submodulesContainer {
        display: inline-flex;
        background-color: white;
        width: 100%;
        .col3Div {
          display: inline-flex;
          width: 100%;
          // flex-wrap: wrap;
          column-gap: 0.5rem;
          .mediaContainer,
          .archContainer,
          .featureContainer,
          .bomContainer,
          .costingContainer,
          .ideasContainer {
            // width: 16.1%;
            min-width: 33%;
            width: 100%;
            height: 84vh;
            // margin-left: 0.5rem;
            border-top-right-radius: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
              rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

            // box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
            // -webkit-box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
            // -moz-box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
            .header {
              background-color: #f7f7f7;
              padding: 0.6rem 1rem;
              justify-content: space-between;
              display: flex;
              align-items: center;
              .pageView {
                cursor: pointer;
              }
            }
          }
        }
        .col6Div {
          display: inline-flex;
          width: 100%;
          flex: wrap;
          column-gap: 0.5rem;
          .mediaContainer,
          .featureContainer,
          .costingContainer,
          .ideasContainer,
          .archContainer,
          .bomContainer {
            min-width: 16.22%;
            width: 100%;
            height: 84vh;
            // margin-left: 0.54rem;
            border-top-right-radius: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
              rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            .header {
              background-color: #f7f7f7;
              padding: 0.6rem 1rem;
              justify-content: space-between;
              display: flex;
              align-items: center;
              .pageView {
                cursor: pointer;
              }
            }
          }
        }
        .col5Div {
          display: inline-flex;
          width: 100%;
          flex: wrap;
          column-gap: 0.5rem;
          .mediaContainer,
          .featureContainer,
          .costingContainer,
          .ideasContainer,
          .archContainer,
          .bomContainer {
            width: 100%;
            min-width: 19%;
            height: 84vh;
            // margin-left: 0.5rem;
            border-top-right-radius: 0.5rem;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
              rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
            .header {
              background-color: #f7f7f7;
              padding: 0.6rem 1rem;
              justify-content: space-between;
              display: flex;
              align-items: center;
              .pageView {
                cursor: pointer;
              }
            }
            .archcolumn {
              display: grid;
              grid-template-columns: 1fr 1fr;
              padding: 0.5rem;
              grid-column-gap: 10px;
              grid-row-gap: 5px;
              height: 81vh;
            }
          }
        }
        .partLevelContainer {
          width: 100%;
          height: 100%;
          // display: grid;
          // grid-template-columns: 1fr 3rem;
          .bompartHeader {
            display: flex;
            margin-left: 1rem;
            // justify-content: space-between;
            padding: 0.5rem;
            background-color: aliceblue;
          }
        }
      }
    }
  }
}
.treetem {
  background-color: "red" !important;
}
.Project_chip {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #007fff;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  width: fit-content;
  // max-width: 14rem;
  height: -moz-fit-content;
  background-color: white;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  transition: all 0.3s ease 0s;
}

.Project_chip:hover {
  background-color: #007fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 255, 0.4);
  color: #fff;
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.productLevel {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #007fff;
  // white-space: nowrap;
  border-radius: 4px;
  // padding: 0.1rem 1rem;
  margin: 0 0.25rem;
  width: fit-content;
  transition: all 0.3s ease 0s;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
  // rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  .prodtitle {
    // font-size: 0.75rem;
  }
  .proddesc {
    width: 15rem;
    line-height: 1;
    // font-size: 0.75rem;
  }
}

.productlist_active {
  padding: 5%;
  color: white;
  font-size: 1.1rem;
  border-radius: 4px;
  border: none;
  margin-bottom: 4%;
  background: blue;
  box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.Project_chip_active {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  width: fit-content;
  // max-width: 14rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background-color: #007fff;
  transition: all 0.3s ease 0s;
}

.footer {
  background-color: #fff;
  color: #007fff;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  .fooCont {
    cursor: pointer;
    text-decoration: underline;
  }
}
.left_container::-webkit-scrollbar-track {
  // border: 1px solid black;
  background-color: #f5f5f5;
  border-radius: 30%;
}
.left_container::-webkit-scrollbar {
  width: 5px;
  // background-color: #18aedb;
  border-radius: 5%;
}

.left_container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.imagePostTD {
  height: 6rem;
  width: auto;
  object-fit: contain;
}
.archIamges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 17rem 0rem 0rem 0rem;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .view_container {
    width: 100%;
    height: auto;
    .tab_mainContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 1rem;
      .icon_project {
        display: flex;
        width: 75%;
        // overflow-x: scroll;
      }
      .tabBox_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 3rem;
        margin-bottom: 0.5rem; // remove on enable back button
        align-items: center;
        // overflow-x: scroll;
        overflow-y: hidden;
      }
      .tabBox_container::-webkit-scrollbar-track {
        // border: 1px solid black;
        background-color: #f5f5f5;
        // border-radius: 30%;
      }
      .tabBox_container::-webkit-scrollbar {
        height: 4px;
        // background-color: #18aedb;
        // border-radius: 5%;
      }
    }
    .viewbtnSection {
      display: flex;
      align-items: center;
      column-gap: 15px;
      .downloadBtn:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
    .recursive_container {
      display: flex;
      flex-direction: row;
      font-size: 0.9rem;
      column-gap: 0.5rem;
      margin: 0 1rem;
      height: 82vh;
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

      .left_container {
        width: fit-content;
        min-width: 16%;
        max-width: 22%;
        // height: 81vh;
      }
      .righ_container {
        width: 85%;
        .submodulesContainer {
          display: inline-flex;
          background-color: white;
          width: 100%;
          .col3Div {
            display: inline-flex;
            width: 100%;
            // flex-wrap: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .archContainer,
            .featureContainer,
            .bomContainer,
            .costingContainer,
            .ideasContainer {
              // width: 16.1%;
              min-width: 33%;
              width: 100%;
              height: 82vh;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col6Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .featureContainer,
            .costingContainer,
            .ideasContainer,
            .archContainer,
            .bomContainer {
              min-width: 16.1%;
              width: 100%;
              height: 82vh;
              // margin-left: 0.54rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col5Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .featureContainer,
            .costingContainer,
            .ideasContainer,
            .bomContainer,
            .archContainer {
              // width: 16.1%;
              min-width: 19%;
              width: 100%;
              height: 82vh;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
              .archcolumn {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 0.5rem;
                grid-column-gap: 10px;
                grid-row-gap: 5px;
                height: 72vh !important;
              }
            }
          }
          .partLevelContainer {
            width: 100%;
            height: 100%;
            // display: grid;
            // grid-template-columns: 1fr 3rem;
            .bompartHeader {
              display: flex;
              // justify-content: space-between;
              padding: 0.5rem;
              background-color: aliceblue;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .view_container {
    width: 100%;
    height: auto;
    padding: 0 0.5rem;
    .tab_mainContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 1rem;
      .icon_project {
        display: flex;
        width: 80%;
        overflow-x: scroll;
      }
      .tabBox_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.5rem; // remove on enable back button
        align-items: center;
        height: 3rem;
      }
    }
    .viewbtnSection {
      display: flex;
      align-items: center;
      column-gap: 15px;
      width: 17rem;
      .downloadBtn:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
    .recursive_container {
      // display: grid;
      // grid-template-columns: 1fr 5fr;
      display: flex;
      font-size: 0.9rem;
      column-gap: 0.5rem;
      margin: 0 1rem;
      height: 84vh;
      overflow-x: scroll;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
        rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
      .left_container {
        width: fit-content;
        min-width: 22rem;
        max-width: 30%;
        height: 84vh;
        padding-bottom: 1rem;
      }
      .righ_container {
        width: 100rem;
        .submodulesContainer {
          display: inline-flex;
          background-color: white;
          width: 100%;
          .col3Div {
            display: inline-flex;
            width: 100%;
            // flex-wrap: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .archContainer,
            .featureContainer,
            .bomContainer,
            .costingContainer,
            .ideasContainer {
              // width: 16.1%;
              width: 100%;
              min-width: 33%;
              // height: 81vh;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col6Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .archContainer,
            .featureContainer,
            .bomContainer,
            .costingContainer,
            .ideasContainer {
              width: 100%;
              min-width: 25rem;
              height: 84vh;
              padding-bottom: 1rem;
              // margin-left: 0.54rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col5Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .featureContainer,
            .costingContainer,
            .ideasContainer,
            .archContainer,
            .bomContainer {
              width: 100%;
              min-width: 25%;
              height: 84vh;
              padding-bottom: 1rem;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
              .archcolumn {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 0.5rem;
                grid-column-gap: 10px;
                grid-row-gap: 5px;
                height: 76vh;
              }
            }
          }
          .partLevelContainer {
            width: 100%;
            height: 100%;
            .bompartHeader {
              display: flex;
              // justify-content: space-between;
              padding: 0.5rem;
              background-color: aliceblue;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .view_container {
    width: 100%;
    height: auto;
    padding: 0 0.5rem;
    .tab_mainContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.5rem 1rem;
      .icon_project {
        display: flex;
        width: 70%;
        overflow-x: scroll;
      }
      .icon_project::-webkit-scrollbar-track {
        // border: 1px solid black;
        background-color: #f5f5f5;
        border-radius: 30%;
      }
      .icon_project::-webkit-scrollbar {
        height: 5px;
        // background-color: #18aedb;
        border-radius: 5%;
      }
      .tabBox_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0.5rem; // remove on enable back button
        align-items: center;
      }
    }
    .viewbtnSection {
      display: flex;
      align-items: center;
      column-gap: 15px;
      width: 17rem;
      .downloadBtn:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }
    .recursive_container {
      // display: grid;
      // grid-template-columns: 1fr 5fr;
      display: flex;
      font-size: 12px;
      column-gap: 0.5rem;
      margin: 0 1rem;
      overflow-x: scroll;
      height: 87vh;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
        rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
      .left_container {
        // width: 30rem !important;
        width: fit-content;
        min-width: 22rem;
        max-width: 30%;
        overflow: auto;
        height: auto;
        padding-bottom: 1rem;
      }
      .righ_container {
        width: 120rem;
        .submodulesContainer {
          display: inline-flex;
          background-color: white;
          width: 100%;
          .col3Div {
            display: inline-flex;
            width: 100%;
            // flex-wrap: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .archContainer,
            .featureContainer,
            .bomContainer,
            .costingContainer,
            .ideasContainer {
              // width: 16.1%;
              width: 100%;
              min-width: 33%;
              height: 87vh;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col6Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .archContainer,
            .featureContainer,
            .bomContainer,
            .costingContainer,
            .ideasContainer {
              width: 100%;
              min-width: 25rem;
              height: 87vh;
              // margin-left: 0.54rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
            }
          }
          .col5Div {
            display: inline-flex;
            width: 100%;
            flex: wrap;
            column-gap: 0.5rem;
            .mediaContainer,
            .featureContainer,
            .costingContainer,
            .ideasContainer,
            .archContainer,
            .bomContainer {
              width: 100%;
              min-width: 19%;
              height: 87vh;
              // margin-left: 0.5rem;
              border-top-right-radius: 0.5rem;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
              .header {
                background-color: #f7f7f7;
                padding: 0.6rem 1rem;
                justify-content: space-between;
                display: flex;
                align-items: center;
                .pageView {
                  cursor: pointer;
                }
              }
              .archcolumn {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 0.5rem;
                grid-column-gap: 10px;
                grid-row-gap: 5px;
                height: 76vh;
              }
            }
          }
          .partLevelContainer {
            width: 100%;
            height: 100%;
            .bompartHeader {
              display: flex;
              // justify-content: space-between;
              padding: 0.5rem;
              background-color: aliceblue;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
}
