.videoCome {
  width: auto;
  height: 32rem;

  video::-webkit-media-controls-mute-button {
    display: none;
  }
}




@media all and (min-width: 1200px) and (max-width:1535px) {
  .videoCome {
    width: auto;
    height: 24rem;

    video::-webkit-media-controls-mute-button {
      display: none;
    }
  }
}


@media all and (min-width: 900px) and (max-width:1199px) {}

@media all and (min-width: 601px) and (max-width: 899px) {}


@media all and (max-width: 600px) {}