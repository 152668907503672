.Inwardinputcard_container_textfiledata {
  width: 100%;

  // height: 39px !important;
  color: #007fff;
  background-color: aliceblue;
}

.buttonconatiner {
  display: none;
  position: absolute;
  right: 0;
  background-color: aliceblue;
}

.Inwardinputcard_container_textfiledata:hover {
  .buttonconatiner {
    display: flex !important;
    position: relative;
  }
}

.Inwardinputcard_container_textfiledata:focus-visible {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.Inwardinputcard_container_textfiledata::before{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.Inwardinputcard_container_textfiledata::after{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.inputContainer {
  display: flex;
  margin: 1rem 0.5rem;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.inputContainer:hover .buttonconatiner {
  display: flex;
}