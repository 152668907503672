.top_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0rem 1rem 0 0.5rem;
    align-items: center;
}
.arrowbtn{
    display: flex;
    align-items: center;
    column-gap: 1rem;
}