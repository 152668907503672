.container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.3fr;

    // height: calc(100% - 11rem);
    .graph_area {
        border-right: 1px solid #ddefff;
    }
}

.header {
    display: flex;
    padding: 0 10px;
    justify-content: flex-end;

    .legend {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        padding-top: 0.5rem;
        column-gap: 1rem;

        .active_user {
            display: flex;
            align-items: center;
            font-size: 1.1rem;

            &:nth-child(1) {
                color: #007fff;
            }

            &:nth-child(2) {
                color: green;
            }

            &:nth-child(3) {
                color: #d8b02a;
            }

            .dot {
                height: 1rem;
                width: 1rem;
                border-radius: 50%;
                display: inline-block;               
                margin-right: 4px;
            }
        }
    }
}

.top_section {
    display: flex;
    /* grid-template-rows: 1fr 0.3fr; */
    /* grid-gap: 1rem; */
    gap: 1rem;
    // align-items: center;
    justify-content: center;
    height: 91vh;

}