.accContent {
  display: block !important;
}
.ideaAccord {
  height: 84vh;
  width: 100%;
}
.accordianHeader {
  // align-items: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // float: right;
}

@media all and (min-width: 1200px) and (max-width:1535px){
  .ideaAccord {
    height: 81vh;
    width: 100%;
  }
}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}


@media all and (max-width: 600px) {

}
