.ideaModuleSec {
  height: 81.6vh;
}
.archIamges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  margin: 17rem 0rem 0rem 0rem;
}

@media all and (min-width: 1536px) {
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .ideaModuleSec {
    height: 77vh;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .ideaModuleSec {
    height: 77.5vh;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .ideaModuleSec {
    height: 83.6vh;
  }
}

@media all and (max-width: 600px) {
}
