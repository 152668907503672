.InputSearchData {
  appearance: none;
  width: 37rem;
  border-radius: none;
  // height: 2rem;
  height: 2.6rem;
  font-size: 1.1rem;
  border: none;
  border-bottom: 1px solid rgb(224, 227, 231);
  // box-shadow: 0px 2px 0px 0px ;
  outline: 0;
  padding: 0rem 0rem 0rem 2rem;
}

.searchConatiner {
  height: 30.8rem;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.btnsearch {
  text-align: right;
}
.closeIcon {
  position: relative;
  float: right;
  margin-right: -2rem;
  background-color: white;
  width: 2.5rem;
  height: 1.5rem;
}
.nextbtn:hover {
  transition: transform 0.5s ease;
  transform: scale(1.1);
}
.searchbtn {
  margin: 0 0.5rem !important;
}
.searchbtn:hover {
  transition: transform 0.5s ease;
  transform: scale(1.1);
}
.serach_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.serachlogs {
  margin: 0 auto;
}
.chipContainer {
  padding: 1rem 0rem 0rem 1rem;
  display: flex;
}
.selectedData {
  margin-right: 1rem;
}
.searchlogdata {
  margin-left: 2rem;
  margin-top: 1rem;
  width: 35rem;
}
.searchName {
  font-size: 0.8125rem;
  font-weight: 500;
  color: rgb(62, 80, 96);
  padding-left: 1.3rem;
}
.recentSearch {
  /* background-color: #f0f7ff; */
  border-color: #007fff;
  color: rgb(62, 80, 96);
  /* border-radius: 5px; */
  /* border-bottom: 1px solid grey; */
  margin-bottom: 0.3rem;

  //    padding: 1rem;
  line-height: 1.6;
}
.insideData {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom: 1px solid #e0e3e7;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
.insideData:hover {
  background-color: #f0f7ff;
  border-color: #007fff;
  border-radius: 3px;
  border-bottom: none;
  border: 1px solid #007fff;
  color: #007fff;
}
.BtnClearSelect {
  margin-left: 4rem;
  margin-top: -0.6rem;
  /* font-size: 0rem; */
  margin-bottom: -2rem;
}
