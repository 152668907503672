.dataView{
    z-index: 1001;
    /* height: 100vh; */
    width: 101%;
    height: 100%;
    top: -2.5rem;
    left: -2.5rem;
    /* width: 100vh; */
    position: absolute;
    background-color: #00000080;
    /* background-color: rgba(255, 255, 255, 0.4); */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 20px;
    margin: 30px;
    font-weight: bold;
}

.threesixstyInnerContainer{
    // background-color: white;
    // height: 36rem !important;
    // width: 54rem !important;
     margin:auto;
 
    margin-top: 3rem;
}
    // margin-top: 9rem !important;


.closedImgs{
    position: absolute;
    right: 1rem;
    top: 0rem;
    z-index: 2222222;
    font-size: 2rem;
    background-color: #ffffff85;
    cursor: pointer;
    border-bottom-left-radius: 90%;
    width: 3rem;
}
.tabcolor
{
    background-color: #007fff;
    height: 2.9rem;
    padding: 0rem;
    color: white;
    padding: 0.5rem;
    margin: 0.3rem;
    cursor: pointer;
    border-radius: 3px;
}
.tabcolor2{
    background-color: #007fff;
    height: 2.9rem;
    padding: 0rem;
    color: white;
     padding: 0.5rem;
    margin: 0.3rem;
    cursor: pointer;
    border-radius: 3px;
}