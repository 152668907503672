.calculatorTabSection {
    // display: flex;
    // align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    // column-gap: 0.5rem;

    .chipSection {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        padding: 0 0.5rem;
        padding-bottom: 0.1rem;
        width: 41vw;

        .calulatorChip_active {
            background-color: #007fff !important;
            color: white !important;
            height: 27px;

            .chipDeleteIcon {
                color: white !important;
                opacity: 0.7;

            }

            .chipDeleteIcon:hover {
                opacity: 1;
            }
        }

        .calulatorChip {
            background-color: #ddefff !important;
            color: #007fff !important;
            height: 27px;
        }

    }
}

@media all and (min-width: 1200px) and (max-width:1535px) {
    .chipSection {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        padding: 0 0.5rem;
        padding-bottom: 0.1rem;
        width: 28vw;

        .calulatorChip_active {
            background-color: #007fff !important;
            color: white !important;
            height: 27px;

            .chipDeleteIcon {
                color: white !important;
                opacity: 0.7;

            }

            .chipDeleteIcon:hover {
                opacity: 1;
            }
        }

        .calulatorChip {
            background-color: #ddefff !important;
            color: #007fff !important;
            height: 27px;
        }

    }
}