.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.link {
    font-size: 1rem;
}

.hide {
    display: none !important;
}
