.main {
    display: grid;
    position: sticky;
    border-radius: 0.28571429rem;
    width: 100%;
    border-bottom: 0.01rem solid #e8f0fe;
    &[data-columns="1"] {
      grid-template-columns: 3rem repeat(1, 1fr) 3rem;
    }
    &[data-columns="2"] {
      grid-template-columns: 3rem repeat(2, 1fr) 3rem;
    }
    &[data-columns="3"] {
      grid-template-columns: 3rem repeat(3, 1fr) 3rem;
    }
    &[data-columns="4"] {
      grid-template-columns: 3rem repeat(4, 1fr) 3rem;
    }
    &[data-columns="5"] {
      grid-template-columns: 3rem repeat(5, 1fr) 3rem;
    }
  }
  
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    &[data-columns="1"] {
      height: 32.8rem;
    }
    &[data-columns="2"] {
      height: 28.96rem;
    }
    &[data-columns="3"] {
      height: 19.4rem;
    }
    &[data-columns="4"] {
      height: 17.26rem;
    }
    &[data-columns="5"] {
      height: 15.7rem;
    }
  }
  .drawerIcon {
    border-radius: 50%;
    background-color: #007fff14;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
  }
  .tableCellH {
    padding: 0rem 0.5rem;
    background-color: #007fff;
    color: #fff;
    font-size: 0.87rem;
  }
  .tableCellD {
    padding: 0.3rem 0.5rem;
    font-size: 0.87rem;
  }
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;
    .sideTitle {
      cursor: pointer;
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 50vh;
      font-size: 1rem;
    }
  }
  .drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  
  .errormsg {
    font-size: 0.75rem;
    color: #d32f2f;
    margin-top: 0.4rem;
    margin-left: 1.45rem;
  }
  @media all and (min-width: 1200px) and (max-width: 1535px) {
    .mediaCont {
      width: 96%;
    }
    // .expandIconBox {
    //   .sideTitle {
    //     margin-top: 36rem;
    //   }
    // }
  }
  
  @media all and (min-width: 900px) and (max-width: 1199px) {
    .mediaCont {
      width: 95%;
    }
    // .expandIconBox {
    //   .sideTitle {
    //     margin-top: 36rem;
    //   }
    // }
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
    .mediaCont {
      width: 93%;
    }
  }
  
  @media all and (max-width: 600px) {
  }
  