.treeViewData {
  padding: 0.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;

  .treeViewText {
    line-height: 1.2 !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
  }
}

.treetem {
  background-color: "red" !important;
}

.blink {
  animation: blinker 1.5s linear 5;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 10px;
  background-color: #007fff;
  /* border: 1px solid #007fff; */
  width: 5rem;
  padding: 0.2rem 0.5rem;
  font-family: sans-serif;
  text-align: center;
}

.centeredContent {
  display: none;
  height: 220px;
  margin: 0 auto;
  width: 220px;
}

@media print {
  .centeredContent {
    display: none;
    height: 220px;
    margin: 0 auto;
    width: 220px;
  }
}

@keyframes blinker {
  50% {
      opacity: 0;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
}

@media all and (min-width: 900px) and (max-width: 1199px) {
}

@media all and (min-width: 601px) and (max-width: 899px) {
}

@media all and (max-width: 600px) {
}
