.progressBarContainer{
    height: 51vh;
    width: 100%;
    padding: 0.5rem 0 0 0;
}
@media all and (min-width: 1200px) and (max-width:1535px){
    .progressBarContainer{
        height: 48vh;
        width: 100%;
        padding: 0.5rem 0 0 0;
    }
}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}


@media all and (max-width: 600px) {

}