.bannerContainer {
    display: grid;
    // grid-template-columns: 4rem 1fr;
  
    .left_container {
      width: 3rem;
      padding: 0 0.5rem;
    }
  
    .drawerIcon {
      border-radius: 50%;
      background-color: #007fff14;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  
    .expandIconBox {
      width: 4rem;
      padding-top: 0.4rem;
      text-align: center;
      border-right: 1px solid #007fff14;
      height: 89vh;
  
      .sideTitle {
        transform: rotate(270deg);
        white-space: nowrap;
        margin-top: 40vh;
      }
    }
  
    .right_container {
      padding: 0 1rem;
      // margin-top: 0.5rem;  
    }
  
    .drawBtn:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  
    .analyseBtn:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  
    .mid_container {
      display: block;
      height: 100%;
  
      // width:45rem;
      .body_container {
        height: 90vh;
      }
  
    }
  
    .removeaccord{
      display:none
    }
    .right_arrow_icon {
      text-align: center;
      width: 100%;
    }
  
  
    .accord {
      // background-color: aliceblue; 
      margin-bottom: 0.5rem;
    }
  }
  
  @media all and (min-width: 1200px) and (max-width:1535px) {
    .bannerContainer {
      .expandIconBox {
        width: 4rem;
        padding-top: 0.4rem;
        text-align: center;
        border-right: 1px solid #007fff14;
        height: 89vh;
  
        .sideTitle {
          transform: rotate(270deg);
          white-space: nowrap;
          margin-top: 40vh;
        }
      }
  
  
      .mid_container {
        display: block;
        height: 100%;
  
        // width:45rem;
        .body_container {
          height: 86vh;
        }
      }
  
      .accord {
        background-color: aliceblue;
        margin-bottom: 0.5rem;
      }
    }
  }