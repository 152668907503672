.headerCell{
    padding: 0.2rem 0.5rem !important;
    background-color: #a5d9d4 !important;
}
.rowCell{
    padding: 0.2rem 0.5rem !important;
    border-bottom: 1px solid #a5d9d4 !important;
}

.user_tab_container {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	column-gap: 2rem;
	margin: 0 auto;
	width: 98%;
	.subscription_tab {
		width: 100%;
		border-radius: 6px;
		background: linear-gradient(90deg, #18c9b7 0%, hsl(220, 78%, 29%) 100%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}
	.zbc_request_tab {
		width: 100%;
		border-radius: 6px;
		background: linear-gradient(90deg, hsl(44deg 78.19% 68.19%) 0%, hsl(35.76deg 100% 43.38%) 100%);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}

	.scenario_request_tab {
		width: 100%;
		border-radius: 6px;
		background: linear-gradient(
			90deg,
			hsl(122.42deg 100% 73.11%) 0%,
			#009688 100%
		);
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
		// transition: all .15s ease-in-out;
	}
	.add_part_tab {
        background: linear-gradient(90deg, #abaeff 0%, #241c69 50%);        
		width: 100%;
		border-radius: 6px;
		padding: 1rem 2rem;
		cursor: pointer;
		color: white;
		text-align: center;
		border: none;
		font-size: 1.4rem;
	}
	
	.subscription_tab:hover,
	.zbc_request_tab:hover,
	.scenario_request_tab:hover,
	.add_part_tab:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		opacity: 1;
	}
}

.details_table {
	height: 89vh;
	width: 98%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	margin: 0rem auto 1rem;
	// padding-top: 1rem;
	border-radius: 1rem;
}

@media all and (min-width: 1200px) and (max-width:1535px){
	.details_table {
		height: 84vh;
	}
}