.searchInput {
    color: #007fff;
    background-color: transparent;
    width: 23rem;
    height: 2rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: none;
    border-top: none;
    border-right: none;
    border-left: none;
}

.search {
    text-align: center;
    // width: 55%;
    margin: 0 auto;
}

.searchForm {
    display: inline-block;
    margin-top: 0.5rem;
    border-bottom: 2px solid;
}

.searchIcon {
    color: #007fff;
    width: 3rem;
    height: 1.5rem;
    margin-right: -2.5rem;
    cursor: pointer;
    z-index: 1;
    position: relative;
    margin-bottom: -1.5rem;
}

.AutoSearchBox {
    height: auto;
    max-height: 27rem;
    overflow: auto;
    width: 27rem;
    margin-top: 0.1rem;
    background-color: aliceblue;
    position: absolute;
    z-index: 11;
    border-radius: 2px;
    border-bottom: 2px solid #007fff14;
    border-right: 2px solid #007fff14;
    border-left: 2px solid #007fff14;

    .searchSubText {
        color: #007fff;
        cursor: pointer;
        text-align: left;
    }
}

.textarea:focus,
input:focus {
    outline: none;
}
.innserSearchSubText {
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #007fff14;
    padding: 0 0.6rem;
    background-color: white;
    transition: transform 0.5s ease;
    align-items: center;
    font-size: 0.9rem;
    justify-content: space-between;
  }

  .searchBox{
    min-width: 15rem;
    color: rgb(0, 127, 255);
    font-size: 1rem;
    width: 100%;
    padding: 0.5rem;
    border: none;
    // background-color: #007fff14;
  }
  .searchBoxSenario{
    min-width: 15rem;
    color: rgb(0, 127, 255);
    font-size: 1rem;
    width: 100%;
    padding: 0.5rem;
    border: none;
    // background-color: #faebd7;
  }

.assumptionCard{
    display: flex;   
    align-items: flex-start;    
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #007fff14;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin-bottom: 1rem;
    .cardDetails{
        display: flex;        
        align-items: center;       
        column-gap: 0.5rem;
        .textColorleft{
            text-align: right;
            width: 100%;
        }
        .textColor{
            color:#007fff;
        }
    }
    .chipOutline{
        font-size: 1rem;       
        min-width: 18rem; //{ lg: "9rem", xl:
        border-color: #007fff;
        cursor:pointer;  
        height:2rem; 
    }
    .chipOutline:hover{
        transform: scale(1.05);
        transition: all 0.5s ease;
    }
}
.assumptionCardScenario{
    display: flex;   
    align-items: flex-start;    
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #faebd7;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    margin-bottom: 1rem;
    .cardDetails{
        display: flex;        
        align-items: center;       
        column-gap: 0.5rem;
        .textColorleft{
            text-align: right;
            color:#833406;
            width: 100%;
        }
        .textColor{
            color:#833406;
        }
    }
    .chipOutline{
        font-size: 1rem;       
        min-width: 18rem; //{ lg: "9rem", xl:
        border-color: #833406;
        cursor:pointer;
        color:#833406;
        height:2rem;                    
    }
    .chipOutline:hover{
        transform: scale(1.05);
        transition: all 0.5s ease;
    }

}

  @media all and (min-width: 1200px) and (max-width:1535px){
    .searchBox{
        min-width: 13rem;
        color: rgb(0, 127, 255);
        font-size: 1rem;
        width: 100%;
        padding: 0.5rem;
        border: none;
        // background-color: #007fff14;
      }
      .searchBoxSenario{
        min-width: 13rem;
        color: rgb(0, 127, 255);
        font-size: 1rem;
        width: 100%;
        padding: 0.5rem;
        border: none;
        // background-color: #faebd7;
      }
    .assumptionCard{
        display: flex;   
        align-items: flex-start;    
        justify-content: space-between;
        padding: 0.5rem;
        background-color: #007fff14;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        margin-bottom: 1rem;
        .cardDetails{
            display: flex;        
            align-items: center;       
            column-gap: 0.5rem;
            .textColorleft{
                text-align: right;
                width: 100%;
            }
            .textColor{
                color:#007fff;
            }
        }
        .chipOutline{
            font-size: 1rem;       
            min-width: 12rem; //{ lg: "9rem", xl:
            border-color: #007fff;
            cursor:pointer; 
        }
        .chipOutline:hover{
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }
    .assumptionCardScenario{
        display: flex;   
        align-items: flex-start;    
        justify-content: space-between;
        padding: 0.5rem;
        background-color: #faebd7;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        margin-bottom: 1rem;
        .cardDetails{
            display: flex;        
            align-items: center;       
            column-gap: 0.5rem;
            .textColorleft{
                text-align: right;
                color:#833406;
                width: 100%;
            }
            .textColor{
                color:#833406;
            }
        }
        .chipOutline{
            font-size: 1rem;       
            min-width: 12rem; //{ lg: "9rem", xl:
            border-color: #833406;
            cursor:pointer;
            color:#833406;                        
        }
        .chipOutline:hover{
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }

  }
  
  
  @media all and (min-width: 900px) and (max-width:1199px) {
  
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
  
  }
  
  
  @media all and (max-width: 600px) {
  
  }