.drawerIcon {
    border-radius: 50%;
    background-color: #007fff14;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
  }
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;
  }
  .drawerBtn:disabled{
    background-color: #dddbdb;
  }
  .drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  .BarChartAnalysis{
    svg:not(:root){
      overflow: visible !important;
     }
  }
  .analysisModuleNotFound{display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  margin-right: 4rem;
  color: red;
  font-size: 1rem;}