.container {
    width: calc(100vw - 2rem) !important;
}

.tableContainer {
    max-height: calc(100vh - 16rem);
}

.tableHead {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
    background-color: #ddefff !important;
    white-space: nowrap !important;
}

.tableBody {
    padding: 0.2rem 0.5rem !important;
    border-bottom-color: #ddefff !important;
}