.drawerIcon {
  border-radius: 50%;
  background-color: #007fff14;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
}
.expandIconBox {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid #007fff14;
  height: 100%;
  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 45vh;
    font-size: 1rem;
  }
}
.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.container {
  display: grid;
  grid-template-columns: 4rem 1fr 1.25fr;
}

.containerFullScreen {
  display: grid;
  grid-template-columns: 4rem 1fr;
}

.containerNoIdea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80vh;
}



@media all and (min-width: 1200px) and (max-width:1535px){
  .expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 100%;
    .sideTitle {
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 45vh;
      font-size: 1rem;
    }
  }
}


@media all and (min-width: 900px) and (max-width:1199px) {

}

@media all and (min-width: 601px) and (max-width: 899px) {

}


@media all and (max-width: 600px) {

}