.archData {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 10px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 10px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 7vw;
    object-fit: contain;
    max-height: 7vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archData6 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
  column-gap: 5px;
  row-gap: 5px;
  cursor: pointer;
  padding: 0.5rem;
  .archImageIn {
    width: 5vw;
    object-fit: contain;
    max-height: 5vw;
    min-height: 5rem;
    margin: 0 auto;
  }
}
.archIamges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 17rem 0rem 0rem 0rem;
}

.ArchInfiniteScrollBar {
  max-height: 81vh;
  overflow: auto;
  margin-bottom: 0.5rem;
  // display: flex;
}
.ArchInfiniteScrollBar::-webkit-scrollbar-track {
  // border: 1px solid black;
  background-color: #f5f5f5;
  border-radius: 5%;
}
.ArchInfiniteScrollBar::-webkit-scrollbar {
  width: 3px;
  background-color: #18aedb;
  border-radius: 5%;
}
.ArchInfiniteScrollBar::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#09c6f9, #045de9);
  border-radius: 5%;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .ArchInfiniteScrollBar {
    max-height: 75vh !important;
    overflow: auto;
  }
  .archData {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 10px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 10px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 7vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 6vw;
      object-fit: contain;
      max-height: 7vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archData6 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5vw, 1fr));
    column-gap: 5px;
    row-gap: 5px;
    cursor: pointer;
    padding: 0.5rem;
    .archImageIn {
      width: 5vw;
      object-fit: contain;
      max-height: 5vw;
      min-height: 5rem;
      margin: 0 auto;
    }
  }
  .archIamges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 17rem 0rem 0rem 0rem;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .ArchInfiniteScrollBar {
    max-height: 37rem !important;
    overflow: auto;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .ArchInfiniteScrollBar {
    max-height: 62rem !important;
    overflow: auto;
  }
}

@media all and (max-width: 600px) {
}
