.container {
    width: 100%;
    margin: 0 !important;
    height: 100vh;
    font-size: 1.1rem !important;
}
.header {
    width: 100%;
    margin-top: 0.5rem;
}
.backarrow {
    cursor: pointer;
    padding: 1rem 0rem;
    font-size: 1.2rem;
}
.mainContainer {
    height: 90vh;
    border-radius: 1rem;
    margin: 0.5rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;
}
.iconConatiner {
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
    align-items: center;
    padding: 1rem;
}
.dataContainer {
    height: 83vh;
    display: grid;
    grid-template-columns: 13fr;
}
.flexBox {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
.gridItem {
    height: 2.5rem !important;
    display: grid;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
}
.mainGridItem {
    height: 79vh;
    display: grid;
    text-align: right;
    padding: 0.5rem;
    grid-template-columns: 1fr 9fr;
    grid-template-rows: 16fr;
}
.statusGridItem {
    height: 2.5rem !important;
    display: grid;
    padding: 0.5rem;
}
.bar {
    text-align: center;
    color: white;
    margin: 1px;
    padding: 0.5rem;
}
.graph {
    display: flex;
    color: white;
    text-align: center;
    height: 2.5rem;
    margin: 1px;
    vertical-align: middle;
}
.systemContainer {
    margin: 1px;
    text-align: center;
    padding: 0.5rem;
    height: fit-content;
    border-radius: 0.3rem;
}
.barHeader {
    text-align: center;
    font-weight: 600;
    margin-right: 1rem;
    float: right;
    position: relative;
    top:0.5rem;
    .pdfDownload{
        // float: right;
        padding: 0;
    }
}
.gridData {
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.dropCont {
    width: 100%;
    text-align: center;
}

.dashboardButtonActive {
    cursor: pointer;
    background-color: #007fff;
    color: #fff;
    border: none !important;
    padding: 0.5rem;
    border-radius: 0.5rem;
}
span.text {
    color: #007fff !important;
}
.itemHeader {
    font-weight: 600;
    text-align: right;
    height: 2rem;
}
.itemData {
    height: 2.5rem;
    text-align: right;
    margin: 1px;
}
.columright {
    text-align: center;
    margin-top: 5rem;
}
.donutoptionactive {
    line-height: 1.5;
    background-color: #007fff;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0 0.5rem;
    width: max-content;
    min-width: 4rem;
    border-radius: 0.3rem;
}
.donutoption {
    line-height: 1.5;
    background-color: white;
    color: #007fff;
    padding: 0.5rem;
    cursor: pointer;
    width: max-content;
    margin: 0 0.5rem;
    min-width: 4rem;
    border-radius: 0.3rem;
}
.donutoption:hover {
    line-height: 1.5;
    background-color:#007fff ;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    width: max-content;
    margin: 0 0.5rem;
    min-width: 4rem;
    border-radius: 0.3rem;
}
.overalldonutoptionactive {
    line-height: 1.5;
    background-color: blue;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    width: max-content;
    min-width: 5rem;
    display: inline-block;
    border-radius: 0.3rem;
}
// .subproudlist{
//     text-align: left;
// }
.overalldonutoption {
    line-height: 1.5;
    background-color: white;
    color: #007fff;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    width: max-content;
    min-width: 5rem;
    display: inline-block;
    border-radius: 0.3rem;
}
.allprodactive {
    line-height: 1.5;
    background-color: #007fff;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    border-radius: 0.3rem;
}
.allprod {
    line-height: 1.5;
    background-color: white;
    color: #007fff;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    border-radius: 0.3rem;
}


@media all and (min-width: 1200px) and (max-width:1535px){
    .mainGridItem {
        height: 73vh;
        display: grid;
        text-align: right;
        padding: 0.5rem;
        grid-template-columns: 1fr 9fr;
        grid-template-rows: 16fr;
    }
}
