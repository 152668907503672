.BomAnalysisContainer {
    width: 100vw;
    padding: 0 1rem;
    margin-top: 0.5rem;
  
    height: calc(100vh - var(--main-header-height));
  
    .gridContainer {
      display: flex;
      width: 100%;
      height: 17.7rem;
      border: 1px solid #e8f0fe;
      .gridInnerContainer {
        width: 100%;
        height: 100%;
      }
    }
    .gridItem {
      background-color: rgba(255, 255, 255, 0.8);
      border-right: 1px solid #e8f0fe;
      text-align: center;
      .gridInnerContainer {
        // display: grid;
        .gridContentItem {
          width: 28vw;
          background: #fff;
        }
      }
      .gridInnerFooter {
        display: grid;
        grid-template-columns: 2.3fr 1fr;
        border: 1px solid #e8f0fe;
        margin-top: 0.5rem;
        .gridFooterItem {
          border: 1px solid #e8f0fe;
        }
      }
    }
    .gridItem {
      background-color: rgba(255, 255, 255, 0.8);
      border-right: 1px solid #e8f0fe;
      text-align: center;
  
      .InititalImageSection {
        grid-template-columns: repeat(5, 1fr);
        height: 15.4rem;
        border-right: 1px solid #e8f0fe;
        width: 18rem;
      }
      .tabDiv {
        border: 1px solid #e8f0fe;
        height: 2.3rem;
      }
      .tabInner {
        display: flex;
        position: absolute;
        width: 70.4%;
        z-index: 5;
      }
      .tabcontainerActve {
        background-color: blue;
        color: white;
        padding: 0.5rem;
        font-weight: 600;
        text-align: center;
        font-size: 1.1rem;
        width: 50%;
        cursor: pointer;
        border-radius: 2px;
        height: 2.9rem;
        line-height: 1.5;
      }
      .tabcontainer {
        background-color: white;
        border: 1px solid #0000ff26;
        cursor: pointer;
        text-align: center;
        width: 50%;
        padding: 0.5rem;
        font-weight: 600;
        font-size: 1.1rem;
        border-radius: 2px;
        height: 2.9rem;
        line-height: 1.5;
      }
      .dataHeader {
        display: flex;
        height: 3rem;
        line-height: 1;
        position: absolute;
        width: 70.4%;
        z-index: 5;
      }
    }
    .gridContainer1 {
      display: block;
      height: calc(100vh - var(--main-header-height) - 21rem);
      // margin-top: 1rem;
    }
  }
  .slider {
    left: 3rem;
    position: relative;
    width: 7rem;
    bottom: 5rem;
    line-height: 1;
    border-radius: 0.5rem;
    border: 1px solid #0000ff;
    background-color: #fff;
    z-index: 2;
    margin-top: 1rem;
  }
  // .parameterheader {
  //   width: 8rem;
  //   text-align: center;
  // }
  // .graphcolheader {
  //   width: 19vw;
  //   text-align: center;
  // }
  .parameter {
    width: 10rem;
  }
  .graphcol {
    width: 18vw;
  }
  .pageSlide {
    margin-bottom: -3rem;
    float: right;
    line-height: 1;
  }
  